.totalProtein-parent {
  display: flex;
}
.totalProtein-left {
  width: 77%;
  margin-top: 20px;
  margin-left: 5px;
}
.totalProtein-grid {
  display: grid;
  background-color: white;
  padding: 5px 10px 0 10px;
  font-size: 12px;
}
.totalProtein-1 {
  grid-column: 1;
  grid-row: 1;
}
.totalProtein-2 {
  grid-column: 2;
  grid-row: 1;
}

.totalProtein-3 {
  grid-column: 3;
  grid-row: 1;
}
.totalProtein-4 {
  grid-column: 4;
  grid-row: 1;
}
.totalProtein-5 {
  grid-column: 5;
  grid-row: 1;
}

.totalProtein-border-1 {
  grid-column: 1 / span 5;
  grid-row: 2;
  border-bottom: 1px solid #d9d9d9;
}
.totalProtein-6 {
  grid-column: 1;
  grid-row: 3;
}
.totalProtein-7 {
  grid-column: 2;
  grid-row: 3;
}

.totalProtein-8 {
  grid-column: 3;
  grid-row: 3;
}
.totalProtein-9 {
  grid-column: 4;
  grid-row: 3;
}
.totalProtein-10 {
  grid-column: 5;
  grid-row: 3;
}

.totalProtein-border-2 {
  grid-column: 1 / span 5;
  grid-row: 4;
  border-bottom: 1px solid #d9d9d9;
}
.totalProtein-11 {
  grid-column: 1;
  grid-row: 5;
}
.totalProtein-12 {
  grid-column: 2;
  grid-row: 5;
}

.totalProtein-13 {
  grid-column: 3;
  grid-row: 5;
}
.totalProtein-14 {
  grid-column: 4;
  grid-row: 5;
}
.totalProtein-15 {
  grid-column: 5;
  grid-row: 5;
}

.totalProtein-border-3 {
  grid-column: 1 / span 5;
  grid-row: 6;
  border-bottom: 1px solid #d9d9d9;
}

.totalProtein-16 {
  grid-column: 1;
  grid-row: 7;
}
.totalProtein-17 {
  grid-column: 2;
  grid-row: 7;
}

.totalProtein-18 {
  grid-column: 3;
  grid-row: 7;
}
.totalProtein-19 {
  grid-column: 4;
  grid-row: 7;
}
.totalProtein-20 {
  grid-column: 5;
  grid-row: 7;
}

.totalProtein-border-4 {
  grid-column: 1 / span 5;
  grid-row: 8;
  border-bottom: 1px solid #d9d9d9;
}

.totalProtein-input {
  width: 120px;
  border-radius: 5px;
}
.totalProtein-pad {
  padding-top: 7px;
}
.totalProtein-foot {
  margin-top: 20px;
  padding-left: 10px;
}
.totalProtein-foot .add-image-input {
  background-color: white;
}
