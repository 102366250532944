.allergy-div {
    display: flex;
    margin: 20px;
    margin-bottom: 30px ;
}
.allergy-antSelect .ant-select-dropdown .ant-select-dropdown-placement-bottomLeft{
    z-index: 1 !important;
  }

.allergy-intelorance {
    width: 23%;
    /* padding: 8px; */
}

.allergy-intelorance h6 {
    font-size: 13px;
    padding: 5px 10px;
    font-weight: 600;
}

.allergy-antSelect {
    width: 40vw;
}

.allergy-multiselect {
    width: 42vw;
}