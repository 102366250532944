.bsf-main {
  display: flex;
}
.bsf-container {
  width: 80%;
}
.bsf-grid-container {
  display: grid;
  background-color: #ffff;
  text-align: left;
  padding: 24px 10px 0 16px;
  font-size: 12px;
}
