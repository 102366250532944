.blood {
  display: grid;
  background-color: #ffff;
  text-align: left;
  padding: 10px 10px 0 10px;
  font-size: 12px;
}

.col1 {
  grid-column: 1;
  grid-row: 1;
  width: 150px;
}

.col2 {
  grid-column: 2;
  grid-row: 1;
}

.col3 {
  grid-column: 3;
  grid-row: 1;
}

.col4 {
  grid-column: 4;
  grid-row: 1;
}

.col5 {
  grid-column: 5;
  grid-row: 1;
}

.col11 {
  grid-column: 6;
  grid-row: 1;
}

.line-bottom {
  grid-column: 1 / span 6;
  grid-row: 2;

  border-bottom: 1px solid rgb(221, 221, 221);
}

.col12 {
  grid-column: 1;
  grid-row: 3;
}

.col13 {
  grid-column: 3 / span 4;
  grid-row: 3;
}

.line-bottom-two {
  grid-column: 1 / span 6;
  grid-row: 4;

  border-bottom: 1px solid rgb(221, 221, 221);
}

.col14 {
  grid-column: 1;
  grid-row: 5;
}

.col15 {
  grid-column: 3 / span 4;
  grid-row: 5;
}

.line-bottom-three {
  grid-column: 1 / span 6;
  grid-row: 6;

  border-bottom: 1px solid rgb(221, 221, 221);
}

.col16 {
  grid-column: 1;
  grid-row: 7;
}

.col17 {
  grid-column: 3 / span 4;
  grid-row: 7;
}

.line-bottom-four {
  grid-column: 1 / span 6;
  grid-row: 8;

  border-bottom: 1px solid rgb(221, 221, 221);
}

.col18 {
  grid-column: 1;
  grid-row: 9;
  width: 150px;
}

.col19 {
  grid-column: 2;
  grid-row: 9;
}

.col20 {
  grid-column: 3;
  grid-row: 9;
}

.col21 {
  grid-column: 4;
  grid-row: 9;
}

.col22 {
  grid-column: 5;
  grid-row: 9;
}

.col23 {
  grid-column: 6;
  grid-row: 9;
}

.line-bottom-five {
  grid-column: 1 / span 6;
  grid-row: 10;

  border-bottom: 1px solid rgb(221, 221, 221);
}

.col24 {
  grid-column: 1;
  grid-row: 11;
}

.col25 {
  grid-column: 3 / span 4;
  grid-row: 11;
}

.line-bottom-six {
  grid-column: 1 / span 6;
  grid-row: 12;

  border-bottom: 1px solid rgb(221, 221, 221);
}

.col26 {
  grid-column: 1;
  grid-row: 13;
}

.col27 {
  grid-column: 3 / span 4;
  grid-row: 13;
}

.line-bottom-seven {
  grid-column: 1 / span 6;
  grid-row: 14;

  border-bottom: 1px solid rgb(221, 221, 221);
}

.col13 div {
  height: 25px;
}

.col15 div {
  height: 25px;
}

.col17 div {
  height: 25px;
}

.col25 div {
  height: 25px;
}

.col27 div {
  height: 25px;
}

.pad {
  padding: 5px 0 0 5px;
}
.fasting-blood {
  width: 80%;
  max-width: 1300px;
  margin-left: 5px;
}

.input-blood {
  width: 120px;
  height: 32px;
  border-radius: 4px;
  margin-top: 2px;
}

.blood textarea {
  width: 100%;
  height: 26px;
  border-radius: 4px;
  border: 1px solid rgb(210, 210, 210);
  outline: none;
  margin-top: 2px;
}

.blood-parent {
  display: flex;
}
.blood-footer .add-image-btn button {
  font-weight: 400;
  width: 130px;
  padding: 6px;
  margin-right: 30px;
}
.blood-footer {
  padding-left: 5px;
  margin-top: 20px;
}
.blood-footer .add-image-input {
  background-color: #ffffff;
}
.blood-footer .ant-input-affix-wrapper > input.ant-input {
  background-color: #ffffff;
}
.dictation-blood .ant-input:placeholder-shown {
  background-color: #ffffff;
}
.blood-method h5 {
  margin-top: 20px;
  font-size: 12px;
  font-weight: 800;
  padding-left: 10px;
}
.blood-method p {
  font-size: 12px;
  line-height: 15px;
  padding-left: 10px;
}
