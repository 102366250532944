.masterlist-container {
  display: flex;
}

.masterlist-head {
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
}

.masterlist-head h4 {
  margin: 0px;
  color: #000000;
  font-weight: 700;
  font-size: 20px;
}
.update-button-blue {
  display: none;
}

.masterlist-left-sec {
  background: #fff;
  width: 22%;
  height: 82vh;

    width: 22%;
    background: white;
}

.masterlist-list-sec {
    background: #ffffff;
     /* margin-left: 22px;  */
     height: 100px; 
}

.masterlist-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 10px 7px 20px;
  border-bottom: 1px solid #ececec;
}
.masterlist-items p {
  cursor: pointer;
}
/* .master-table-edit{
    margin-right: 20px;
} */
.item-color {
  background-color: #FFFFFF;

  /* background-color: #f0f8fa; */

  
}

.masterlist-items p {
  margin: 0px;
  font-weight: 600;
  text-transform: capitalize;
}

.masters-right-container {
  width: 76.5%;
  margin: 0px 0% 0px 0.5%;
}

.masterlist-head-sec {
  background-color: white;
  padding-top: 20px;
  padding-bottom: 10px;
  margin-bottom: 5px;
}

.masterlist-head-sec p {
  padding-left: 20px;
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 10px;
}

.masters-table-container1 {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100%;
  height: 79vh;
  overflow-y: scroll;

    background: #ffffff;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    width: 100%;
    height: 300px;
    overflow-y: scroll;
  

}
.masters-table-container1::-webkit-scrollbar {
  display: none;

}

.master-table-edit {
  padding: 4px;
  height: 22px;
  width: 22px;
  background: #00aeef;
  border-radius: 50%;
  margin-right: 7px;
  cursor: pointer;
}

.master-table-delete {
  padding: 4px;
  height: 22px;
  width: 22px;
  background: #ef4036;
  border-radius: 50%;
  cursor: pointer;
}

.table-container1 h3 {
  margin-left: 15px;
  font-weight: 700;
  margin-top: 10px;
  font-size: 18px;
}

.table-container2 {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 140px;
  width: 100%;
  font-weight: 600;
  margin-left: 15px;
}

.platlets-text {
  background-color: #dcf4fd;
  display: flex;
  /* flex-direction: row; */
  justify-content: space-between;
  width: 30%;
  padding: 5px;
  /* border-radius: 10%; */
  font-weight: 600;
  margin-left: 10px;
  margin-bottom: 2px;
}

.edit-master-search-input {
  height: 35px;
  margin-right: 10px;
  margin-top: 5px;
  width: 14vw;
}

.add-list-input {
  width: 90%;
  margin-right: 10px;
}

.add-list-btn {
  width: 10%;
}

.edit-master-name-input {
  height: 35px;
  margin-top: 5px;
  width: 100%;
}

.edit-master-add-button {
  height: 35px;
  margin-top: 5px;
  width: 63px;
}

.update-button-blue {
  background-color: #00aeef;
  height: 35px;
  width: 93px;
}

.update-button-blue img {
  margin-right: 5px;
  margin-bottom: 2px;
  width: 20px;
}

.add-edit-wrapper {
  display: flex;
  justify-content: stretch;
  margin: 0 auto;
  margin-bottom: 5px;
  width: 100%;
  padding-left: 20px;
}

.add-edit-wrapper Button {
  margin-top: 5px;
}

.red-notification {
  font-size: 12px;
  color: #ef4036;
  padding-left: 20px;
  font-weight: 600;
}

.add-master-list {
  display: flex;
}

.add-btn-add-list {
  margin-top: 6px;
}

.white-color-background-med-list {
  background-color: #f0f8fa;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 10px 7px 20px;
  border-bottom: 1px solid #ececec;
  text-transform: capitalize;

}

/* body {
  
     overflow-y: scroll
    } */

@media screen and (min-width: 1336px) {
  .masters-table-container1 {
    background: #fff;
    display: flex;
    flex-direction: column;
    height: 380px;
    /* overflow-y: auto; */
    /* overflow-y: scroll; */
    width: 100%;
  }

  .masterlist-left-sec {
    background: #fff;
    width: 22%;
    height: auto;
  }
}

@media screen and (min-width:2560px) {
  .masters-table-container1 {
    background: #fff;
    display: flex;
    flex-direction: column;
    height: 480px !important;
    overflow-y: auto !important;
    overflow-y: scroll !important;
    -ms-overflow-style: none;
    scrollbar-width: none;
    width: 100%;
  }
  .masters-table-container1::-webkit-scrollbar {
    display: none;
  }
  
  .masterlist-left-sec {
    background: #fff;
    width: 22%;
    height: auto;
  }
}

@media screen and (min-width: 1440px) {
  .masterlist-left-sec {
    background: #fff;
    width: 22%;
    height: auto;
  }

  .masters-table-container1 {
    background: #fff;
    display: flex;
    flex-direction: column;
    height: 485px;
    overflow-y: auto;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    width: 100%;
  }
  .masters-table-container1::-webkit-scrollbar {
    display: none;
  }
  
}

@media screen and (min-width: 1880px) {
  .masters-table-container1 {

    
      -ms-overflow-style: none;
      background: #fff;
      display: flex;
      flex-direction: column;
      height: 680px!important;
      overflow-y: auto;
      overflow-y: scroll;
      scrollbar-width: none;
      width: 100%;

   

  }
  .masters-table-container1::-webkit-scrollbar {
    display: none;
  }
  

  .masterlist-left-sec {
    background: #fff;
    height: auto;
    width: 22%;
}
}


.black {
  background: black;
}

.yellow {
  background: yellow;
}
html, body {
    /* overflow-y:auto; */
    height:100%;
  }


