.electrolyte-left {
  width: 82%;
  margin-top: 20px;
}
.electrolyte-grid {
  display: grid;
  gap: 0 40px;
  background-color: white;
  padding: 5px 10px 0 10px;
  font-size: 12px;
}
.electrolyte-parent {
  display: flex;
}
.elec-input {
  width: 130px;
  border-radius: 5px;
  margin-top: 8px;
}
.male-inp {
  width: 130px;
  border-radius: 5px;
  margin-top: 4px;
}
.elec-pad {
  padding-top: 10px;
  margin-top: 4px;
}
.elecc-pad {
  padding-top: 6px;
}
.elec1 {
  grid-column: 1;
  grid-row: 1;
  width: 120px;
}
.elec2 {
  grid-column: 2;
  grid-row: 1;
  width: 158px;
}
.elec3 {
  grid-column: 3;
  grid-row: 1;
}
.elec4 {
  grid-column: 4;
  grid-row: 1;
}
.elec5 {
  grid-column: 5;
  grid-row: 1;
}
.elec6 {
  grid-column: 6;
  grid-row: 1;
}
.elec-pad {
  padding-top: 10px;
}
.elec7 {
  grid-column: 1;
  grid-row: 3;
  width: 120px;
}
.elec8 {
  grid-column: 2;
  grid-row: 3;
  width: 158px;
}
.elec9 {
  grid-column: 3;
  grid-row: 3;
}
.elec10 {
  grid-column: 4;
  grid-row: 3;
}
.elec11 {
  grid-column: 5;
  grid-row: 3;
}
.elec12 {
  grid-column: 6;
  grid-row: 3;
}

.elec13 {
  grid-column: 1;
  grid-row: 5;
  width: 120px;
}
.elec14 {
  grid-column: 2;
  grid-row: 5;
  width: 158px;
}
.elec15 {
  grid-column: 3;
  grid-row: 5;
}
.elec16 {
  grid-column: 4;
  grid-row: 5;
}
.elec17 {
  grid-column: 5;
  grid-row: 5;
}

.elec18 {
  grid-column: 1;
  grid-row: 7;
  width: 120px;
}
.elec19 {
  grid-column: 2;
  grid-row: 7;
  width: 158px;
}
.elec20 {
  grid-column: 3;
  grid-row: 7;
}
.elec21 {
  grid-column: 4;
  grid-row: 7;
}
.elec22 {
  grid-column: 5;
  grid-row: 7;
}
.elec23 {
  grid-column: 1;
  grid-row: 9;
  width: 120px;
}
.elec24 {
  grid-column: 2;
  grid-row: 9;
  width: 158px;
}
.elec25 {
  grid-column: 3;
  grid-row: 9;
}
.elec26 {
  grid-column: 4;
  grid-row: 9;
}
.elec27 {
  grid-column: 5;
  grid-row: 9;
}
.elec28 {
  grid-column: 1;
  grid-row: 11;
  width: 120px;
}
.elec29 {
  grid-column: 2;
  grid-row: 11;
  width: 158px;
}
.elec30 {
  grid-column: 3;
  grid-row: 11;
}
.elec31 {
  grid-column: 4;
  grid-row: 11;
}
.elec32 {
  grid-column: 5;
  grid-row: 11;
}
.elec33 {
  grid-column: 1;
  grid-row: 13;
  width: 120px;
}
.elec34 {
  grid-column: 2;
  grid-row: 13;
  width: 158px;
}
.elec35 {
  grid-column: 3;
  grid-row: 13;
}
.elec36 {
  grid-column: 4;
  grid-row: 13;
}
.elec37 {
  grid-column: 5;
  grid-row: 13;
}

.elec38 {
  grid-column: 1;
  grid-row: 15;
  width: 120px;
}
.elec39 {
  grid-column: 2;
  grid-row: 15;
  width: 168px;
}
.elec40 {
  grid-column: 3;
  grid-row: 15;
}
.elec41 {
  grid-column: 4;
  grid-row: 15;
}
.elec42 {
  grid-column: 5;
  grid-row: 15;
}
.elec43 {
  grid-column: 1;
  grid-row: 17;
  padding-left: 5px;
}
.elec44 {
  grid-column: 3 / span 4;
  grid-row: 17;
}

.elec-line {
  grid-column: 1 / span 6;
  grid-row: 2;
  border-bottom: 1px solid #d9d9d9;
}
.elec-line2 {
  grid-column: 1 / span 6;
  grid-row: 4;
  border-bottom: 1px solid #d9d9d9;
}
.elec-line3 {
  grid-column: 1 / span 6;
  grid-row: 6;
  border-bottom: 1px solid #d9d9d9;
}
.elec-line4 {
  grid-column: 1 / span 6;
  grid-row: 8;
  border-bottom: 1px solid #d9d9d9;
}
.elec-line5 {
  grid-column: 1 / span 6;
  grid-row: 10;
  border-bottom: 1px solid #d9d9d9;
}
.elec-line6 {
  grid-column: 1 / span 6;
  grid-row: 12;
  border-bottom: 1px solid #d9d9d9;
}
.elec-line7 {
  grid-column: 1 / span 6;
  grid-row: 14;
  border-bottom: 1px solid #d9d9d9;
}
.elec-line8 {
  grid-column: 1 / span 6;
  grid-row: 16;
  border-bottom: 1px solid #d9d9d9;
}
.elec-line9 {
  grid-column: 1 / span 6;
  grid-row: 18;
  border-bottom: 1px solid #d9d9d9;
}
.elec-gender {
  width: 40%;
  display: flex;
  justify-content: space-between;
  margin: 20px 0 10px 20px;
}
.male-pad {
  padding-top: 8px;
}
.gen {
  width: 100px; /* temperory until images are added */
  background-color: white;
  color: rgb(80, 78, 78);
  font-size: 12px;
  font-weight: 500;
  border: 1px solid #d9d9d9;
  border-radius: 18px;
  padding: 5px;
}
.gen:hover {
  color: #00809c;
  border-color: #b3d9e1;
  background-color: #e6f2f5;
}
.gen:focus {
  color: #00809c;
  border-color: #b3d9e1;
  background-color: #e6f2f5;
}
.elec-foot {
  padding-left: 10px;
}
.elec-foot .add-image-input {
  background-color: white;
}
.auto-button Button {
  background-color: gray;
  margin: 10px;
}
.electrolyte-grid textarea {
  height: 32px;
  border-radius: 4px;
  border: 1px solid rgb(210, 210, 210);
  margin-top: 2px;
}
.preg-pad {
  width: 150px;
}
.perg {
  width: 0;
}
.preg-height {
  padding-top: 18px;
}
.preg-inp {
  width: 130px;
  border-radius: 5px;
  margin-top: 14px;
}
.tab-elec {
  font-size: 12px;
}
.tab-elec td {
  padding-right: 15px;
}
