.hbsag-grid {
  display: grid;
  gap: 0;
  background-color: white;
  padding: 5px 10px 0 10px;
  font-size: 12px;
}
.hbsag-parent {
  display: flex;
}
.hbsag-left {
  width: 77%;
  margin: 15px 0 0 5px;
}
.hbsag1 {
  grid-column: 1;
  grid-row: 1;
}
.hbsag2 {
  grid-column: 2;
  grid-row: 1;
}
.hbsag-text textarea {
  height: 32px;
  border-radius: 4px;
  border: 1px solid rgb(210, 210, 210);
}
.hbsag-border {
  grid-column: 1 / span 2;
  grid-row: 2;
  border-bottom: 1px solid #d9d9d9;
}

.hbsag-pad {
  padding: 7px 0 0 5px;
}
.hbsag-foot {
  margin-top: 20px;
  padding-left: 10px;
}
.hbsag-foot .add-image-input {
  background-color: white;
}
.hbsag-method {
  font-size: 12px;
  margin-top: 20px;
  padding-left: 10px;
  font-weight: 600;
}
.hbsag-method p {
  font-weight: 500;
}
