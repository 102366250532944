:root {
    --variablename: default;
}

.ant-table-tbody>tr>td {
    border-bottom: 1px solid #f0f0f0;
    color: #000000;
    padding: 10px 12px;
    font-weight: 500;
}

.ant-pagination-next {
    font-family: 'Poppins' !important;
    outline: 0;
    margin-left: 5px;
    margin-top: 5px;
}

.ant-pagination-item-active a {
    color: white !important;
    background: #3DA3B8;
}

.ant-pagination-item a {
    display: block;
    padding: 0 6px;
    color: #3DA3B8;
    transition: none;
}

.ant-pagination-prev {
    margin-right: 5px;
    margin-top: 5px;
}

.ant-spin-container {
    position: relative;
    transition: opacity 0.3s;

}

.ant-table-pagination.ant-pagination {
    margin: 16px 16px;
}

.ant-table-tbody {
    line-height: 1px;
}

.ant-table-thead>tr>th {
    color: white;
    background: var(--variablename) !important;
    padding: 10px;

}

.ant-spin-container {
    position: relative;
    transition: opacity 0.3s;

}

.ant-table-tbody {
    line-height: 1px;
}


.ant-table-pagination>* {
    flex: none;

}

.ant-pagination-options-size-changer.ant-select {
    display: inline-block;
    margin-top: 5px;
    width: auto;
}

.ant-pagination-item {
    display: inline-block;
    min-width: 32px;
    height: 32px;
    margin-right: 0px;
    margin-top: 5px;
    font-family: 'Poppins', sans-serif !important;
    line-height: 30px;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    outline: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    height: 0px;
}