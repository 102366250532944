.diet {
  display: flex;
}
.diet-footer {
  padding-left: 5px;
  margin-top: 20px;
}
.diet-footer .add-image-btn button {
  font-weight: 400;
  width: 130px;
  padding: 6px;
  margin-right: 30px;
}
.diet-left {
  width: 80%;
}
.diet-plan {
  display: grid;
  background-color: #ffff;
  text-align: left;
  padding: 10px 10px 0 10px;
  font-size: 12px;
}
.diet1 {
  grid-column: 1;
  grid-row: 1;
  width: 170px;
}
.diet2 {
  grid-column: 2;
  grid-row: 1;
  width: 80px;
}
.diet3 {
  grid-column: 3;
  grid-row: 1;
}
.diet4 {
  grid-column: 4;
  grid-row: 1;
}
.diet5 {
  grid-column: 5;
  grid-row: 1;
}
.diet6 {
  grid-column: 1;
  grid-row: 3;
  width: 170px;
}
.diet7 {
  grid-column: 2;
  grid-row: 3;
  width: 80px;
}
.diet8 {
  grid-column: 3;
  grid-row: 3;
}
.diet9 {
  grid-column: 4;
  grid-row: 3;
}
.diet10 {
  grid-column: 5;
  grid-row: 3;
}
.diet11 {
  grid-column: 1;
  grid-row: 5;
  width: 170px;
}
.diet12 {
  grid-column: 2;
  grid-row: 5;
  width: 80px;
}
.diet13 {
  grid-column: 3;
  grid-row: 5;
}
.diet14 {
  grid-column: 4;
  grid-row: 5;
}
.diet15 {
  grid-column: 5;
  grid-row: 5;
}
.dite16 {
  grid-column: 1;
  grid-row: 7;
  width: 170px;
}
.diet17 {
  grid-column: 3 / span 4;
  grid-row: 7;
}
.dite18 {
  grid-column: 1;
  grid-row: 9;
  width: 170px;
}
.diet19 {
  grid-column: 3 / span 4;
  grid-row: 9;
}
.dite20 {
  grid-column: 1;
  grid-row: 11;
  width: 170px;
}
.diet21 {
  grid-column: 3 / span 4;
  grid-row: 11;
}
.dite22 {
  grid-column: 1;
  grid-row: 13;
  width: 170px;
}
.diet23 {
  grid-column: 3 / span 4;
  grid-row: 13;
}

.diet-line1 {
  grid-column: 1 / span 6;
  grid-row: 2;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.diet-line2 {
  grid-column: 1 / span 6;
  grid-row: 4;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.diet-line3 {
  grid-column: 1 / span 6;
  grid-row: 8;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.diet-line4 {
  grid-column: 1 / span 6;
  grid-row: 10;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.diet-line5 {
  grid-column: 1 / span 6;
  grid-row: 12;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.diet-line6 {
  grid-column: 1 / span 6;
  grid-row: 14;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.diet-line7 {
  grid-column: 1 / span 6;
  grid-row: 6;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.input-diet {
  width: 120px;
  height: 32px;
  border-radius: 4px;
  margin-top: 2px;
}
.diet-plan textarea {
  height: 32px;
  border-radius: 4px;
  border: 1px solid rgb(210, 210, 210);
  margin-top: 2px;
}
.diet-pad {
  padding: 5px 5px;
}
.diet-footer .ant-input-affix-wrapper > input.ant-input {
  background-color: #f5f5f5;
}
