.lipid-parent {
  display: flex;
}
.lipid-left {
  width: 80%;
  margin-left: 5px;
}
.lipid-grid {
  display: grid;
  background-color: white;
  padding: 5px 10px 0 10px;
  font-size: 12px;
}
.lipid1 {
  grid-column: 1;
  grid-row: 1;
  width: 150px;
}
.lipid2 {
  grid-column: 2;
  grid-row: 1;
}
.lipid3 {
  grid-column: 3;
  grid-row: 1;
}
.lipid4 {
  grid-column: 4;
  grid-row: 1;
}
.lipid5 {
  grid-column: 5;
  grid-row: 1;
}
.lipid6 {
  grid-column: 6;
  grid-row: 1;
}
.lipid-input {
  width: 120px;
  border-radius: 5px;
  margin-top: 2px;
}
.lipid-border {
  grid-column: 1 / span 6;
  grid-row: 2;
  border-bottom: 1px solid #d9d9d9;
}
.lipid-border1 {
  grid-column: 1 / span 6;
  grid-row: 4;
  border-bottom: 1px solid #d9d9d9;
}
.lipid-border2 {
  grid-column: 1 / span 6;
  grid-row: 6;
  border-bottom: 1px solid #d9d9d9;
}
.lipid-border3 {
  grid-column: 1 / span 6;
  grid-row: 8;
  border-bottom: 1px solid #d9d9d9;
}
.lipid-border4 {
  grid-column: 1 / span 6;
  grid-row: 10;
  border-bottom: 1px solid #d9d9d9;
}
.lipid-border5 {
  grid-column: 1 / span 6;
  grid-row: 12;
  border-bottom: 1px solid #d9d9d9;
}
.lipid-border6 {
  grid-column: 1 / span 6;
  grid-row: 14;
  border-bottom: 1px solid #d9d9d9;
}
.lipid-border7 {
  grid-column: 1 / span 6;
  grid-row: 16;
  border-bottom: 1px solid #d9d9d9;
}
.lipid-border8 {
  grid-column: 1 / span 6;
  grid-row: 18;
  border-bottom: 1px solid #d9d9d9;
}
.h-pad {
  padding: 6px 5px;
}
.lipid-foot {
  margin-top: 20px;
  padding-left: 5px;
}
.lipid-foot .add-image-input {
  background-color: white;
}
.lipid-method {
  margin-top: 20px;
  padding-left: 10px;
  font-weight: 600;
}
.lipid-method P {
  font-weight: 500;
  font-size: 12px;
}
.back-input {
  width: 120px;
  border-radius: 5px;
  background-color: #f0f8fa;
  margin-top: 2px;
}
.back .ant-input-affix-wrapper > input.ant-input {
  background-color: #f0f8fa;
}
.add-cal-btn img {
  padding-right: 5px;
}

.add-cal-btn button {
  width: 15%;
  font-weight: 500;
  border-radius: 15px;
  padding: 6px 16px 4px 15px;
  background-color: rgb(143, 139, 139);
  color: white;
  border: 1px solid #d9d9d9;
  margin-left: 10px;
  margin-top: 10px;
  height: 32px;
}
