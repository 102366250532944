.lh-parent {
  display: flex;
}
.lh-left {
  width: 77%;
  margin-top: 20px;
  margin-left: 5px;
}
.lh-grid {
  display: grid;
  background-color: white;
  padding: 5px 10px 0 10px;
  font-size: 12px;
}
.lh-1 {
  grid-column: 1;
  grid-row: 1;
}
.lh-2 {
  grid-column: 2;
  grid-row: 1;
}

.lh-3 {
  grid-column: 3;
  grid-row: 1;
}
.lh-4 {
  grid-column: 4;
  grid-row: 1;
}
.lh-5 {
  grid-column: 5;
  grid-row: 1;
}
.lh-6 {
  grid-column: 6;
  grid-row: 1;
}
.lh-input {
  width: 120px;
  border-radius: 5px;
}
.lh-pad {
  padding-top: 7px;
}
.lh-foot {
  margin-top: 20px;
  padding-left: 10px;
}
.lh-foot .add-image-input {
  background-color: white;
}
.lh-p {
  padding: 15px 10px;
  font-size: 12px;
  line-height: 015px;
  font-weight: 700;
}
.lh-p h4 {
  font-weight: 800;
  padding-bottom: 5px;
}
.lh-p span {
  font-weight: 500;
}
.lh-msr {
  padding: 5px 10px;
  font-size: 13px;
  font-weight: 500;
}
.lh-line {
  grid-column: 1 / span 6;
  grid-row: 2;
  border-bottom: 1px solid #d9d9d9;
}
