.branch-name{
    display: flex;
    justify-content: space-between;
    margin: 15px 3% 0px 3% ;
}
.new-branch-btn{
    padding: 5px 11px;
    font-size: 11px;
    margin: 0px;
    font-weight: 600;
}
.branch-name h1{
    font-weight: 700;
    font-size: 25px;
    margin: 0px 0px 4px 0px;
}
.vising-areas-section{
    background: #ffffff;
    margin: 0px 3% 15px 3%;
    padding: 10px 3% 30px 3%;
}
.each-visiting-details{
    display: flex;
}
.each-visiting-details p{
    width: 27%;
    color: #808080;
}
.each-visiting-details h4,h3{
    color: #000000;
}
.each-visiting-details h3{
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;

}
.each-visiting-details h4 span{
    padding-right: 30px;
}

.visiting-icon-sec{
    text-align: right;
}


.visit-switch-mt{
    margin-right: 5px;
}

.ant-modal-header {
    padding: 16px 24px;
    color: rgba(0, 0, 0, 0.85);
    background: #fff;
    border-bottom: none !important;
    border-radius: 2px 2px 0 0;
}
.ant-modal-footer {
    display: none;
   
}
.ant-switch{
    padding: 0px 15px;
    height: 27px;
    line-height: 27px;
}
.ant-switch-handle {
    position: absolute;
    top: 4px !important;
    left: 2px;
    width: 18px;
    height: 18px;
    transition: all 0.2s ease-in-out;
}
.ant-modal-title {
    margin: 0;
    color: #000000d9;
    font-weight: 700;
    font-size: 21px;
    line-height: 22px;
    word-wrap: break-word;
}
.ant-modal-close-x {
    font-size: 22px;
    color: black;
    margin-top: -19px;
    padding-left: 23px;
}
.add-branch-modal-sec{
    display: flex;
    justify-content: center;
    margin-top: 17px;
}
.add-branch-modal-sec p{
    width: 30%;
    color: #2D2D2D;
    font-size: 15px;
    font-weight: 500;
    /* padding-left: 6%; */
}
.add-branch-input{
    width: 70%;
}
.add-branch-time{
    width: 20%;
}
.timing-p-mt{
   margin-top: 12px;
}
.add-branch-time h4{
    font-size: 13px;
    color: #C0C2C7;
    margin: 0px;
}
.profile-day-check .ant-checkbox + span {
    padding-right: 8px;
    padding-left: 8px;
    padding-bottom: 14px;
    font-size: 16px;
    font-weight: 500;
}
.add-branch-modal-btn{
    gap: 10px;
    display: flex;
}
.add-branch-modal-cancel-btn{
    background: #BFBFBF;
}
.add-branch-modal-check-sec{
    display: flex;
}
.add-branch-modal-right{
    display: flex;
    margin-left: 4%;
}

.add-branch-modal-slot-wd{
    width: 39% ;
    color: #BFBFBF;
    font-size: 15px;
}
.add-branch-modal-line{
    background: #CCCED2;
    margin-left: 3%;
}

.add-branch-modal-slot-details{
    display: flex;
    margin-top: 10px;
    margin-left: 4%;
}
.add-branch-modal-start-time{
    width: 31%;
    color: #BFBFBF;
    font-size: 15px;
    
}
.add-branch-modal-slot-details h6{
    margin-left: 12px;
}
.add-branch-modal-end-time{
    width: 24%;
    color: #3C3C3C;
    font-size: 15px;
}
.add-branch-modal-slot-list-wd{
    width: 45% ;
    color: #3C3C3C;
    font-size: 15px;
}

/* .add-start-time{
    margin-left: 60px;
} */
.profile-modal-plus{
    background: #1E9902;
    padding: 5px;
    height: 22px;
    width: 22px;
    margin-left: 4px;
    margin-top: 2px;
    border-radius: 50%;
    cursor: pointer;
    margin-right: 10px;
}
.visiting-table-dev{
    overflow-x: scroll;
    margin-top: 10px;
}
.slots-table-div{
    display: flex;
    width: max-content;
}
.visiting-table-hor h3{
    font-size: 15px;
    text-transform: uppercase;
    margin: 0px;
}
.visiting-table-hor p{
    padding: 10px 20px 0px 0px;
    font-size: 13px;
    font-weight: 500;
}
.visiting-table-hor span{
    color:#CACACA;
    text-transform: uppercase;
    padding-right: 10px;

}
.check-width{
    width: 25% !important;
}
.visiting-table-hor{
    width: 215px;
}
.add-branch-profile-slot{
    display: flex;
}
.profile-day-check .ant-checkbox-wrapper + .ant-checkbox-wrapper{
margin-left: 0px !important;
}
.add-branch-modal-right-one{
    display: flex;
}
.add-branch-modal-slot-details-one{
    display: flex;
    margin-top: 10px;
}
.add-branch-modal-line-one{
    background: #CCCED2;
}
.add-branch-profile-slot{
    display: flex;
}
.modal-slot-icons{
    display: flex;
}

.modal-adding-slot-sec{
    margin-bottom: 15px;
}

.profile-time-main-sec{
    width: 100%;
    display: flex;
    margin-bottom: 20px;
}
.profile-mornig-sec{
    width: 50%;
    margin-right: 7px;
}
.profile-evng-sec{
    width: 50%;
}
.morning-p{
    margin-bottom: 10px;
    color: green;
}
.profile-branch-show-sec{
    background: #ffffff;
    padding: 10px;
    margin: 10px;
}



@media screen and (min-width:2560px){

    .ant-modal-body,.addbranch-response {
        width: 1300px!important;

    }

}