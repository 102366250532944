.bil-main {
  display: flex;
}
.bil-container {
  width: 80%;
}
.bil-grid-container {
  display: grid;
  background-color: #ffff;
  text-align: left;
  padding: 24px 10px 0 16px;
  font-size: 12px;
}

.bilinputs {
  width: 120px;
  height: 29px;
  border-radius: 4px;
}
.bil-1 {
  grid-column: 1;
  grid-row: 1;
}
.bil-2 {
  grid-column: 2;
  grid-row: 1;
}
.bil-input1 {
  grid-column: 3;
  grid-row: 1;
}
.bil-3 {
  grid-column: 4;
  grid-row: 1;
}
.bil-4 {
  grid-column: 5;
  grid-row: 1;
}
.bil-5 {
  grid-column: 6;
  grid-row: 1;
}
.bil-6 {
  grid-column: 7;
  grid-row: 1;
}
.bil-border1 {
  grid-column: 1 / span 7;
  grid-row: 2;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.bil-7 {
  grid-column: 1;
  grid-row: 3;
}
.bil-8 {
  grid-column: 2;
  grid-row: 3;
}
.bil-input2 {
  grid-column: 3;
  grid-row: 3;
}
.bil-9 {
  grid-column: 4;
  grid-row: 3;
}
.bil-10 {
  grid-column: 5;
  grid-row: 3;
}
.bil-11 {
  grid-column: 6;
  grid-row: 3;
}
.bil-12 {
  grid-column: 7;
  grid-row: 3;
}
.bil-border2 {
  grid-column: 1 / span 7;
  grid-row: 4;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}

.bil-13 {
  grid-column: 1;
  grid-row: 5;
}
.bil-input3 {
  grid-column: 3;
  grid-row: 5;
}
.bil-14 {
  grid-column: 2;
  grid-row: 5;
}
.bil-15 {
  grid-column: 4;
  grid-row: 5;
}
.bil-16 {
  grid-column: 5;
  grid-row: 5;
}
.bil-17 {
  grid-column: 6;
  grid-row: 5;
}
.bil-18 {
  grid-column: 7;
  grid-row: 5;
}
.bil-border3 {
  grid-column: 1 / span 7;
  grid-row: 6;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
