.burea-main {
  display: flex;
}
.burea-container {
  width: 80%;
}
.burea-grid-container {
  display: grid;
  background-color: #ffff;
  text-align: left;
  padding: 24px 10px 0 16px;
  font-size: 12px;
}
.burea-1 {
  grid-column: 1;
  grid-row: 1;
}
.burea-2 {
  grid-column: 2;
  grid-row: 1;
}
.burea-3 {
  grid-column: 3;
  grid-row: 1;
}
.burea-4 {
  grid-column: 4;
  grid-row: 1;
}
.burea-5 {
  grid-column: 5;
  grid-row: 1;
}
.burea-6 {
  grid-column: 6;
  grid-row: 1;
}
.burea-7 {
  grid-column: 1;
  grid-row: 3;
}
.burea-8 {
  grid-column: 2;
  grid-row: 3;
}
.burea-9 {
  grid-column: 3;
  grid-row: 3;
}
.burea-10 {
  grid-column: 4;
  grid-row: 3;
}
.burea-11 {
  grid-column: 5;
  grid-row: 3;
}
.burea-12 {
  grid-column: 6;
  grid-row: 3;
}
