.embryo-main {
  display: flex;
}

.embryo-container {
  width: 80%;
}
.embryo-grid-container {
  display: grid;
  background-color: #ffff;
  text-align: left;
  padding: 24px 10px 0 16px;
  font-size: 12px;
}
.embryo-grid-container textarea {
  height: 32px;
  border-radius: 4px;
}
.embryo-1 {
  grid-column: 1;
  grid-row: 1;
}
.embryo-2 {
  grid-column: 4 / span 3;
  grid-row: 1;
}
.embryo-border1 {
  grid-column: 1 / span 6;
  grid-row: 2;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.embryo-3 {
  grid-column: 1;
  grid-row: 3;
}
.embryo-4 {
  grid-column: 4 / span 3;
  grid-row: 3;
}
.embryo-border2 {
  grid-column: 1 / span 6;
  grid-row: 4;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.embryo-5 {
  grid-column: 1;
  grid-row: 5;
}
.embryo-6 {
  grid-column: 4 / span 3;
  grid-row: 5;
}
.embryo-border3 {
  grid-column: 1 / span 6;
  grid-row: 6;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.embryo-7 {
  grid-column: 1;
  grid-row: 7;
}
.embryo-8 {
  grid-column: 4 / span 3;
  grid-row: 7;
}
.embryo-border4 {
  grid-column: 1 / span 6;
  grid-row: 8;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
