.main-container {
  /* background-color: #1a8e6b !important; */

  height: 100vh;
  background-image: url("../../images/splash.jpg");
  /* background-color: black; */
  /* object-fit: cover; */
  background-size: cover;
  /* text-align: center; */
  .rbi-content {
    /* text-align: center; */
    text-align: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    gap: 30px;
    justify-content: center;
  }

  .apollo-logo {
    padding-top: 40%;
    display: flex;
    justify-content: center;
    display: flex;
    gap: 10px;
  }
  .rbi-logo {
    justify-content: center;
    display: flex;
    width: 75%;
  }
  .text-white {
    color: white;
  }
  .padding-y {
    padding-top: 30px;
  }
  .text-orange {
    color: orange;
  }
  .btn-rounded {
    border-radius: 30px;
    font-weight: bold;
  }
  .btn-rounded:hover {
    color: black;
  }
}


.ant-radio-wrapper {
  margin-left: 8px;
}