.vitals-div {
    display: flex;
    margin: 20px;
    margin-bottom: 10px !important;
}

.vitals-3-div {
    width: 23%;
    /* padding: 8px; */
}

.vitals-3-div h6 {
    font-size: 13px;
    padding: 5px 10px;
    font-weight: 600;
}

.vitals-bmi-whr-div {
    display: flex;
    padding-left: 10px;
    line-height: 5px;
    cursor: pointer;
}

.vitals-bmi-whr-div p{
    font-size: 12px;
    margin-right: 13px;
    color: #19b3f0;
    font-weight: 600;
}

.vitals-antSelect {
    display: flex;
}

.vitals-p-select {
    margin-right: 5px;
    /* line-height: 0px; */
}

.vitals-p-select p {
    font-size: 12px;
    opacity: 0.5;
    margin: 0px;
}

.add-new-div p{
    font-size: 12px;
    margin-top: 5px;
    color: #19b3f0;
    font-weight: 500;
}

.vitals-delete-icon-div {
    width: 24px;
    height: 24px;
    background-color: red;
    border-radius: 50%;
    margin: 20px;
    text-align: center;
}

.vitals-multiselect {
    width: 15vw;
}

.vitals-input {
    width: 15vw;
    border: 1px solid rgb(128,128,128,0.4);
}

.vital-headings{
    display: flex;
}

.vital-headings {
    display: flex;
   
}
.vital-antSelect{
    width: 60%;
}
.vital-days{
    width: 60%;
}
.vital-bp-name{
    width: 25%;
}
.vital-value{
    width: 60%;
}
.vital-names-days{
    display: flex;
}
.vital-name{
    width: 25%;
}
.vital-delect-sec {
    background: red;
    border-radius: 50%;
    padding: 1px 6px 3px;
    margin-top: 5px;
    cursor: pointer;
}
.vitals-value-component{
    display: flex;
}
.vitals-opd-wd{
    width: 30%;
    margin-right: 10px;
}
.vitals-opd-wd-btn{
    margin-top: 22px;
}