.book-edit-container {
  display: flex;
  justify-content: space-between;
}

.book-edit-left-section {
  width: 20%;
}

.book-edit-right-section {
  border-left: 1px solid rgb(236 236 236);
  width: 80%;
  padding: 0px 10px 10px 20px;
}

.left-inputs {
  width: 90%;
}

.left-section-inputs p {
  font-size: 12px;
  color: #aaa;
  font-weight: 700;
}
.left-section-inputs .ant-space.ant-space-vertical {
  width: 100%;
  margin-left: 0px;
}
.left-section-inputs .ant-picker {
  width: 100%;
}
.ant-space-item {
  width: 90%;
  margin: 0px;
}

.left-section-inputs {
  margin-bottom: 5px;
}

.type-test-group-container {
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
}

.type-div {
  width: 17%;
}

.test-div {
  width: 45%;
}

.group-div {
  width: 29%;
}

.type-div p {
  font-size: 10px;
  color: #aaa;
  font-weight: 600;
}

.test-div p {
  font-size: 10px;
  color: #aaa;
  font-weight: 600;
}

.group-div p {
  font-size: 10px;
  color: #aaa;
  font-weight: 600;
}

.disc-rate-discount-net-container {
  display: flex;
  width: 100%;
  margin-left: -11px;
  margin-bottom: 15px;
}
.disc-rate-net-container{
  width: 100%;
  display: flex;
  margin: 10px 0px;
}
.inputs-sec{
  width: 93%;
  display: flex;
  justify-content: space-evenly;
}
.disc-content {
  width: 23%;
}
.button-sec{
  width: 10%;
}
.overall-disc-content{
  width: 19%;
  margin-bottom: 10px;
  margin-right: 10px;
}
.disc-content p {
  font-size: 10px;
  color: #aaa;
  font-weight: 600;
}
.overall-disc-content p{
  font-size: 10px;
  color: #aaa;
  font-weight: 600;
}
.book-edit-font-size {
  font-size: 10px;
}

.table-input-box-grey {
  background-color: rgb(236 236 236);
}
.book-edit-services-table {
  font-size: 10px;
  text-align: left;
  border-bottom: 1px solid rgb(236 236 236);
}

.book-edit-services-head-row {
  position: sticky;
  font-size: 14px;
  border-bottom: 1px solid rgb(236 236 236);
}

.book-edit-services-head-row th {
  color: #aaa;
}

.book-edit-services-head {
  width: 40%;
}

.book-edit-table-delete {
  padding: 5px;
  height: 22px;
  width: 22px;
  background: #ef4036;
  border-radius: 50%;
  cursor: pointer;
}

.book-edit-services-head-row th {
  padding: 10px 0px 5px 10px;
}

.book-edit-services-data-row td {
  font-size: 14px;
  padding: 10px;
  color: #aaa;
}

.net-gross-disc-containter {
     display: flex;
     font-weight: 500;
     margin-top: 15px;
}

.net-gross-disc-containter p {
  padding: 5px 10px 0px 20px;
  font-weight: 600;
}

.net-gross-disc-containter span {
  padding: 5px 0px 0px 0px;
  font-weight: 600;
}

.book-edit-cash-input {
  display: flex;
  gap: 10px;
}

.book-edit-cash-type-container p {
  font-size: 10px;
  color: #aaa;
  font-weight: 600;
}

.cash-input {
  width: 25%;
}

.book-edit-cash-grey-input {
  width: 10%;
}

.cash-input p {
  font-size: 10px;
  font-weight: 600;
}

.book-edit-button-container {
  display: flex;
  gap: 5px;
  margin-top: 20px;
  margin-left: 21rem;
}

.book-edit-save-button {
  font-size: 12px;
  height: 38px;
}

.book-edit-cancel-button {
  background-color: #bfbfbf;
  font-size: 12px;
  height: 38px;
}

.book-edit-add-button {
  font-size: 12px;
  height: 30px;
  margin-top: 16px;
}
