
.appointments-payments-time {
    display: flex;
    margin-right: 5px;
}

.appointments-payments-time p {
    font-size: 12px;
    margin: 5px;
    font-weight: 500;
}

.save-checkout {
    display: flex;
    gap: 5px;
    margin: 5px 0px;
}

.offline {
    color: rgb(11, 192, 247);
    font-size: 13px;
    font-weight: 600;
}

.arrived-time {
    color: rgb(11, 192, 247);
    font-size: 12px;
    font-weight: 600;

}

.time-duration {
    color: red;
    font-size: 12px;
    font-weight: 600;
}

.pending {
    color: red;
    font-weight: 600;
}
.complete{
    color: green;
    font-weight: 600;
}

@media screen and (min-width: 1364px) {
  .appointments-payments-time  {
    /* width: 30%; */
    }
    .appointments-payments-time p {
       font-size: 10px!important;
      
      }
  }

  
@media screen and (min-width: 1440px) {
    .appointments-payments-time  {
      /* width: 30%; */
      }
      .appointments-payments-time p {
         font-size: 12px!important;
        
        }
    }