.lft-parent {
  display: flex;
}
.lft-left {
  width: 80%;
  margin-left: 5px;
  margin-top: 20px;
}
.lft-grid {
  display: grid;
  background-color: white;
  padding: 5px 10px 0 10px;
  font-size: 12px;
}
.lft-1 {
  grid-column: 1;
  grid-row: 1;
  width: 150px;
}
.lft-2 {
  grid-column: 2;
  grid-row: 1;
}
.lft-3 {
  grid-column: 3;
  grid-row: 1;
}
.lft-4 {
  grid-column: 4;
  grid-row: 1;
}
.lft-5 {
  grid-column: 5;
  grid-row: 1;
}
.lft-6 {
  grid-column: 6;
  grid-row: 1;
}
.lft-input {
  width: 120px;
  border-radius: 5px;
}
.lft-border {
  grid-column: 1 / span 6;
  grid-row: 2;
  border-bottom: 1px solid #d9d9d9;
}
.lft-border1 {
  grid-column: 1 / span 6;
  grid-row: 4;
  border-bottom: 1px solid #d9d9d9;
}
.lft-border2 {
  grid-column: 1 / span 6;
  grid-row: 6;
  border-bottom: 1px solid #d9d9d9;
}
.lft-border3 {
  grid-column: 1 / span 6;
  grid-row: 8;
  border-bottom: 1px solid #d9d9d9;
}
.lft-border4 {
  grid-column: 1 / span 6;
  grid-row: 10;
  border-bottom: 1px solid #d9d9d9;
}
.lft-border5 {
  grid-column: 1 / span 6;
  grid-row: 12;
  border-bottom: 1px solid #d9d9d9;
}
.lft-border6 {
  grid-column: 1 / span 6;
  grid-row: 14;
  border-bottom: 1px solid #d9d9d9;
}
.lft-border7 {
  grid-column: 1 / span 6;
  grid-row: 16;
  border-bottom: 1px solid #d9d9d9;
}
.lft-border8 {
  grid-column: 1 / span 6;
  grid-row: 18;
  border-bottom: 1px solid #d9d9d9;
}
.lft-border9 {
  grid-column: 1 / span 6;
  grid-row: 20;
  border-bottom: 1px solid #d9d9d9;
}
.l-pad {
  padding: 6px 5px;
}
.lft-foot {
  margin-top: 20px;
  padding-left: 5px;
}
.lft-foot .add-image-input {
  background-color: white;
}

.lft-p {
  padding: 15px 10px;
  font-size: 12px;
  line-height: 015px;
  font-weight: 700;
}
.lft-p h4 {
  font-weight: 800;
  padding-bottom: 5px;
}
.lft-p span {
  font-weight: 500;
}
