.special-notes-div {
    display: flex;
    margin: 20px;
}

.special-notes-12-div {
    width: 23%;
    line-height: 20px;
}

.special-notes-12-div h6 {
    font-size: 13px;
    padding: 5px 10px;
    font-weight: 600;
}

.special-notes-antSelect {
    display: flex;
    width: 70%;
}

.new-note p {
    font-weight: 500;
    font-weight: 12px;
    color: #19b3f0;
    margin-top: 5px;
    cursor: pointer;
}


.special-notes-multiselect1 {
    width: 12vw;
    border: 1px solid rgb(128, 128, 128, 0.4);
    margin-right: 10px;
}

.special-notes-multiselect2 {
    width: 29.5vw;
    border: 1px solid rgb(128, 128, 128, 0.4);
    height: 7rem;
}

.speacial-notes-editor {
    border: 1px solid #D8D8D8;
    max-height: 12rem;
    max-width: 530px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    cursor: pointer;
    background-color: #ffffff;
}

.speacial-notes-editor::-webkit-scrollbar {
    display: none;
}

.new-note {
    width: 30%;
}

.speacial-notes-editor .rdw-editor-toolbar {
    display: flex;
    padding: 5px;
    background: #F6F6F6;
    margin-bottom: 5px;
}

.speacial-notes-editor .rdw-dropdown-optionwrapper {
    overflow-y: scroll;
}

.speacial-notes-editor .rdw-image-wrapper,
.rdw-emoji-wrapper,
.rdw-embedded-wrapper,
.rdw-link-wrapper,
.rdw-colorpicker-wrapper,
.rdw-history-wrapper,
.rdw-fontsize-wrapper,
.rdw-fontfamily-wrapper {
    display: none;
}

.speacial-notes-editor .rdw-option-wrapper {
    margin: 0px 10px;
}

.speacial-notes-editor .rdw-inline-wrapper,
.rdw-text-align-wrapper,
.rdw-list-wrapper {
    display: flex;
}

.speacial-notes-editor .rdw-option-disabled,
.rdw-block-wrapper {
    display: none;
}

.speacial-notes-editor .public-DraftStyleDefault-ltr {
    padding-left: 5px;
    
}

.bank-note-sec {
    display: flex;
    flex-wrap: wrap;
}

.bank-note-sec p {
    color: black;
    font-weight: 400;
    margin-right: 10px;
}

.bank-note-remove {
    color: rgb(244, 108, 107);
    margin-left: 5px;
}

.public-DraftEditorPlaceholder-root {
    padding: 0px 0px 0px 4px !important;
}

.speacial-notes-editor .public-DraftStyleDefault-ltr {
    padding-left: 5px;
    padding-bottom: 10px;
}