.obstetricUsg-grid {
  display: grid;
  gap: 0;
  background-color: white;
  padding: 5px 10px 0 10px;
  font-size: 12px;
}
.obstetricUsg-parent {
  display: flex;
}
.obstetricUsg-left {
  width: 77%;
  margin: 15px 0 0 5px;
}
.obstetricUsg-1 {
  grid-column: 1;
  grid-row: 1;
}
.obstetricUsg-2 {
  grid-column: 2;
  grid-row: 1;
}
.obstetricUsg-text {
  padding-top: 1px;
}
.obstetricUsg-text textarea {
  height: 32px;
  border-radius: 4px;
  border: 1px solid rgb(210, 210, 210);
}
.obstetricUsg-border {
  grid-column: 1 / span 2;
  grid-row: 2;
  border-bottom: 1px solid #d9d9d9;
}
.obstetricUsg-border1 {
  grid-column: 1 / span 2;
  grid-row: 4;
  border-bottom: 1px solid #d9d9d9;
}
.obstetricUsg-pad {
  padding: 7px 0 0 5px;
}
.obstetricUsg-foot {
  margin-top: 20px;
  padding-left: 10px;
}
.obstetricUsg-foot .add-image-input {
  background-color: white;
}
