.forward-div {
    display: flex;
    margin: 20px;
    margin-bottom: 5rem ;
}

.forward-15-div {
    width: 23%;
    line-height: 20px;
}

.forward-15-div h6 {
    font-size: 13px;
    padding: 5px 10px;
    font-weight: 600;
}

.forward-15-div p {
    padding-left:10px;
    font-weight: 500;
    font-weight: 12px;
    color: #19b3f0;
    line-height: 0px;

}

.forward-antSelect {
}

