.grid-container {
  display: grid;
  gap: 10px;
  background-color: white;
  padding: 5px 10px 0 10px;
  font-size: 12px;
  border-bottom: 1px solid #d9d9d9;
}

.item6 {
  grid-column: 1;
  grid-row: 1;
  width: 190px;
}

.item7 {
  grid-column: 2;
  grid-row: 1;
  width: 211px;
}

.item8 {
  grid-column: 3;
  grid-row: 1;
}

.item9 {
  grid-column: 4;
  grid-row: 1;
  width: 61px;
}

.item10 {
  grid-column: 5;
  grid-row: 1;
  width: 94px;
}
.item18 {
  grid-column: 6;
  grid-row: 1;
}

.test-report-container {
  display: flex;
}

.tesinput {
  border-radius: 5px;
}

.add-image-input {
  border-radius: 5px;
  /* background-color: #f5f5f5; */
}

.add-image-btn img {
  padding-right: 5px;
}

.add-image-btn button {
  width: 100%;
  font-weight: 500;
  border-radius: 15px;
  padding: 6px 16px 4px 20px;
  background-color: white;
  color: gray;
  border: 1px solid #d9d9d9;
}
.add-image-btn {
  width: 15%;
}
.add-input {
  width: 87%;
}

.test-report-main {
  margin-top: 20px;
  margin-left: 10px;
}

.test-report-left-sec {
  width: 75%;
}

.auto-button Button {
  background-color: gray;
}

.test-report-container button {
  font-weight: 500;
  border-radius: 15px;
  padding: 6px 16px 4px 20px;
}
.test-report-main Textarea {
  background-color: #f5f5f5;
}

.add-image-input textarea {
  background-color: #f0f8fa !important;
}

.cbc-add-image {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-right: 10px;
}

.test-report-container img {
  padding-right: 5px;
}

.inputs {
  width: 120px;
  border-radius: 5px;
  height: 27px;
  background-color: white;
  margin-top: 2px;
}

.test-report-main .ant-input-affix-wrapper > input.ant-input {
  background-color: #ffff;
}

.cbc-right-sec .ant-input-affix-wrapper {
  background-color: #ffff;
}
.cbc-right-sec .test-report-input textarea {
  background-color: #ffff;
}
.Report-Image {
  display: flex;
}
.Report-Img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
}
.report-footer-img {
  width: auto;
  height: 200px;
}
