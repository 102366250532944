.hb-parent {
  display: flex;
}
.hb-left {
  width: 80%;
  margin-left: 5px;
  margin-top: 20px;
}
.hb-grid {
  display: grid;
  background-color: white;
  padding: 5px 10px 0 10px;
  font-size: 12px;
}
.hb1 {
  grid-column: 1;
  grid-row: 1;
  width: 150px;
}
.hb2 {
  grid-column: 2;
  grid-row: 1;
}
.hb3 {
  grid-column: 3;
  grid-row: 1;
}
.hb4 {
  grid-column: 4;
  grid-row: 1;
}
.hb5 {
  grid-column: 5;
  grid-row: 1;
}
.hb6 {
  grid-column: 6;
  grid-row: 1;
}
.hb-input {
  width: 120px;
  border-radius: 5px;
}
.hb-border {
  grid-column: 1 / span 6;
  grid-row: 2;
  border-bottom: 1px solid #d9d9d9;
}
.hb-border1 {
  grid-column: 1 / span 6;
  grid-row: 4;
  border-bottom: 1px solid #d9d9d9;
}
.hb-border2 {
  grid-column: 1 / span 6;
  grid-row: 6;
  border-bottom: 1px solid #d9d9d9;
}
.hb-border3 {
  grid-column: 1 / span 6;
  grid-row: 8;
  border-bottom: 1px solid #d9d9d9;
}
.hb-border4 {
  grid-column: 1 / span 6;
  grid-row: 10;
  border-bottom: 1px solid #d9d9d9;
}
.hb-border5 {
  grid-column: 1 / span 6;
  grid-row: 12;
  border-bottom: 1px solid #d9d9d9;
}
.hb-border6 {
  grid-column: 1 / span 6;
  grid-row: 14;
  border-bottom: 1px solid #d9d9d9;
}
.hb-border7 {
  grid-column: 1 / span 6;
  grid-row: 16;
  border-bottom: 1px solid #d9d9d9;
}
.hb-border8 {
  grid-column: 1 / span 6;
  grid-row: 18;
  border-bottom: 1px solid #d9d9d9;
}
.h-pad {
  padding: 6px 5px;
}
.hb-foot {
  margin-top: 20px;
  padding-left: 5px;
}
.hb-foot .add-image-input {
  background-color: white;
}
.growth-btn {
  width: 70%;
  display: flex;
  justify-content: space-between;
  margin-left: 5px;
  margin-bottom: 20px;
}
.gwrt {
  background-color: white;
  color: rgb(80, 78, 78);
  font-size: 12px;
  font-weight: 500;
  border: 1px solid #d9d9d9;
  border-radius: 18px;
  padding: 5px;
}
img.white-img {
  width: 15px;
  margin-left: 10px;
  margin-right: 10px;
}
img.blue-img {
  width: 11px;
  margin-left: 7px;
  margin-right: 11px;
}
