.printOptions {
  font-size: 12px;
  margin-top: 12px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
}

.print-options-main {
  display: block;
}

.prescription-options {
  margin-top: 6px;
  display: flex;
  justify-content: end;
  margin-left: 6px;
}

.prescription-options Button {
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 2px 8px;
  font-size: 13px;
  border-radius: 3px;
  color: rgba(0, 0, 0, 0.85);
  border-color: #d9d9d9;
  background: #fff;
  margin: 0px;
}

print-active::after {
  background-color: #fdc132;
}

.print-options-container {
  display: flex;
  gap: 5px;
  min-width: 72%;
}

.print-invoice-main-container {
  width: 51%;
  margin-right: 5px;
}

/* .print-options-main{
  display: flex;
} */

.invoice-toggle .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 28px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.invoice-toggle .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: red;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.invoice-toggle .slider:before {
  position: absolute;
  content: "";
  height: 23px;
  width: 23px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.invoice-toggle input:checked+.slider {
  background-color: #d7d7d7;
}

input:focus+.slider {
  box-shadow: 0 0 1px white;
}

.invoice-toggle input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(29px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.invoice-toggle-label {
  font-size: 21px;
  font-weight: 600;
  position: relative;
  top: 6px;
  right: 10px;
}

.invoice-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.invoice-toggle {
  margin-right: 30px;
}

.ant-modal-close-x {
  padding: 18px;
}

.invoice-visit-date {
  font-size: 14px;
  font-weight: 500;
}

.hr-line {
  width: 99.8%;
  border: 1px solid #d7d7d7;
}

.calls-modal-btn {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.invoice-heading {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0;
  font-weight: 900;
}

.invoice-profile-detail {
  display: flex;
  width: 100%;
  margin: 10px 0;
}

.invoice-profile-detail img {
  height: 50px;
}

.invoice-profile-detail-text {
  line-height: 1;
  margin-left: 10px;
  margin-top: 5px;
}

.invoice-profile-detail-text h3 {
  font-size: 20px;
  font-weight: 600;
}

.invoice-profile-detail-text p {
  font-weight: 500;
  font-size: 14px;
  word-spacing: 2px;
}

.invoice-profile-detail-text p span {
  color: #00aeef;
}

.invoice-table .ant-table-thead>tr>th {
  background-color: white !important;
  color: #959595 !important;
  padding: 10px 0px;
  border-bottom: 1px solid #959595;
}

.invoice-table .ant-table-pagination.ant-pagination {
  /* margin: 16px 16px; */
  display: none;
}

.invoice-table-delete {
  width: 25px;
  height: 25px;
}

.invoice-table .ant-table-tbody>tr>td {
  border-bottom: 0px;
  background-color: white;
  padding: 10px 0px;
}

.invoice-input1 {
  width: 320px;
  color: #959595;
}

.invoice-input2 {
  width: 80px;
  color: #959595;
}

.invoice-input3 {
  width: 80px;
  /* color: #959595;
  background-color: #959595; */
}

.invoice-input-container {
  width: 97%;
  display: flex;
  justify-content: space-evenly;
  margin-left: -20px;
}

.invoice-modal-btn {
  background-color: green;
  margin-top: 19px;
  padding: 5px 20px;
  height: 25x;
}

.invoice-input-container p {
  color: #959595;
  font-size: 12px;
  font-weight: 800;
}

.invoice-table-two .ant-table-thead>tr>th {
  background-color: white !important;
  color: #959595 !important;
  padding: 10px 0px;
  border-bottom: 0px;
}

.invoice-table-two .ant-table-tbody>tr>td :hover {
  background-color: white !important;
}

.invoice-table-two .ant-table-pagination.ant-pagination {
  display: none;
}

.invoice-table-two .ant-table-tbody>tr.ant-table-row:hover>td,
.ant-table-tbody>tr>td.ant-table-cell-row-hover {
  background: #ffffff;
}

.invoice-input-select {
  width: 150px;
}

.invoice-input4 {
  width: 100px;
  background-color: white;
}

.invoice-input-select {
  width: 100px;
}

.invoice-input-container1 {
  display: flex;
  justify-content: space-evenly;
  width: 70%;
  justify-items: flex-start;
  margin-top: 10px;
  margin-left: -20px;
}

.invoice-input-container1 p {
  color: #959595;
  font-size: 12px;
  font-weight: 800;
}

.invoice-btn-container {
  display: flex;
  width: 70%;
  justify-items: flex-start;
  margin-top: 10px;
}

.invoice-modal-btn1 {
  background-color: #f7941d;
  margin-top: 20px;
  padding: 5px 20px;
  font-weight: 100;
  font-size: 14px;
  margin-right: 10px;
}

.invoice-modal-btn2 {
  background-color: #00aeef;
  margin-top: 20px;
  padding: 5px 20px;
  font-weight: 100;
  font-size: 14px;
}

.invoice-table-two .ant-table-tbody>tr>td {
  border-bottom: 0px;
  background-color: white;
}

.preview-report-btn {
  font-size: 10px;
  font-weight: 700;
  padding: 5px;
}

.invoice-btn {
  background-color: #007D9D;
  font-weight: 700;
  padding: 12px 12px;
  font-size: 10px;
}

.prescription-btn {
  background-color: red;
  font-weight: 700;
  padding: 5px 12px;
  font-size: 10px;
}

.select-language-input {
  font-size: 10px;
  min-width: 33%;
  margin-right: 5px;
}

.invoice-table .ant-select {
  width: 50% !important;
}

.medicine-invoice-headings {
  display: flex;
}

.opd-invoice-headings {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: flex-start;
}

.service-type-sec {
  width: 50%;
}

.disc-gross {
  display: flex;
}

.opd-invoice-gross {
  padding-right: 20px;
}

.opd-invoice-disc {
  padding-right: 10px;
  margin-right: 2%;
}

.opd-discount-input-sec {
  display: flex;
  margin-bottom: 10px;
  margin-top: 10px;
}

.empty-p-tag {
  width: 50%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 50%;

}

.opd-name {
  width: 7%;
  margin-left: 25px;
}

.opd-days {
  width: 11%;
}

.opd-doseage {
  width: 10%;
}

.opd-invoice-discount {
  padding-right: 17px;
  margin-left: 13%;
}

.opd-delete-sec {
  background: red;
  border-radius: 50%;
  padding: 0px 5px 2px 5px;
  margin-left: 75%;
  cursor: pointer;
  height: 23px;
}

.displaytotalDue {
  display: flex;
}

.payable-day {
  width: 20%;
}

.totaldue {
  width: 20%;
}

.totalDisc {
  width: 20%;
}

.display-total-pending {
  display: flex;
  padding: 10px;
}

.totalpend {
  width: 20%;

}

.pastpending {
  width: 20%;
}

.opd-intake {
  margin-left: 13px;
}

.opd-cost {
  margin-left: 6px;
  width: 10%;
}

.opd-invoice-action {
  margin-left: 11px;
}

.opd-delete-sec {
  margin-left: 11%;
}

.invoice-toggle-label {
  padding-left: 20px;
}

/* .previewReport-iframe-div {
  width: 100%;
  height: 1000px;
} */
/* .previewReport-iframe {
  width: 100%;
  height: 2000px;
} */


.default-bg-color {
  background-color: #fdc132 !important;
  color: #ffffff !important;
}

.report-style{
  font-size: 12px;
}


@media screen and (min-width:1024px) {

  .print-inv {
    padding-top: 1px;
    font-size: 10px;
    padding-bottom: 1px;
    font-weight: 700;
    height: 32px;
  }


  .invoice-btn {
    padding: 8px 12px;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-top: 1px;

  }

  .prescription-btn {
    padding-top: 1px;
    padding-bottom: 1px;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-top: 1px;
    height: 32px;
  }
}

@media screen and (min-width:1345px) {
  .print-options-main {
    display: flex;
  }

  .prescription-options {
    margin-top: 2px;
    gap: 5px;
    /* justify-content: none; */
    width: 28%;
  }

}

@media screen and (min-width:1440px){
  .print-options-container{
    min-width: 73%;
  }
}

@media screen and (min-width:1880px) {

  .print-inv {
    padding: 2px 27px 2px 27px;
    font-size: 10px;
    margin-left: 16px !important;
    margin-right: 16px !important;
  }

  .invoice-btn {
    padding: 9px 25px 8px 25px;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-top: 1px;
    margin-right: 16px !important;

  }

  .prescription-btn {
    padding: 2px 20px 2px 20px;
  }

  .print-options-container{
    min-width: 74%;
  }
}




@media screen and (min-width:2560px) {
  .invoice-btn {
    margin-right: 42px;
    margin-left: 18px;
    padding: 9px 35px 8px 35px;
  }

  .opd-invoice-disc {
    padding-right: 10px;
    margin-right: 14%;
  }

  .opd-invoice-gross {
    padding-right: 20px;
  }

  .opd-invoice-discount {
    padding-right: 17px;
    margin-left: 23%;
  }

  .opd-invoice-action {
    margin-left: 40%;
  }

  .prescription-btn {
    margin-left: 18px;
    padding: 2px 35px 2px 35px;
  }

  .select-language-input {
    margin-right: 18px;
  }

  .print-inv {
    padding: 2px 35px 2px 35px;
  }

  .prescription-options {
    margin-left: 15px;
  }

}