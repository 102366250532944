.home-main-div {
    background-color: #4dd3d6;
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
}
.home-doctor-sec img{
    width: 250px;
    height: 250px;
    border-radius: 50%;
    margin-right: 30px;
    cursor: pointer;
}
.home-doctor-sec h1{
    color: #ffffff;
    font-weight: 600;
    font-size: 30px;
    text-align: center;
    padding-right: 20px;
    padding-top: 10px;
}
