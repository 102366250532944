
.user-icon {
    font-size: 45px;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: -5%;
}

.profile {
    display: flex;
    /* line-height: 0px; */
}

.align-center {
    align-items: center;
    margin: 14px 0;
    
}

.patient-details-container {
    /* margin-right: 25px; */
    width: 70%;
}

.patient-name {
    /* padding-left: 10px; */
    padding-top: 10px;
    font-size: 20px;
    font-weight: 700;
    margin: 0;
}
.edit-icon {
    font-size: 17px;
    padding-top: 12px;
    padding-left: 10px;
}

.view-more-p {
    font-size: 11px;
    color: #0BC0F7;
    cursor: pointer;
}

.patient-details {
    /* padding:0px 10px; */
    /* line-height: 0px; */
    /* padding-left: 10px; */
}

.patient-details p {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: -3px;
}

.patient-details span {
    padding: 0px 4px;
}

.patient-details a {
    font-size: 11px;
    padding: 0px 5px;
}

.speech{
    width: 16%;
}

.speech-icon {
   margin: 20px 5px 0px 10px;
   width: 35px;
  
}
.speech button{
    padding: 5px;
    margin: 25px 0px 0px 8px;
}

.mic{
    text-align: center;
    color: dodgerblue;
    font-size: 11px;
}


.edit-patient-modal {
    justify-content: center;
    display: flex;
    margin-bottom: 20px;
}

.patient-details-modal {
    padding: 10px;
}

.patient-details-modal h1 {
    text-align: left;
    justify-content: center;
    display: flex;
    font-weight: 700;
    padding-top: 10px;
    font-weight: 18px;
}

.patient-details-modal-first-div div {
    width: 200px;
}

.patient-details-modal-first-div div h3 {
    font-weight: 700;
    margin-bottom: 0px;
}

.patient-details-modal-first-div {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.video-camera-icon {
    width: 36px;
    padding: 20px 1px 0px 0px;
    margin-left: 10px;
}

.disable-video {
    cursor: default;
    pointer-events: none;
    opacity:0.2;
}

.video-icon{
    /* width: 1%; */
}
.icon-video{
    width: 1%;
}

.patient-profile-main{
    width: 47%;
    margin-left: 1%;
}

/* @media screen and (min-width:2560px){

    .ant-modal-body,.bigdevice {
        width: 1300px!important;

    }
} */
@media screen and (min-width:1280px) {
    .speech {
        width: 8%;
    }
    .speech-icon {
        margin: 20px 5px 0px 5px;
    }
    
}


@media screen and (min-width:1440px) {
    .speech {
        width: 8%;
    }
    
}

@media screen and (min-width:1880px) {
    .speech {
        width: 7.5% !important;
    }
    .patient-profile-main{
        width: 47%;
    }
    
}

@media screen and (min-width:2560px){

    .ant-modal-body,.bigdevice {
        width: 1300px!important;

    }
    .speech {
        width: 7% !important;
    }
    .patient-profile-main{
        width: 33%;
    }

}










/* @media screen and (min-width:1024px) {

    .ant-modal-body,.bigdevice {
        width: 900px!important;

    }

} */










 @media screen and (min-width:2560px){

    .user-icon{
        
            font-size: 45px;
            padding-left: 5px;
            padding-right: 5px;
            margin-top: 1%;
        
    }

   
} 

@media screen and (min-width:1440px){

    .user-icon{
        
            font-size: 45px;
            padding-left: 5px;
            padding-right: 5px;
            margin-top: 1%;
        
    }

   
} 

@media screen and (min-width:1024px){

    .user-icon{
        
            font-size: 45px;
            padding-left: 5px;
            padding-right: 5px;
            margin-top: 1%;
        
    }

   
} 


  
