.edit-name{
    padding: 13px 10px;
    background: #F0F8FA !important;
    border-bottom: 2px solid rgb(203 201 201);
}
.edit-name h1{
    font-size: 22px;
    font-weight: 700;
    color: #000000;
    margin: 5px 0px 0px 15px
}