.stoolExamination-grid {
  display: grid;
  gap: 0;
  background-color: white;
  padding: 5px 10px 0 10px;
  font-size: 12px;
}
.stoolExamination-parent {
  display: flex;
}
.stoolExamination-left {
  width: 77%;
  margin: 15px 0 0 5px;
}
.stoolExamination-1 {
  grid-column: 1;
  grid-row: 1;
}
.stoolExamination-2 {
  grid-column: 2;
  grid-row: 1;
}
.stoolExamination-text {
  padding-top: 1px;
}
.stoolExamination-text textarea {
  height: 32px;
  border-radius: 4px;
  border: 1px solid rgb(210, 210, 210);
}
.stoolExamination-border {
  grid-column: 1 / span 2;
  grid-row: 2;
  border-bottom: 1px solid #d9d9d9;
}
.stoolExamination-border1 {
  grid-column: 1 / span 2;
  grid-row: 4;
  border-bottom: 1px solid #d9d9d9;
}
.stoolExamination-border2 {
  grid-column: 1 / span 2;
  grid-row: 6;
  border-bottom: 1px solid #d9d9d9;
}
.stoolExamination-border3 {
  grid-column: 1 / span 2;
  grid-row: 8;
  border-bottom: 1px solid #d9d9d9;
}
.stoolExamination-border4 {
  grid-column: 1 / span 2;
  grid-row: 10;
  border-bottom: 1px solid #d9d9d9;
}
.stoolExamination-border5 {
  grid-column: 1 / span 2;
  grid-row: 12;
  border-bottom: 1px solid #d9d9d9;
}
.stoolExamination-border6 {
  grid-column: 1 / span 2;
  grid-row: 14;
  border-bottom: 1px solid #d9d9d9;
}
.stoolExamination-border7 {
  grid-column: 1 / span 2;
  grid-row: 16;
  border-bottom: 1px solid #d9d9d9;
}
.stoolExamination-border8 {
  grid-column: 1 / span 2;
  grid-row: 18;
  border-bottom: 1px solid #d9d9d9;
}
.stoolExamination-border9 {
  grid-column: 1 / span 2;
  grid-row: 20;
  border-bottom: 1px solid #d9d9d9;
}
.stoolExamination-border10 {
  grid-column: 1 / span 2;
  grid-row: 22;
  border-bottom: 1px solid #d9d9d9;
}
.stoolExamination-border11 {
  grid-column: 1 / span 2;
  grid-row: 24;
  border-bottom: 1px solid #d9d9d9;
}
.stoolExamination-border12 {
  grid-column: 1 / span 2;
  grid-row: 26;
  border-bottom: 1px solid #d9d9d9;
}
.stoolExamination-pad {
  padding: 7px 0 0 5px;
}
.stoolExamination-foot {
  margin-top: 20px;
  padding-left: 10px;
}
.stoolExamination-foot .add-image-input {
  background-color: white;
}

/* .stoolExamination-no-data{
    padding: 25px;    
} */
