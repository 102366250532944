.vaccine-main-section {
  margin: 20px;

}

/* .vaccine-input-section {
    margin: 0.3% 0%;
    display: flex;
    width: 99%;
    background: white;
    border: 1px solid #d7d7d7;
    padding: 5px;
  } */

.vaccine-input-section {
  margin: 0.3% 0%;
  /* display: flex; */
  width: 99%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: auto;
  /* width: 867px; */
  background: white;
  border: 1px solid #d7d7d7;
  padding: 5px;
}


.vaccine-birth-title {
  width: 15%;
  display: flex;
  padding-right: 5px;
  text-align: right;
  align-items: center;
  justify-content: flex-end;
}

.bcg-sec {
  justify-content: space-between;
  width: 25%;
  padding: 5px;

}

.bcg-title-input {
  display: flex;
  gap: 5px;
}

.bcg-input {
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  align-items: center;
  padding-right: 15px;
  padding-bottom: 5px;
}

.vacc-inc-date {
  padding-left: 15px;
}

.bcg-entire-section {
  display: flex;
}

.bcggg-sec {
  width: 27%;
  padding-left: 10px;
}

.vaccine-input1 {
  accent-color: green;
}


.vaccine-name-heading {

  font-size: 12px;
  color: black;
  font-weight: 600;

}

.conjugate-sec {

  width: 27%;
  padding-left: 12px;

}

.bcg-delete-edit-btn-section {
  display: flex;
  width: 27%;
}

.displaytotalDue {
  display: flex;
}



@media screen and (min-width:1880px) {
  .vac-save-btn {
    margin-top: 5%;

    margin-left: 40%;
    padding: 9px 20px 9px !important;
    font-size: 18px !important;
  }
}


.vac-save-btn {
  margin-top: 5%;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-left: 40%;
  padding: 9px 20px 9px;
  font-size: 14px;
}