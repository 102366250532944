.urineAnalysis-parent {
  display: flex;
}
.urineAnalysis-left {
  width: 77%;
  margin-top: 20px;
  margin-left: 5px;
}
.urineAnalysis-grid {
  display: grid;
  background-color: white;
  padding: 5px 10px 0 10px;
  font-size: 12px;
}
.urineAnalysis-1 {
  grid-column: 1;
  grid-row: 1;
}
.urineAnalysis-2 {
  grid-column: 2;
  grid-row: 1;
}

.urineAnalysis-3 {
  grid-column: 3;
  grid-row: 1;
}
.urineAnalysis-4 {
  grid-column: 4;
  grid-row: 1;
}
.urineAnalysis-5 {
  grid-column: 5;
  grid-row: 1;
}

.urineAnalysis-border-1 {
  grid-column: 1 / span 5;
  grid-row: 2;
  border-bottom: 1px solid #d9d9d9;
}
.urineAnalysis-6 {
  grid-column: 1;
  grid-row: 3;
}
.urineAnalysis-7 {
  grid-column: 2;
  grid-row: 3;
}

.urineAnalysis-8 {
  grid-column: 3;
  grid-row: 3;
}
.urineAnalysis-9 {
  grid-column: 4;
  grid-row: 3;
}
.urineAnalysis-10 {
  grid-column: 5;
  grid-row: 3;
}

.urineAnalysis-border-2 {
  grid-column: 1 / span 5;
  grid-row: 4;
  border-bottom: 1px solid #d9d9d9;
}
.urineAnalysis-11 {
  grid-column: 1;
  grid-row: 5;
}
.urineAnalysis-12 {
  grid-column: 2;
  grid-row: 5;
}

.urineAnalysis-13 {
  grid-column: 3;
  grid-row: 5;
}
.urineAnalysis-14 {
  grid-column: 4;
  grid-row: 5;
}
.urineAnalysis-15 {
  grid-column: 5;
  grid-row: 5;
}

.urineAnalysis-border-3 {
  grid-column: 1 / span 5;
  grid-row: 6;
  border-bottom: 1px solid #d9d9d9;
}

.urineAnalysis-border-4 {
  grid-column: 1 / span 5;
  grid-row: 8;
  border-bottom: 1px solid #d9d9d9;
}

.urineAnalysis-border-5 {
  grid-column: 1 / span 5;
  grid-row: 10;
  border-bottom: 1px solid #d9d9d9;
}
.urineAnalysis-border-6 {
  grid-column: 1 / span 5;
  grid-row: 12;
  border-bottom: 1px solid #d9d9d9;
}
.urineAnalysis-border-7 {
  grid-column: 1 / span 5;
  grid-row: 14;
  border-bottom: 1px solid #d9d9d9;
}
.urineAnalysis-border-8 {
  grid-column: 1 / span 5;
  grid-row: 16;
  border-bottom: 1px solid #d9d9d9;
}
.urineAnalysis-border-9 {
  grid-column: 1 / span 5;
  grid-row: 18;
  border-bottom: 1px solid #d9d9d9;
}
.urineAnalysis-border-10 {
  grid-column: 1 / span 5;
  grid-row: 20;
  border-bottom: 1px solid #d9d9d9;
}
.urineAnalysis-border-11 {
  grid-column: 1 / span 5;
  grid-row: 22;
  border-bottom: 1px solid #d9d9d9;
}
.urineAnalysis-border-12 {
  grid-column: 1 / span 5;
  grid-row: 24;
  border-bottom: 1px solid #d9d9d9;
}
.urineAnalysis-border-13 {
  grid-column: 1 / span 5;
  grid-row: 26;
  border-bottom: 1px solid #d9d9d9;
}
.urineAnalysis-border-14 {
  grid-column: 1 / span 5;
  grid-row: 28;
  border-bottom: 1px solid #d9d9d9;
}
.urineAnalysis-border-15 {
  grid-column: 1 / span 5;
  grid-row: 30;
  border-bottom: 1px solid #d9d9d9;
}
.urineAnalysis-border-16 {
  grid-column: 1 / span 5;
  grid-row: 32;
  border-bottom: 1px solid #d9d9d9;
}
.urineAnalysis-border-17 {
  grid-column: 1 / span 5;
  grid-row: 34;
  border-bottom: 1px solid #d9d9d9;
}
.urineAnalysis-border-18 {
  grid-column: 1 / span 5;
  grid-row: 36;
  border-bottom: 1px solid #d9d9d9;
}
.urineAnalysis-border-19 {
  grid-column: 1 / span 5;
  grid-row: 38;
  border-bottom: 1px solid #d9d9d9;
}
.urineAnalysis-border-20 {
  grid-column: 1 / span 5;
  grid-row: 40;
  border-bottom: 1px solid #d9d9d9;
}
.urineAnalysis-border-21 {
  grid-column: 1 / span 5;
  grid-row: 42;
  border-bottom: 1px solid #d9d9d9;
}
.urineAnalysis-border-22 {
  grid-column: 1 / span 5;
  grid-row: 44;
  border-bottom: 1px solid #d9d9d9;
}
.urineAnalysis-border-23 {
  grid-column: 1 / span 5;
  grid-row: 46;
  border-bottom: 1px solid #d9d9d9;
}
.urineAnalysis-border-24 {
  grid-column: 1 / span 5;
  grid-row: 48;
  border-bottom: 1px solid #d9d9d9;
}
.urineAnalysis-border-25 {
  grid-column: 1 / span 5;
  grid-row: 50;
  border-bottom: 1px solid #d9d9d9;
}
.urineAnalysis-border-26 {
  grid-column: 1 / span 5;
  grid-row: 52;
  border-bottom: 1px solid #d9d9d9;
}
.urineAnalysis-border-27 {
  grid-column: 1 / span 5;
  grid-row: 54;
  border-bottom: 1px solid #d9d9d9;
}
.urineAnalysis-border-28 {
  grid-column: 1 / span 5;
  grid-row: 56;
  border-bottom: 1px solid #d9d9d9;
}
.urineAnalysis-border-29 {
  grid-column: 1 / span 5;
  grid-row: 58;
  border-bottom: 1px solid #d9d9d9;
}
.urineAnalysis-border-30 {
  grid-column: 1 / span 5;
  grid-row: 60;
  border-bottom: 1px solid #d9d9d9;
}
.urineAnalysis-border-31{
  grid-column: 1 / span 5;
  grid-row: 62;
  border-bottom: 1px solid #d9d9d9;
}
.urineAnalysis-input {
  width: 120px;
  border-radius: 5px;
  margin-top: 1px;
}
.urineAnalysis-pad {
  padding-top: 7px;
}
.urineAnalysis-foot {
  margin-top: 20px;
  padding-left: 10px;
}
.urineAnalysis-foot .add-image-input {
  background-color: white;
}
.urineAnalysis-p {
  padding: 15px 10px;
  font-size: 12px;
  line-height: 015px;
  font-weight: 400;
}
.urineAnalysis-p h4 {
  font-weight: 800;
  padding-bottom: 5px;
}
