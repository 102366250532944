.troponin-parent {
  display: flex;
}
.troponin-left {
  width: 77%;
  margin-top: 20px;
  margin-left: 5px;
}
.troponin-grid {
  display: grid;
  background-color: white;
  padding: 5px 10px 0 10px;
  font-size: 12px;
  border-bottom: 1px solid #d9d9d9;
}
.troponin-1 {
  grid-column: 1;
  grid-row: 1;
}
.troponin-2 {
  grid-column: 2;
  grid-row: 1;
}

.troponin-3 {
  grid-column: 3;
  grid-row: 1;
}
.troponin-4 {
  grid-column: 4;
  grid-row: 1;
}
.troponin-5 {
  grid-column: 5;
  grid-row: 1;
}

.troponin-input {
  width: 120px;
  border-radius: 5px;
}
.troponin-pad {
  padding-top: 7px;
}
.troponin-foot {
  margin-top: 20px;
  padding-left: 10px;
}
.troponin-foot .add-image-input {
  background-color: white;
}

.troponin-table {
  width: 40%;
}

.troponin-table td {
  border: 1px solid black;
  padding: 6px;
}

.troponin-p {
  padding: 15px 10px;
  font-size: 12px;
  line-height: 015px;
  font-weight: 700;
}
.troponin-p h4 {
  font-weight: 800;
  padding-bottom: 5px;
}
.troponin-p span {
  font-weight: 500;
}
.troponin-msr {
  padding: 5px 10px;
  font-size: 13px;
  font-weight: 500;
}
