.dna-main {
  display: flex;
}
.dna-container {
  width: 80%;
}
.dna-grid-container {
  display: grid;
  background-color: #ffff;
  text-align: left;
  padding: 24px 10px 0 16px;
  font-size: 12px;
}
.dna-grid-container textarea {
  height: 32px;
  border-radius: 4px;
}
.padd {
  padding-top: 8px;
}
.dna-1 {
  grid-column: 1;
  grid-row: 1;
}
.dna-2 {
  grid-column: 4 / span 3;
  grid-row: 1;
}
.dna-border1 {
  grid-column: 1 / span 6;
  grid-row: 2;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.dna-3 {
  grid-column: 1;
  grid-row: 3;
}
.dna-4 {
  grid-column: 4 / span 3;
  grid-row: 3;
}
.dna-border2 {
  grid-column: 1 / span 6;
  grid-row: 4;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.dna-5 {
  grid-column: 1;
  grid-row: 5;
}
.dna-6 {
  grid-column: 4 / span 3;
  grid-row: 5;
}
.dna-border3 {
  grid-column: 1 / span 6;
  grid-row: 6;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.dna-7 {
  grid-column: 1;
  grid-row: 7;
}
.dna-8 {
  grid-column: 4 / span 3;
  grid-row: 7;
}
.dna-border4 {
  grid-column: 1 / span 6;
  grid-row: 8;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.dna-9 {
  grid-column: 1;
  grid-row: 9;
}
.dna-10 {
  grid-column: 4 / span 3;
  grid-row: 9;
}
.dna-border5 {
  grid-column: 1 / span 6;
  grid-row: 10;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.dna-11 {
  grid-column: 1;
  grid-row: 11;
}
.dna-12 {
  grid-column: 4 / span 3;
  grid-row: 11;
}
.dna-border6 {
  grid-column: 1 / span 6;
  grid-row: 12;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.dna-13 {
  grid-column: 1;
  grid-row: 13;
}
.dna-14 {
  grid-column: 4 / span 3;
  grid-row: 13;
}
.dna-border7 {
  grid-column: 1 / span 6;
  grid-row: 14;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.dna-15 {
  grid-column: 1;
  grid-row: 15;
}
.dna-16 {
  grid-column: 4 / span 3;
  grid-row: 15;
}
.dna-border8 {
  grid-column: 1 / span 6;
  grid-row: 16;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.dna-17 {
  grid-column: 1;
  grid-row: 17;
}
.dna-18 {
  grid-column: 4 / span 3;
  grid-row: 17;
}
.dna-border9 {
  grid-column: 1 / span 6;
  grid-row: 18;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.dna-19 {
  grid-column: 1;
  grid-row: 19;
}
.dna-20 {
  grid-column: 4 / span 3;
  grid-row: 19;
}
.dna-border10 {
  grid-column: 1 / span 6;
  grid-row: 20;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
