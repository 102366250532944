.OPD-Records {
    justify-content: flex-start;
}

.record {
    background-color: #f0f8fa;
    text-align: center;
    font-size: 20px;
    padding: 3px;
    padding-top: 17px;
    border-right: 2px solid black;
}

.record h4 {
    font-weight: 900;
    font-size: 22px;
}

.record span {
    font-weight: 600;
}

.all-patients-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    min-height: 52px;
}

.patient-search-icon {
    font-size: 16px;
    stroke: white;
    stroke-width: 40;
    color: white;
    cursor: pointer;
}

.patient-search-input-container {
    display: flex;
    padding: 10px 15px;
    justify-content: space-between;
    align-items: center;
}

.patient-search-input {
    width: 90%;
}

.patients-list {
    color: white;
    margin-bottom: 0 !important;
    font-size: 15px;
    text-align: center;
}

.patient-names {
    background-color: white;
}

.patient-names p {
    padding: 6px 14px;
    margin-bottom: 0 !important;
    /* border-bottom: 0.5px solid gray;  */
    font-size: 12px;
    font-weight: 600;
}

.patient-names p:last-child {
    border-bottom: none;
}

.opd-patient-list-active {
    background: #90EE90;
    border-bottom: 1px solid black;
    cursor: pointer;
    padding: 5px;
    display: flex;
    justify-content: space-around;
}

.opd-patient-list-sec {
    height: 300px;
    overflow-y: scroll;

}
.opd-patient-list-sec::-webkit-scrollbar {
    width: 5px;
}

.opd-patient-list-sec::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.opd-patient-list-sec::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;

}

.opd-patient-list-sec::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.opd-patient-list-inactive {
    background: #F75D59;
    border-bottom: 1px solid black;
    cursor: pointer;
    padding: 5px;
    display: flex;
    justify-content: space-around;
 }
 .opd-patient-list-sec::-webkit-scrollbar {
    display: none;
}