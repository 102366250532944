* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.admin-login-container{
    display: flex;
    align-items: center;
    justify-content: center;
}
.login-box {
    width: 30%;
    border: 1px solid #ddd;
    margin-top: 100px;
    padding: 32px;
    background-color: white;
}

.admin-logo {
    width: 40%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;

}

.login-button button {
    width: 100%;
    border-radius: 0px;
    background-color: #00809C;
    height: 50px;
    color: white;
    font-size: 16px;
    font-weight: 500;
}

.login-inputfield {
    margin-bottom: 17px;
}

.login-inputfield .ant-input:hover {
    border-color: #ddd;
}

.login-forgot-password {
    color: #00809C;
    font-size: 16px;
    margin-top: 15px;
}