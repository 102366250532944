.update-risks {
    background-color: #007d9d;
}

.update-risks h6 {
    padding: 5px 15px;
    color: white;
    font-size: 15px;
    text-align: center;
}

.risk-form-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 15px;
    margin-bottom: 20px;
}

.active-visit-date-btns {
    padding: 6px 12px;
}

.triangle {
    position: relative;

}

.tri {
    position: absolute;
    background-color: white;
    border-top: 15px solid red;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    margin-left: 8px;
}

.risk-btn {
    background-color: red;
    padding: 6px 16px;

}

.forms-btn {
    background-color: lightgray;
    padding: 6px 16px;
}

.active-list-btn {
    width: 100%;
    margin-bottom: 5px;
    color: black;
    text-align: left;
    padding: 6px 12px;

}

.date-btn {
    width: 100%;
    color: black;
    text-align: left;
    padding: 6px 12px;
    background-color: gray;


}

.prev-visit-date-btns {
    height: 300px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.prev-visit-date-btns::-webkit-scrollbar {
    display: none;

}

.update-risk-modal-sec {
    display: flex;
    width: 100%;
}

.risk-factor-sec {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.risk-factors-left-sec {
    width: 72%;
}

.risk-update-history-sec {
    width: 28%;
    padding-left: 20px;
    overflow-y: scroll;
    -ms-overflow-style: none;
}
.risk-update-history-sec::-webkit-scrollbar {
    display: none;
}

.risk-factor-name-sec {
    width: 27%;
    cursor: pointer;
    background: #A6A9AD;
    color: #ffffff;
    border-radius: 7px;
    margin: 5px 0px;
}

.risk-factor-name-sec p {
    padding: 4px 0px 4px 15px;
    font-size: 12px;
}

.update-risk-add-sec {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.opd-form-certificate-sec {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
}

.opd-form-certificate-sec div {
    margin-right: 20px;
}

.opd-form-select-sec {
    width: 60%;

}

.opd-form-left-sec {
    display: flex;
    width: 100%;
}

.opd-form-download {
    background: #A6A9AD;
    color: white;
    border-radius: 50%;
    padding: 7px;
    cursor: not-allowed;
}

.opd-form-save {
    background: #5E6A72;
    color: white;
    border-radius: 50%;
    padding: 7px;
    cursor: pointer;
}

.opd-form-print {
    background: #EBB563;
    color: white;
    border-radius: 50%;
    padding: 7px;
    cursor: not-allowed;
}
.opd-form-delete-div img:hover {
    cursor: not-allowed;
}

.opd-form-delete {
    background-color: #F78989;
    padding: 7px;
    border-radius: 50%;
    cursor: pointer;

}

.certificate-data {
    margin-top: 50px;
    padding: 20px;
    font-size: 15px;
    font-family: 'Poppins';
}
.certificate-data span{
    font-size: 15px;
    font-family: 'Poppins';
}

.opd-formname-sec {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
}

.opd-formname-sec p {
    background: #A6A9AD;
    color: #ffffff;
    padding: 5px 7px;
    font-size: 12px;
    margin-right: 15px;
    cursor: pointer;
    margin-top: 10px;
    border-radius: 5px;
}

.opd-form-speech-sec {
    display: flex;
    margin-bottom: 35px;
    align-items: center;
}

.opd-form-speech-sec div {
    margin-right: 15px;
}

.opd-form-speech-select {
    width: 25%;
}

.opd-form-text-sec {
    width: 67%;
}

.opd-form-speech-cross p {
    padding: 2px 8px;
    background-color: #F78989;
    border-radius: 50%;
    font-size: 14px;
    color: #ffffff;
    cursor: pointer;
}

.opd-form-speech-icon {
    font-size: 22px;
    cursor: pointer;
}
.opd-form-speech-cross-prev p {
    padding: 2px 8px;
    background-color: #F78989;
    border-radius: 50%;
    font-size: 14px;
    color: #ffffff;
    cursor: not-allowed;
}


@media screen and (min-width:1880px){
    .active-list-btn{
        color: #000;
        padding: 6px 12px;
        text-align: left;    
        padding-left: 3;
    }


    .date-btn {
        color: black;
        text-align: left;
        padding: 6px 12px;
        background-color: gray;
    }
}




@media screen and (min-width:2560px){

    .active-visit-date-btns {
        padding: 6px 20px;
    }

    .active-list-btn {
        color: #000;
        padding: 6px 12px;
        text-align: left;
    }

    .date-btn {
        color: black;
        text-align: left;
        padding: 6px 12px;
        background-color: gray;
    
    
    }

}




@media screen and (min-width:1440px){

    .active-list-btn {
        margin-bottom: 5px;
        color: black;
        text-align: left;
        padding: 6px 12px;
    
    }
    
    .date-btn {
        color: black;
        text-align: left;
        padding: 6px 12px;
        background-color: gray;
    
    
    }
}





@media screen and (min-width:1336px){

    .active-list-btn {
        margin-bottom: 5px;
        color: black;
        text-align: left;
        padding: 6px 12px;
    
    }
    
    .date-btn {
        color: black;
        text-align: left;
        padding: 6px 12px;
        background-color: gray;
    
    
    }
}

