.appointments-heading {
    
    border-bottom: 1px solid lightgray;
    border-right: 1px solid lightgray;
    padding:20px 0 0 25px
}

.appointments-heading h5 {
    font-size: 22px;
    font-weight: 700;
}

.day-week-month-search-main-div {
    display: flex;
    padding: 10px 15px;
    justify-content: space-between;
}

.day-week-month-div {
    display: flex;
}



.date-from-calendar {
    display: flex;
    justify-content: space-between;
    background-color: #F0F8FA;
    padding: 12px 15px;
    margin: 0;
}

.date-from-calendar h4 {
    margin-bottom: 0px;
} 


.patient-time-name-type {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid lightgray;    
}

.time-patient-name-div {
    display: flex;
}

.time-patient-name-div h5 {
    font-size: 13px;
    font-weight: bold;
    padding-right: 15px;
}

.patient-name-age-phone-div p {
    font-weight: bold;
    font-size: 13px;
}

.patient-name-age-phone-div span {
    padding: 0 10px;
}

.patient-age {
    line-height: 10px;
}
.ant-input-group-wrapper.ant-input-search.search-no-do{
    border:none;
    
}
.week-btn {
    background-color: lightgray;
}

.vacant-btn {
    background-color: red;
}
.appoint-inp{
    display: flex;
}
.appoint-modal-inp1{
    width: 200px;
    margin-left: 20px;
}
.appoint-textarea{
    padding: 5px;
    color: black;
    border: 1px solid lightgray;
}
.clock{
    color: lightgray;
}
.appointment-modal-container .ant-modal-title {
    color: gray;
    font-weight: 300;
    font-size: 20px;
    word-wrap: break-word;
    margin-top: 20px;
    padding-bottom: 0px;
}
.appointment-modal-container .ant-modal-close-x{
    font-size: 22px;
    display: inline-block;
    color: gray;
    margin-right: 10px;
}
.appointment-modal-container p{
 color: gray;
 font-weight: 100px;
}
.appointment-date{
    width: 208px;
}
.appointment-date .ant-picker-input{
width: 100%;
}
.search-divsion{
    display: flex;
    border-bottom: 1px solid lightgray;
    padding-bottom: 20px;
}
.icon-search{
    margin: 35px 126px 0 0;   
    background-color: green;
    color: white;
    padding: 2px 6px;
}


.ant-space.ant-space-vertical {
    width: 100%;
    margin-left: 10px;
}
.horizontal-line{
    margin-top: 20px;
}

.apt-date .ant-picker{
    width: 90%;

}
.from-date-datepicker{
    margin-left: 9px;
}
.appointment-modal-container .ant-modal-close-x {
    font-size: 18px;
}
/* @media screen and (min-width:1880px){
    .appoint-textarea{
        width: 100%;
    }
} */