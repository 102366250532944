* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.statistics-container {
    width: 90%;
    margin: 0px 20px 1% 6%;
    font-weight: 400;

}
.doctor-statistics-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.doctor-statistics-last-cron {
    display: flex;
    align-items: center;
    gap: 10px;
}
.dia-table-scroll{
    /* overflow-y: scroll; */
    /* height: 400px; */
}

.statistics-container .ant-table-tbody>tr>td:nth-of-type(3) {
    text-align: left;
    width: 19%;
}

.statistics-container .ant-table-tbody>tr>td:nth-of-type(4) {
    text-align: left;
    width: 20%;
}
.statistics-container .ant-table-tbody>tr>td:nth-of-type(2){
    padding: 0px;
    width: 30%;
}
.statistics-container .ant-table-tbody>tr>td:nth-of-type(1){
    width: 25%;
}

.statistics-container .ant-table-tbody>tr>td {
    padding: 15px 10px;
}

.statistics-container h2 {
    font-weight: bold;
    margin-bottom: 0.1em;

}
.statistics-calender{
    display: flex;
    gap: 20px;
    margin-bottom: 12px;
}

.statistics-calenders{
    width: 100%;
}
.statistics-calenders p{
    color: #B5B5B5;
    margin-left: 10px;
}

.fromdate-date-heading{
    display: flex;
    gap: 41%;
}
.ant-space-vertical{
    gap: 0;
    width: 100%;
}
.statistics-calender .ant-picker {
    width:  25vw;
    margin-left: 0px;
    width: 100%;
}
