* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}
.audio-container {
  width: 100%;
  height: 100%;
  padding: 30px;
}
.audio-section1 {
  width: 71%;
  background-color: white;
  padding: 15px 20px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  /* margin: auto; */
}
.audio-inp1 {
  width: 400px;
  height: 30px;
  border: 1px solid #ccc;
  display: inline;
}
.audio-title {
  color: #ccc;
}
.audio-inp2 {
  width: 200px;
  height: 30px;
  border: 1px solid #ccc;
}
.audio-icon {
  width: 34px;
  height: 34px;
  margin-left: 10px;
  margin-top: 20px;
}
.audio-subsection {
  width: 60%;
  margin-right: 10px;
}
.audio-section2 {
  width: 71%;
  background-color: white;
  padding: 10px 30px 8px 20px;
  line-height: 0.7;
  
}
.audio-section {
  display: flex;
  justify-content: center;
}
.audio-player {
  width: 600px;
  height: 50px;
  margin-top: 7px;
}
.delete-icon {
  width: 30px;
  height: 30px;
  position: relative;
  top: 16px;
  left: 18px;
}
.audio-header {
  font-weight: 800;
}
.audio-container h4 {
  font-weight: 800;
  font-size: 15px;
}
.audio-rec{
  
    padding: 10px;
}
.audio-bg{
  padding: 10px;
}
.patient-audio-name{
  width: 30%;
  margin-left: 10px;
}
.p-name-audio-date{
  display: flex;
  padding: 10px;
}
.patient-audio-name-date{

    padding-top: 23px;
    padding-left: 10px;

}
.patient-audio-name-cancel{
  width: 47%;
}
.cross-button{
  background: red;
}
.p-name-audio-date{
  background:white;
}
.time-min-sec{
  padding-top: 10px;
}
.audio-ui{
  padding-top: 0px;
}

.time-sec{
  padding-top: 5px;
  width: 10%;
  margin-right: 10px;
  padding-left: 10px;
}
.icon-faCloudArrowUp{
  color:black;
}
.all-icons{
  padding-top: 10px;
}
.cancel-audio{
  background:none;
  color: red;
  margin-left: 10px;
}
.audio-listing{
  background: #ffffff;
  padding: 10px;

}
.audio-listing p{
  padding:0px 0px 7px 0px ;
}