.dnu-why-main-container {
  display: flex;
}
.dnu-why-main-left-sec {
  width: 80%;
}
.dnu-why-container {
  display: grid;
  background-color: #ffff;
  text-align: left;
  padding: 10px 0px 0 10px;
  font-size: 12px;
}
.dnu-why-container textarea {
  height: 32px;
  border-radius: 4px;
}
.dnu-why-footer .add-image-input {
  background-color: #ffff;
}
.test-report-input textarea {
  background-color: #ffff;
}
.dnu-why-footer {
  margin-left: 10px;
}

.dnu-why-1 {
  grid-column: 1;
  grid-row: 1;
}
.dnu-why-2 {
  grid-column: 4 / span 4;
  grid-row: 1;
}
.dnu-why-border1 {
  grid-column: 1 / span 7;
  grid-row: 2;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.dnu-why-3 {
  grid-column: 1;
  grid-row: 3;
}
.dnu-why-4 {
  grid-column: 4 / span 4;
  grid-row: 3;
}
.dnu-why-border2 {
  grid-column: 1 / span 7;
  grid-row: 4;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.dnu-why-5 {
  grid-column: 1;
  grid-row: 5;
}
.dnu-why-6 {
  grid-column: 4 / span 4;
  grid-row: 5;
}
.dnu-why-border3 {
  grid-column: 1 / span 7;
  grid-row: 6;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.dnu-why-7 {
  grid-column: 1;
  grid-row: 7;
}
.dnu-why-8 {
  grid-column: 4 / span 4;
  grid-row: 7;
}
.dnu-why-border4 {
  grid-column: 1 / span 7;
  grid-row: 8;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.dnu-why-9 {
  grid-column: 1;
  grid-row: 9;
}
.dnu-why-10 {
  grid-column: 4 / span 4;
  grid-row: 9;
}
.dnu-why-border5 {
  grid-column: 1 / span 7;
  grid-row: 10;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.dnu-why-11 {
  grid-column: 1 / span 2;
  grid-row: 11;
}
.dnu-why-12 {
  grid-column: 4 / span 4;
  grid-row: 11;
}
.dnu-why-border6 {
  grid-column: 1 / span 7;
  grid-row: 12;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.dnu-why-13 {
  grid-column: 1;
  grid-row: 13;
}
.dnu-why-14 {
  grid-column: 4 / span 4;
  grid-row: 13;
}
.dnu-why-border7 {
  grid-column: 1 / span 7;
  grid-row: 14;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.dnu-why-15 {
  grid-column: 1;
  grid-row: 15;
}
.dnu-why-16 {
  grid-column: 4 / span 4;
  grid-row: 15;
}
.dnu-why-border8 {
  grid-column: 1 / span 7;
  grid-row: 16;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.dnu-why-17 {
  grid-column: 1;
  grid-row: 17;
}
.dnu-why-18 {
  grid-column: 4 / span 4;
  grid-row: 17;
}
.dnu-why-border9 {
  grid-column: 1 / span 7;
  grid-row: 18;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.dnu-why-19 {
  grid-column: 1;
  grid-row: 19;
}
.dnu-why-20 {
  grid-column: 4 / span 4;
  grid-row: 19;
}
.dnu-why-border10 {
  grid-column: 1 / span 7;
  grid-row: 20;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.dnu-why-21 {
  grid-column: 1 / span 2;
  grid-row: 21;
}
.dnu-why-22 {
  grid-column: 4 / span 4;
  grid-row: 21;
}
.dnu-why-border11 {
  grid-column: 1 / span 7;
  grid-row: 22;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.dnu-why-23 {
  grid-column: 1;
  grid-row: 23;
}
.dnu-why-24 {
  grid-column: 4 / span 4;
  grid-row: 23;
}
.dnu-why-border12 {
  grid-column: 1 / span 7;
  grid-row: 24;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.dnu-why-25 {
  grid-column: 1;
  grid-row: 25;
}
.dnu-why-26 {
  grid-column: 4 / span 4;
  grid-row: 25;
}
.dnu-why-border13 {
  grid-column: 1 / span 7;
  grid-row: 26;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.dnu-why-27 {
  grid-column: 1;
  grid-row: 27;
}
.dnu-why-28 {
  grid-column: 4 / span 4;
  grid-row: 27;
}
.dnu-why-border14 {
  grid-column: 1 / span 7;
  grid-row: 28;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.dnu-why-29 {
  grid-column: 1 / span 2;
  grid-row: 29;
}
.dnu-why-30 {
  grid-column: 4 / span 4;
  grid-row: 29;
}
.dnu-why-border15 {
  grid-column: 1 / span 7;
  grid-row: 30;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
/* -------------------------------------- */

.dnu-why-31 {
  grid-column: 3 / span 2;
  grid-row: 31;
}
.dnu-why-32 {
  grid-column: 6 / span 2;
  grid-row: 31;
}
.dnu-why-border16 {
  grid-column: 1 / span 7;
  grid-row: 32;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.dnu-why-33 {
  grid-column: 1 / span 2;
  grid-row: 33;
}
.dnu-why-34 {
  grid-column: 3 / span 2;
  grid-row: 33;
}
.dnu-why-35 {
  grid-column: 6 / span 2;
  grid-row: 33;
}
.dnu-why-border17 {
  grid-column: 1 / span 7;
  grid-row: 34;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}

.dnu-why-36 {
  grid-column: 1 / span 2;
  grid-row: 35;
}
.dnu-why-37 {
  grid-column: 3 / span 2;
  grid-row: 35;
}
.dnu-why-38 {
  grid-column: 6 / span 2;
  grid-row: 35;
}
.dnu-why-border18 {
  grid-column: 1 / span 7;
  grid-row: 36;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.dnu-why-39 {
  grid-column: 1 / span 2;
  grid-row: 37;
}
.dnu-why-40 {
  grid-column: 3 / span 2;
  grid-row: 37;
}

.dnu-why-41 {
  grid-column: 6 / span 2;
  grid-row: 37;
}
.dnu-why-border19 {
  grid-column: 1 / span 7;
  grid-row: 38;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.dnu-why-42 {
  grid-column: 1 / span 2;
  grid-row: 39;
}
.dnu-why-43 {
  grid-column: 3 / span 2;
  grid-row: 39;
}
.dnu-why-44 {
  grid-column: 6 / span 2;
  grid-row: 39;
}
.dnu-why-border20 {
  grid-column: 1 / span 7;
  grid-row: 40;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}

.dnu-why-45 {
  grid-column: 1 / span 2;
  grid-row: 41;
}
.dnu-why-46 {
  grid-column: 3 / span 2;
  grid-row: 41;
}
.dnu-why-47 {
  grid-column: 6 / span 2;
  grid-row: 41;
}
.dnu-why-border21 {
  grid-column: 1 / span 7;
  grid-row: 42;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}

.dnu-why-48 {
  grid-column: 1 / span 2;
  grid-row: 43;
}
.dnu-why-49 {
  grid-column: 3 / span 2;
  grid-row: 43;
  /* width: 50px; */
}

.dnu-why-50 {
  grid-column: 6 / span 2;
  grid-row: 43;
}

.dnu-why-border22 {
  grid-column: 1 / span 7;
  grid-row: 44;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.dnu-why-51 {
  grid-column: 1 / span 2;
  grid-row: 45;
}
.dnu-why-52 {
  grid-column: 3 / span 2;
  grid-row: 45;
}

.dnu-why-53 {
  grid-column: 6 / span 2;
  grid-row: 45;
}
.dnu-why-border23 {
  grid-column: 1 / span 7;
  grid-row: 46;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}

.dnu-why-54 {
  grid-column: 1 / span 2;
  grid-row: 47;
}
.dnu-why-55 {
  grid-column: 3 / span 2;
  grid-row: 47;
}

.dnu-why-56 {
  grid-column: 6 / span 2;
  grid-row: 47;
}
.dnu-why-border24 {
  grid-column: 1 / span 7;
  grid-row: 48;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}

.dnu-why-57 {
  grid-column: 1 / span 2;
  grid-row: 49;
}
.dnu-why-58 {
  grid-column: 3 / span 2;
  grid-row: 49;
}

.dnu-why-59 {
  grid-column: 6 / span 2;
  grid-row: 49;
}
.dnu-why-border25 {
  grid-column: 1 / span 7;
  grid-row: 50;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}

/* ------------------- */
.dnu-why-5-input {
  height: 29px;
  border-radius: 4px;
}

/* ----------------------- */

.dnu-why-60 {
  grid-column: 1 / span 2;
  grid-row: 51;
}
.dnu-why-61 {
  grid-column: 3 / span 2;
  grid-row: 51;
}

.dnu-why-62 {
  grid-column: 6 / span 2;
  grid-row: 51;
}

.dnu-why-border26 {
  grid-column: 1 / span 7;
  grid-row: 52;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}

.dnu-why-63 {
  grid-column: 1 / span 2;
  grid-row: 53;
}
.dnu-why-64 {
  grid-column: 3 / span 2;
  grid-row: 53;
}
.dnu-why-65 {
  grid-column: 6 / span 2;
  grid-row: 53;
}

.dnu-why-border27 {
  grid-column: 1 / span 7;
  grid-row: 54;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}

/* ----------------------- */

.dnu-why-66 {
  grid-column: 1 / span 1;
  grid-row: 55;
}
.dnu-why-67 {
  grid-column: 2 / span 1;
  grid-row: 55;
}
.dnu-why-68 {
  grid-column: 3 / span 1;
  grid-row: 55;
}
.dnu-why-69 {
  grid-column: 4 / span 1;
  grid-row: 55;
}
.dnu-why-70 {
  grid-column: 5 / span 1;
  grid-row: 55;
}
.dnu-why-71 {
  grid-column: 6 / span 1;
  grid-row: 55;
}
.dnu-why-72 {
  grid-column: 7 / span 1;
  grid-row: 55;
}
.dnu-why-border28 {
  grid-column: 1 / span 7;
  grid-row: 56;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}

.dnu-why-73 {
  grid-column: 1 / span 1;
  grid-row: 57;
}
.dnu-why-74 {
  grid-column: 2 / span 1;
  grid-row: 57;
}
.dnu-why-75 {
  grid-column: 3 / span 1;
  grid-row: 57;
}
.dnu-why-76 {
  grid-column: 4 / span 1;
  grid-row: 57;
}
.dnu-why-77 {
  grid-column: 5 / span 1;
  grid-row: 57;
}
.dnu-why-78 {
  grid-column: 6 / span 1;
  grid-row: 57;
}
.dnu-why-79 {
  grid-column: 7 / span 1;
  grid-row: 57;
}
.dnu-why-border29 {
  grid-column: 1 / span 7;
  grid-row: 58;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}

.dnu-why-80 {
  grid-column: 1 / span 1;
  grid-row: 59;
}
.dnu-why-81 {
  grid-column: 2 / span 1;
  grid-row: 59;
}
.dnu-why-82 {
  grid-column: 3 / span 1;
  grid-row: 59;
}
.dnu-why-83 {
  grid-column: 4 / span 1;
  grid-row: 59;
}
.dnu-why-84 {
  grid-column: 5 / span 1;
  grid-row: 59;
}
.dnu-why-85 {
  grid-column: 6 / span 1;
  grid-row: 59;
}
.dnu-why-86 {
  grid-column: 7 / span 1;
  grid-row: 59;
}
.dnu-why-border30 {
  grid-column: 1 / span 7;
  grid-row: 60;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}

.dnu-why-87 {
  grid-column: 1 / span 1;
  grid-row: 61;
}
.dnu-why-88 {
  grid-column: 2 / span 1;
  grid-row: 61;
}
.dnu-why-89 {
  grid-column: 3 / span 1;
  grid-row: 61;
}
.dnu-why-90 {
  grid-column: 4 / span 1;
  grid-row: 61;
}
.dnu-why-91 {
  grid-column: 5 / span 1;
  grid-row: 61;
}
.dnu-why-92 {
  grid-column: 6 / span 1;
  grid-row: 61;
}
.dnu-why-93 {
  grid-column: 7 / span 1;
  grid-row: 61;
}
.dnu-why-border31 {
  grid-column: 1 / span 7;
  grid-row: 62;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}

.dnu-why-94 {
  grid-column: 1 / span 1;
  grid-row: 63;
}
.dnu-why-95 {
  grid-column: 2 / span 1;
  grid-row: 63;
}
.dnu-why-96 {
  grid-column: 3 / span 1;
  grid-row: 63;
}
.dnu-why-97 {
  grid-column: 4 / span 1;
  grid-row: 63;
}
.dnu-why-98 {
  grid-column: 5 / span 1;
  grid-row: 63;
}
.dnu-why-99 {
  grid-column: 6 / span 1;
  grid-row: 63;
}
.dnu-why-100 {
  grid-column: 7 / span 1;
  grid-row: 63;
}
.dnu-why-border32 {
  grid-column: 1 / span 7;
  grid-row: 64;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}

.dnu-why-101 {
  grid-column: 1 / span 1;
  grid-row: 65;
}
.dnu-why-102 {
  grid-column: 2 / span 1;
  grid-row: 65;
}
.dnu-why-103 {
  grid-column: 3 / span 1;
  grid-row: 65;
}
.dnu-why-104 {
  grid-column: 4 / span 1;
  grid-row: 65;
}
.dnu-why-105 {
  grid-column: 5 / span 1;
  grid-row: 65;
}
.dnu-why-106 {
  grid-column: 6 / span 1;
  grid-row: 65;
}
.dnu-why-107 {
  grid-column: 7 / span 1;
  grid-row: 65;
}
.dnu-why-border33 {
  grid-column: 1 / span 7;
  grid-row: 66;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}

.dnu-why-108 {
  grid-column: 1 / span 1;
  grid-row: 67;
}
.dnu-why-109 {
  grid-column: 2 / span 1;
  grid-row: 67;
}
.dnu-why-110 {
  grid-column: 3 / span 1;
  grid-row: 67;
}
.dnu-why-111 {
  grid-column: 4 / span 1;
  grid-row: 67;
}
.dnu-why-112 {
  grid-column: 5 / span 1;
  grid-row: 67;
}
.dnu-why-113 {
  grid-column: 6 / span 1;
  grid-row: 67;
}
.dnu-why-114 {
  grid-column: 7 / span 1;
  grid-row: 67;
}
.dnu-why-border34 {
  grid-column: 1 / span 7;
  grid-row: 68;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}

.dnu-why-115 {
  grid-column: 1 / span 1;
  grid-row: 69;
}
.dnu-why-116 {
  grid-column: 2 / span 1;
  grid-row: 69;
}
.dnu-why-117 {
  grid-column: 3 / span 1;
  grid-row: 69;
}
.dnu-why-118 {
  grid-column: 4 / span 1;
  grid-row: 69;
}
.dnu-why-119 {
  grid-column: 5 / span 1;
  grid-row: 69;
}
.dnu-why-120 {
  grid-column: 6 / span 1;
  grid-row: 69;
}
.dnu-why-121 {
  grid-column: 7 / span 1;
  grid-row: 69;
}
.dnu-why-border35 {
  grid-column: 1 / span 7;
  grid-row: 70;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
