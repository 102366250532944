.crp-main {
  display: flex;
}
.crp-container {
  width: 80%;
}
.crp-grid-container {
  display: grid;
  column-gap: 23px;
  background-color: #ffff;
  text-align: left;
  padding: 24px 10px 0 16px;
  font-size: 12px;
}
.crp-footer {
  margin-left: 15px;
}
.crp-footer p {
  font-size: 12px;
}
.crp-1 {
  grid-column: 1 / span 1;
  width: 96px;
}
.crp-2 {
  grid-column: 2;
}
.crp-7 {
  grid-column: 3;
}
.crp-4 {
  grid-column: 4;
}
.crp-5 {
  grid-column: 7;
}
/* .crp-6{
    grid-column: 6 ;
} */
