.rft-parent {
  display: flex;
}
.rft-left {
  width: 80%;
  margin-left: 5px;
  margin-top: 20px;
}
.rft-grid {
  display: grid;
  background-color: white;
  padding: 5px 10px 0 10px;
  font-size: 12px;
}
.rft1 {
  grid-column: 1;
  grid-row: 1;
  width: 150px;
}
.rft2 {
  grid-column: 2;
  grid-row: 1;
}
.rft3 {
  grid-column: 3;
  grid-row: 1;
}
.rft4 {
  grid-column: 4;
  grid-row: 1;
}
.rft5 {
  grid-column: 5;
  grid-row: 1;
}
.rft6 {
  grid-column: 6;
  grid-row: 1;
}
.rft-input {
  width: 120px;
  border-radius: 5px;
  margin-top: 2px;
}
.rft-border {
  grid-column: 1 / span 6;
  grid-row: 2;
  border-bottom: 1px solid #d9d9d9;
}
.rft-border2 {
  grid-column: 1 / span 6;
  grid-row: 4;
  border-bottom: 1px solid #d9d9d9;
}
.rft-border3 {
  grid-column: 1 / span 6;
  grid-row: 6;
  border-bottom: 1px solid #d9d9d9;
}
.rft-border4 {
  grid-column: 1 / span 6;
  grid-row: 8;
  border-bottom: 1px solid #d9d9d9;
}
.rft-border5 {
  grid-column: 1 / span 6;
  grid-row: 10;
  border-bottom: 1px solid #d9d9d9;
}
.h-pad {
  padding: 6px 5px;
}
.rft-foot {
  margin-top: 20px;
  padding-left: 5px;
}
.rft-foot .add-image-input {
  background-color: white;
}
