.medicine-top-sec {
    margin: 0 3% 0px 3%;
    background-color: white;
    padding-top: 20px;
    padding-bottom: 10px;
}

.medicine-top-sec p {
    padding-left: 56px;
    color: #000000;
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 10px;
}

.medicine-table-sec {
    margin: 0 3% 10px 3%;
    background: #ffffff;
    padding-bottom: 20px;
    max-height: 400px;
}

.medicine-table-sec .ant-table-thead>tr>th {
    padding: 10px 20px;
}

.medicine-table-sec .ant-table-tbody>tr>td {
    padding: 5px 20px;
    text-align: left;
    white-space: nowrap;
    width:50%;
}

.medicine-table-icons {
    text-align: right !important;
    opacity: 0;
}

.medicine-table-sec tr:hover .medicine-table-icons {
    opacity: 1;
}

.medicine-table-delete {
    padding: 5px;
    height: 22px;
    width: 22px;
    background: #EF4036;
    border-radius: 50%;
    cursor: pointer;
    margin-left: 5px;
}
.medicine-table-edit {
    padding: 5px;
    height: 22px;
    width: 22px;
    background: #0000FF;
    border-radius: 50%;
    cursor: pointer;
    margin-left: 5px;
}

.medicine-table-edit {
    padding: 5px;
    height: 22px;
    width: 22px;
    background: #00AEEF;
    border-radius: 50%;
    margin-left: 7px;
    cursor: pointer;
}

.edit-update-button {
    background-color: rgb(201, 192, 192);
    height: 30px;
    min-width: 100px;
}

.edit-update-button img {
    margin-right: 8px;
    margin-top: -3px;
    width: 18px;
}

.add-edit {
    display: flex;
    margin: 0 auto;
    margin-bottom: 5px;
    width: 100%;
   justify-content: center;
}

.add-edit Button {
    margin-top: 5px;
    margin-right: 10px;
}

.red-message {
    font-size: 12px;
    color: #EF4036;
    padding-left: 20px;
    font-weight: 600;

}

.generic-search {
    width: 30vw;
    height: 35px;
    margin-top: 5px;
    margin-right: 10px;
}

.medicine-input-cls {
    height: 35px;
    margin-top: 5px;
    margin-right: 10px;
    width: 18vw;
}

.medicine-add-b {
    height: 35px;
    width: 63px;
    margin-top: 5px;
}

.edit-medicine-update-btn {
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;
}


@media only screen and (min-width: 992px) and (max-width:1249px) {
    .generic-search {
        width: 24vw;
    }
}

@media screen and (min-width:1880px) {
    

    .medicine-top-sec p {
        padding-left: 106px;
        color: #000000;
        font-size: 18px;
        font-weight: 500;
        padding-bottom: 10px;
    }
}


@media screen and (min-width:2560px) {
    

    .medicine-top-sec p {
        padding-left: 160px;
        color: #000000;
        font-size: 18px;
        font-weight: 500;
        padding-bottom: 10px;
    }
}




    /* body{
        overflow-y:hidden;
        
        } */

       