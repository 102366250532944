.diagnostic-patient-invoice-status {
  display: flex;
  font-size: 12px;
  margin-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-right: 10px;
}
.diagnostic-patient-date-invoice-status {
  display: flex;
  padding-top: 5px;
  color: black;
  font-weight: 600;
  font-size: 13px;
  width: 47%;
}
.diagnostic-patient-sms-copy-save-print-download {
  display: flex;
}
.diagnostics-edit-copy-print-btns {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.diagnostic-profile-inovice-status-section {
  border: 2px solid #d4d7d8;
  margin-top: 5px;
}
.diagnostic-date-year {
  margin-right: 10px;
  width: 25%;
}
.diagnostic-patient-invoice-number-section {
  margin-right: 10px;
  width: 20%;
}
.diagnostic-patient-status {
  margin-left: 10px;
}
.report-pending-title {
  color: #f6ad5b;
}
.diagnostic-download-invoice-icon {
  background: #1e9902;
  border-radius: 50%;
  padding: 6px;
  margin-left: 5px;
}

.diagnostic-profile-printer-icon {
  background: #ef4036;
  border-radius: 50%;
  padding: 7px 6px 0px 5px;
}

.diag-sms-invoice {
  background: #f7941d;
  font-size: 12px;
  font-weight: 400;
}

.diag-copylink-title {
  background: #00aeef;
  font-size: 12px;
  font-weight: 400;
}
.diagnostic-patient-actions-section {
  padding-right: 10px;
}

@media print {
  @page {
    height: 100%;
  }
}
.print-invoice-page{
  margin: 10px;
}
