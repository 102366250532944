.procedures-div {
    display: flex;
    margin: 20px;
    /* margin-bottom: 30px ; */
}

.procedures-11-div {
    width: 23%;
    line-height: 20px;
}

.procedures-11-div h6 {
    font-size: 13px;
    padding: 5px 10px;
    font-weight: 600;
}

.procedures-11-div p {
    padding-left:10px;
    font-weight: 500;
    font-weight: 12px;
    color: #19b3f0;
    line-height: 0px;

}

.procedures-antSelect {
}

.procedures-multiselect {
    width: 42vw;
}