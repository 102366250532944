.csf-main {
  display: flex;
}

.csf-container {
  width: 80%;
}

.csf-grid-container {
  display: grid;
  background-color: #ffff;
  text-align: left;
  padding: 24px 10px 0 16px;
  font-size: 12px;
}

.csf-1 {
  grid-column: 1;
  grid-row: 1;
}

.csf-2 {
  grid-column: 2;
  grid-row: 1;
}

.csf-3 {
  grid-column: 3;
  grid-row: 1;
}

.csf-4 {
  grid-column: 4;
  grid-row: 1;
}

.csf-5 {
  grid-column: 5;
  grid-row: 1;
}

.csf-6 {
  grid-column: 6;
  grid-row: 1;
}

.csf-7 {
  grid-column: 1;
  grid-row: 3;
}

.csf-8 {
  grid-column: 2;
  grid-row: 3;
}

.csf-9 {
  grid-column: 3;
  grid-row: 3;
}

.csf-10 {
  grid-column: 4;
  grid-row: 3;
}
.csf-11 {
  grid-column: 5;
  grid-row: 3;
}
.csf-12 {
  grid-column: 6;
  grid-row: 3;
}
.csf-13 {
  grid-column: 1;
  grid-row: 5;
}
.csf-14 {
  grid-column: 2;
  grid-row: 5;
}
.csf-15 {
  grid-column: 3;
  grid-row: 5;
}
.csf-16 {
  grid-column: 4;
  grid-row: 5;
}
.csf-17 {
  grid-column: 5;
  grid-row: 5;
}
.csf-18 {
  grid-column: 6;
  grid-row: 5;
}
.csf-19 {
  grid-column: 1;
  grid-row: 7;
}
.csf-20 {
  grid-column: 2;
  grid-row: 7;
}
.csf-21 {
  grid-column: 3;
  grid-row: 7;
}
.csf-22 {
  grid-column: 4;
  grid-row: 7;
}
.csf-23 {
  grid-column: 5;
  grid-row: 7;
}
.csf-24 {
  grid-column: 6;
  grid-row: 7;
}
.csf-25 {
  grid-column: 1;
  grid-row: 9;
}
.csf-26 {
  grid-column: 2;
  grid-row: 9;
}
.csf-27 {
  grid-column: 3;
  grid-row: 9;
}
.csf-28 {
  grid-column: 4;
  grid-row: 9;
}
.csf-29 {
  grid-column: 5;
  grid-row: 9;
}
.csf-30 {
  grid-column: 6;
  grid-row: 9;
}
.csf-31 {
  grid-column: 1;
  grid-row: 11;
}
.csf-32 {
  grid-column: 2;
  grid-row: 11;
}
.csf-33 {
  grid-column: 3;
  grid-row: 11;
}
.csf-34 {
  grid-column: 4;
  grid-row: 11;
}
.csf-35 {
  grid-column: 5;
  grid-row: 11;
}
.csf-36 {
  grid-column: 6;
  grid-row: 11;
}
