.done-btn {
  background-color: #f9b71a;
  border-radius: 20px;
  font-weight: 700;
}

.login-heading {
  @media (min-width: 768px) {
    width: 390px;
    margin: 0 auto;
  }
}

.error {
  color: orangered;
}

.rbi-main-content {
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center; */
  /* background-color: #1a8e6b !important; */
}
