.hba-parent {
  display: flex;
}
.hba-left {
  width: 77%;
  margin-top: 20px;
  margin-left: 5px;
}
.hba-grid {
  display: grid;
  background-color: white;
  padding: 0px 10px 2px 10px;
  font-size: 12px;
  border-bottom: 1px solid #d9d9d9;
}
.hba-1 {
  grid-column: 1;
  grid-row: 1;
}
.hba-2 {
  grid-column: 2;
  grid-row: 1;
}

.hba-3 {
  grid-column: 3;
  grid-row: 1;
}
.hba-4 {
  grid-column: 4;
  grid-row: 1;
}
.hba-5 {
  grid-column: 5;
  grid-row: 1;
}
.hba-6 {
  grid-column: 6;
  grid-row: 1;
}
.hba-input {
  width: 120px;
  border-radius: 5px;
}
.hba-pad {
  padding-top: 7px;
}
.hba-foot {
  margin-top: 20px;
  padding-left: 10px;
}
.hba-foot .add-image-input {
  background-color: white;
}
.hba-p {
  padding: 15px 10px;
  font-size: 12px;
  line-height: 015px;
  font-weight: 700;
}
.hba-p h4 {
  font-weight: 800;
  padding-bottom: 5px;
}
.hba-p span {
  font-weight: 500;
}
.hba-msr {
  padding: 5px 10px;
  font-size: 13px;
  font-weight: 500;
}
