
.appointments {
    display: flex;
    overflow-x: hidden;
}

.patient-appointments-main-div {
    width: 43%;
    background-color: white;
    /* height: 100vh; */
}

.create-patient-main-div {
    width: 57%;
    /* height: 100vh; */
}
.search-icon {
    position: relative;
    right: 34px;
    bottom: 6px;
}