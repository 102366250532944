.semen-grid {
  display: grid;
  gap: 0;
  background-color: white;
  padding: 5px 0 0 10px;
  font-size: 12px;
  height: 420px;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: rgb(218, 218, 221) rgb(207, 204, 204);
}
.semen-grid::-webkit-scrollbar {
  width: 10px;
  height: 100px;
}
.semen-grid::-webkit-scrollbar-track {
  background: rgb(255, 255, 255);
}
.semen-grid::-webkit-scrollbar-thumb {
  background-color: rgb(214, 214, 218);
  border-radius: 20px;
  border: 3px solid rgb(255, 255, 255);
}
.semen-parent {
  display: flex;
}
.semen-left {
  width: 77%;
  margin: 15px 0 0 5px;
}
.semen1 {
  grid-column: 1;
  grid-row: 1;
}
.semen2 {
  grid-column: 2;
  grid-row: 1;
}
.semen-text textarea {
  height: 32px;
  border-radius: 4px;
  border: 1px solid rgb(210, 210, 210);
  margin-top: 2px;
}
.semen-border {
  grid-column: 1 / span 2;
  grid-row: 2;
  border-bottom: 1px solid #d9d9d9;
}
.semen-border2 {
  grid-column: 1 / span 2;
  grid-row: 4;
  border-bottom: 1px solid #d9d9d9;
}
.semen-border3 {
  grid-column: 1 / span 2;
  grid-row: 6;
  border-bottom: 1px solid #d9d9d9;
}
.semen-border4 {
  grid-column: 1 / span 2;
  grid-row: 8;
  border-bottom: 1px solid #d9d9d9;
}
.semen-border5 {
  grid-column: 1 / span 2;
  grid-row: 10;
  border-bottom: 1px solid #d9d9d9;
}
.semen-border6 {
  grid-column: 1 / span 2;
  grid-row: 12;
  border-bottom: 1px solid #d9d9d9;
}
.semen-border7 {
  grid-column: 1 / span 2;
  grid-row: 14;
  border-bottom: 1px solid #d9d9d9;
}
.semen-border8 {
  grid-column: 1 / span 2;
  grid-row: 16;
  border-bottom: 1px solid #d9d9d9;
}
.semen-border9 {
  grid-column: 1 / span 2;
  grid-row: 18;
  border-bottom: 1px solid #d9d9d9;
}
.semen-border10 {
  grid-column: 1 / span 2;
  grid-row: 20;
  border-bottom: 1px solid #d9d9d9;
}
.semen-border11 {
  grid-column: 1 / span 2;
  grid-row: 22;
  border-bottom: 1px solid #d9d9d9;
}
.semen-border12 {
  grid-column: 1 / span 2;
  grid-row: 24;
  border-bottom: 1px solid #d9d9d9;
}
.semen-border13 {
  grid-column: 1 / span 2;
  grid-row: 26;
  border-bottom: 1px solid #d9d9d9;
}
.semen-border14 {
  grid-column: 1 / span 2;
  grid-row: 28;
  border-bottom: 1px solid #d9d9d9;
}
.semen-border15 {
  grid-column: 1 / span 2;
  grid-row: 30;
  border-bottom: 1px solid #d9d9d9;
}
.semen-border16 {
  grid-column: 1 / span 2;
  grid-row: 32;
  border-bottom: 1px solid #d9d9d9;
}
.semen-border17 {
  grid-column: 1 / span 2;
  grid-row: 34;
  border-bottom: 1px solid #d9d9d9;
}
.semen-border18 {
  grid-column: 1 / span 2;
  grid-row: 36;
  border-bottom: 1px solid #d9d9d9;
}
.semen-border19 {
  grid-column: 1 / span 2;
  grid-row: 38;
  border-bottom: 1px solid #d9d9d9;
}
.semen-border20 {
  grid-column: 1 / span 2;
  grid-row: 40;
  border-bottom: 1px solid #d9d9d9;
}
.semen-border21 {
  grid-column: 1 / span 2;
  grid-row: 42;
  border-bottom: 1px solid #d9d9d9;
}
.semen-border22 {
  grid-column: 1 / span 2;
  grid-row: 44;
  border-bottom: 1px solid #d9d9d9;
}

.semen-pad {
  padding: 7px 0 0 5px;
}
.semen-foot {
  margin-top: 20px;
  padding-left: 10px;
}
.semen-foot .add-image-input {
  background-color: white;
}
.semen-method {
  margin-top: 20px;
  padding-left: 10px;
  font-weight: 400;
  font-size: 13px;
}
.formtxt textarea {
  height: 78px;
  border-radius: 4px;
  border: 1px solid rgb(210, 210, 210);
}
.form-pad {
  padding: 30px 0 0 5px;
}
