.gtt-parent {
  display: flex;
}
.gtt-left {
  width: 80%;
  margin-top: 20px;
}
.gtt-grid {
  display: grid;
  background-color: white;
  padding: 5px 10px 0 10px;
  font-size: 12px;
}
.gtt1 {
  grid-column: 1;
  grid-row: 1;
}
.gtt2 {
  grid-column: 2;
  grid-row: 1;
}
.gtt3 {
  grid-column: 3;
  grid-row: 1;
}
.gtt4 {
  grid-column: 4;
  grid-row: 1;
}
.gtt5 {
  grid-column: 5;
  grid-row: 1;
}
.gtt6 {
  grid-column: 6;
  grid-row: 1;
}
.gtt-input {
  width: 120px;
  border-radius: 5px;
  margin-top: 2px;
}
.gtt-line {
  grid-column: 1 / span 6;
  grid-row: 2;
  border-bottom: 1px solid #d9d9d9;
}
.gtt7 {
  grid-column: 1 / span 2;
  grid-row: 3;
}
.gtt8 {
  grid-column: 3 / span 4;
  grid-row: 3;
}
.gtt9 {
  grid-column: 1;
  grid-row: 5;
}
.gtt10 {
  grid-column: 2;
  grid-row: 5;
}
.gtt11 {
  grid-column: 3;
  grid-row: 5;
}
.gtt12 {
  grid-column: 4;
  grid-row: 5;
}
.gtt13 {
  grid-column: 5;
  grid-row: 5;
}
.gtt14 {
  grid-column: 6;
  grid-row: 5;
}
.gtt15 {
  grid-column: 1 / span 2;
  grid-row: 7;
}
.gtt16 {
  grid-column: 3 / span 4;
  grid-row: 7;
}

.gtt17 {
  grid-column: 1;
  grid-row: 9;
}
.gtt18 {
  grid-column: 2;
  grid-row: 9;
}
.gtt19 {
  grid-column: 3;
  grid-row: 9;
}
.gtt20 {
  grid-column: 4;
  grid-row: 9;
}
.gtt21 {
  grid-column: 5;
  grid-row: 9;
}
.gtt22 {
  grid-column: 6;
  grid-row: 9;
}
.gtt23 {
  grid-column: 1 / span 2;
  grid-row: 11;
}
.gtt24 {
  grid-column: 3 / span 4;
  grid-row: 11;
}
.txt textarea {
  height: 32px;
  border-radius: 4px;
  border: 1px solid rgb(210, 210, 210);
  margin-top: 2px;
}
.gtt-line2 {
  grid-column: 1 / span 6;
  grid-row: 4;
  border-bottom: 1px solid #d9d9d9;
}
.gtt-line3 {
  grid-column: 1 / span 6;
  grid-row: 6;
  border-bottom: 1px solid #d9d9d9;
}
.gtt-line4 {
  grid-column: 1 / span 6;
  grid-row: 8;
  border-bottom: 1px solid #d9d9d9;
}
.gtt-line5 {
  grid-column: 1 / span 6;
  grid-row: 10;
  border-bottom: 1px solid #d9d9d9;
}
.gtt-line6 {
  grid-column: 1 / span 6;
  grid-row: 12;
  border-bottom: 1px solid #d9d9d9;
}

.gtt-foot {
  margin-top: 20px;
  padding-left: 5px;
}
.gtt-foot .add-image-input {
  background-color: white;
}
.gtt-pad {
  padding: 6px;
}
.gtt-note {
  margin-top: 20px;
  font-size: 11px;
  padding-left: 5px;
}
.gtt-note h4 {
  font-size: 12px;
}
