.invoice-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2%;
}

.invoice-pick-location {
    background: white;
    width: 77%;
    margin: 0px 1% 0px 0px;
    padding: 10px;
}

.download-file-selection {
    background: #FDC132;
    width: 23%;
    padding-left: 3%;
}

.invoice-top-first-section {
    display: flex;
}

.graph-data .recharts-wrapper{
    width: 100% !important;
}

.graph-data .recharts-surface{
    width: 100% !important;
}

.pick-location {
    font-size: 12px;
    font-weight: 600;
    width: 33%;
    letter-spacing: 0px;
    margin: 0px 10px;
}

.payment-mode {
    font-size: 12px;
    font-weight: 600;
    width: 33%;
    margin: 0px 10px;
}

.from-to-date {
    font-size: 12px;
    font-weight: 600;
    margin: 0px 10px;
    width: 30%;
}

.pick-title .ant-picker-suffix {
    display: none !important;
}

.invoice-top-second-section {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
}

.doctor-service-selection {
    width: 33%;
    margin: 0px 10px;
}

.top-section-invoice-count {
    width: 33%;
    margin: 0px 10px;
}

.doctor-title {
    font-size: 12px;
    font-weight: 600;
    margin: 0;

}

.invoice-count-title {
    font-size: 12px;
    font-weight: 600;
}

.export-icon {
    display: flex;
    padding-top: 5px;
    padding-left: 20px;

}

.export-title {
    font-size: 12px;
    font-weight: bold;
    margin-top: 13px;
    margin-left: 3px;

}

.patient-visits-section {
    background: white;
    width: 65%;
    column-gap: 20px;
    display: flex;
    padding-top: 10px;

}

.fee-payment-mode-section {
    background: white;
    width: 35%;
    margin-left: 10px;
}

.middle-main-section {
    display: flex;
}

.total-bookings-section {
    padding-left: 10px;
    margin-top: 6px;
}

.calendar-select-btn-section {
    padding-left: 110px;
    display: flex;

}

.patient-visits-title {
    font-size: 14px;
    font-weight: 500;
    margin-left: 20px;
}

.booking-title {
    font-size: 12px;
    font-weight: 600;

}

.visit-tile {
    font-size: 12px;
    font-weight: 600;
}

.invoice-generate-btn {
    padding: 5px 24px;
}

.visit-title {
    font-size: 12px;
    font-weight: 600;

}

.invoice-monthly-btn {
    border-radius: 50px;
    padding: 3px 10px;
}

.invoice-daily-btn {
    color: black;
    background: none;
}

.visit-section {
    margin-top: 6px;
    margin-left: 10px;
}

.count-btn {
    box-sizing: border-box;
    border: 1px solid #E9E9E9;
    display: flex;
    margin-right: 20px;
    padding: 1px;

}

.fee-pay-online {
    column-gap: 15px;
    padding-top: 10px;

}

.fee-payment-title {
    margin-top: 10px;
}

.fee-payment-mode-main-section {
    display: flex;
    justify-content: space-between;
    padding: 5px 15px 0px 15px;

}

.select-file {
    width: 80%;
    padding-left: 20px;
}

.count-area {
    display: flex;
}

.invoice-add-btn {
    font-size: 15px;
    background: #e1e1e1;
    border-radius: 0px;
    padding: 0px 7px;
    margin: 2px;
    margin-left: 15px;
    color: black;

}

.invoice-sub-btn {
    font-size: 15px;
    background: #E9E9E9;
    padding: 0px 7px;
    border-radius: 0px;
    margin: 2px;
    margin-right: 15px;
    color: black;
}

.generate-btn-section {
    padding-top: 20px;
    width: 33%;
    margin: 0px 10px;
}

.export-btn {
    padding: 10px 16px;
}

.export-btn-title {
    padding: 5px 16px;
}

.graph-color {
    padding: 5px;
    background: #fdc689;
    margin-top: 4px;
    margin-right: 5px;
    height: 10px;
}

.book-section {
    display: flex;
}

.visit-graph-color {
    background: #E6E6E6;
    padding: 5px;
    width: 1%;
    margin-top: 4px;
    margin-right: 5px;
    height: 10px;
}

.visit-graph {
    display: flex;
}

.payment-online {
    display: flex;
    margin-right: 10px;
}

.online-graph-cash {
    background: #F2A78A;
    padding: 5px;
    width: 1%;
    margin-top: 6px;
    margin-right: 5px;
    height: 10px;
}

.from-to-date .ant-picker{
    width: 100%;
}

.online-graph-creditcard {
    background: #D36E2A;
    padding: 5px;
    width: 1%;
    margin-top: 6px;
    margin-right: 5px;
    height: 10px;
}

.online-graph-checque{
    background: rgb(36, 152, 209);
    padding: 5px;
    width: 1%;
    margin-top: 6px;
    margin-right: 5px;
    height: 10px;
}
.online-graph-reciept{
    background:rgb(179 190 201);
    padding: 5px;
    width: 1%;
    margin-top: 6px;
    margin-right: 5px;
    height: 10px;
}

.fee-pay-title {
    padding-top: 10px;
    font-weight: 600;
}

.invoice-bottom-section {
    margin: 1% 0px;
}

.invoice-middle-section {
    margin-top: 0px;
}

.invoice-summary-container {
    margin: 10px 2%;
}



.invoice-middle-left-section {
    width: 65%;
    background: white;

}

.invoice-middle-left-first-section {
    display: flex;
    padding-top: 10px;
}

.pick-title {
    margin-bottom: 5px;
    font-size: 11px;
    font-weight: 700;
}


.recharts-cartesian-axis-tick-line {
    display: none;

}

.main-div {
    width: 32%;
    margin-right: 10px;
}

.main-div-btn {
    width: 32%;
    margin-right: 10px;
    margin-top: 20px;
}

.main-div-calender {
    width: 32%;
    margin-right: 10px;
}

.main-div-calender .anticon {
    display: none;
}