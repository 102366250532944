.hariWidalTest-grid {
  display: grid;
  gap: 0;
  background-color: white;
  padding: 5px 10px 0 10px;
  font-size: 12px;
}

.hariWidalTest-grid-two {
  display: grid;
  background-color: white;
  padding: 5px 10px 0 10px;
  font-size: 12px;
  height: auto;
  overflow: scroll;
}
.hariWidalTest-parent {
  display: flex;
}
.hariWidalTest-left {
  width: 77%;
  margin: 15px 0 0 5px;
}

/* -------------------------row 1--------------------------------- */
.hariWidalTest-1 {
  grid-column: 1;
  grid-row: 1;
}
.hariWidalTest-2 {
  grid-column: 2;
  grid-row: 1;
}
.hariWidalTest-3 {
  grid-column: 3 / span 2;
  grid-row: 1;
}
.hariWidalTest-4 {
  grid-column: 5 / span 2;
  grid-row: 1;
}
.hariWidalTest-5 {
  grid-column: 7;
  grid-row: 1;
}
.hariWidalTest-6 {
  grid-column: 8;
  grid-row: 1;
}
.hariWidalTest-7 {
  grid-column: 9;
  grid-row: 1;
}
.hariWidalTest-8 {
  grid-column: 10;
  grid-row: 1;
}
.hariWidalTest-9 {
  grid-column: 11;
  grid-row: 1;
}
.hariWidalTest-10 {
  grid-column: 12;
  grid-row: 1;
}
.hariWidalTest-11 {
  grid-column: 13;
  grid-row: 1;
}

.hariWidalTest-12 {
  grid-column: 14;
  grid-row: 1;
}
.hariWidalTest-13 {
  grid-column: 15;
  grid-row: 1;
}
.hariWidalTest-14 {
  grid-column: 16;
  grid-row: 1;
}
.hariWidalTest-15 {
  grid-column: 17;
  grid-row: 1;
}
.hariWidalTest-16 {
  grid-column: 18;
  grid-row: 1;
}
.hariWidalTest-17 {
  grid-column: 19;
  grid-row: 1;
}
.hariWidalTest-18 {
  grid-column: 20;
  grid-row: 1;
}
.hariWidalTest-19 {
  grid-column: 21;
  grid-row: 1;
}
.hariWidalTest-20 {
  grid-column: 22;
  grid-row: 1;
}
.hariWidalTest-21 {
  grid-column: 23;
  grid-row: 1;
}
.hariWidalTest-22 {
  grid-column: 24;
  grid-row: 1;
}
.hariWidalTest-23 {
  grid-column: 25;
  grid-row: 1;
}
.hariWidalTest-24 {
  grid-column: 26;
  grid-row: 1;
}
.hariWidalTest-25 {
  grid-column: 27;
  grid-row: 1;
}
.hariWidalTest-26 {
  grid-column: 28;
  grid-row: 1;
}
.hariWidalTest-27 {
  grid-column: 29;
  grid-row: 1;
}
.hariWidalTest-28 {
  grid-column: 30;
  grid-row: 1;
}
.hariWidalTest-29 {
  grid-column: 31;
  grid-row: 1;
}
.hariWidalTest-30 {
  grid-column: 32;
  grid-row: 1;
}
.hariWidalTest-31 {
  grid-column: 33;
  grid-row: 1;
}
.hariWidalTest-32 {
  grid-column: 34;
  grid-row: 1;
}
.hariWidalTest-33 {
  grid-column: 35;
  grid-row: 1;
}
.hariWidalTest-34 {
  grid-column: 36;
  grid-row: 1;
}
.hariWidalTest-border {
  grid-column: 1 / span 36;
  grid-row: 2;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 1px;
}
/* -------------------------row 2--------------------------------- */
.hariWidalTest-one-input-1 {
  grid-column: 1;
  grid-row: 3;
}
.hariWidalTest-one-input-2 {
  grid-column: 2;
  grid-row: 3;
}
.hariWidalTest-one-input-3 {
  grid-column: 3 / span 2;
  grid-row: 3;
}
.hariWidalTest-one-input-4 {
  grid-column: 5 / span 2;
  grid-row: 3;
}
.hariWidalTest-one-input-5 {
  grid-column: 7;
  grid-row: 3;
}
.hariWidalTest-one-input-6 {
  grid-column: 8;
  grid-row: 3;
}
.hariWidalTest-one-input-7 {
  grid-column: 9;
  grid-row: 3;
}
.hariWidalTest-one-input-8 {
  grid-column: 10;
  grid-row: 3;
}
.hariWidalTest-one-input-9 {
  grid-column: 11;
  grid-row: 3;
}
.hariWidalTest-one-input-10 {
  grid-column: 12;
  grid-row: 3;
}
.hariWidalTest-one-input-11 {
  grid-column: 13;
  grid-row: 3;
}
.hariWidalTest-one-input-12 {
  grid-column: 14;
  grid-row: 3;
}
.hariWidalTest-one-input-13 {
  grid-column: 15;
  grid-row: 3;
}
.hariWidalTest-one-input-14 {
  grid-column: 16;
  grid-row: 3;
}
.hariWidalTest-one-input-15 {
  grid-column: 17;
  grid-row: 3;
}
.hariWidalTest-one-input-16 {
  grid-column: 18;
  grid-row: 3;
}
.hariWidalTest-one-input-17 {
  grid-column: 19;
  grid-row: 3;
}
.hariWidalTest-one-input-18 {
  grid-column: 20;
  grid-row: 3;
}
.hariWidalTest-one-input-19 {
  grid-column: 21;
  grid-row: 3;
}
.hariWidalTest-one-input-20 {
  grid-column: 22;
  grid-row: 3;
}
.hariWidalTest-one-input-21 {
  grid-column: 23;
  grid-row: 3;
}
.hariWidalTest-one-input-22 {
  grid-column: 24;
  grid-row: 3;
}
.hariWidalTest-one-input-23 {
  grid-column: 25;
  grid-row: 3;
}
.hariWidalTest-one-input-24 {
  grid-column: 26;
  grid-row: 3;
}
.hariWidalTest-one-input-25 {
  grid-column: 27;
  grid-row: 3;
}
.hariWidalTest-one-input-26 {
  grid-column: 28;
  grid-row: 3;
}
.hariWidalTest-one-input-27 {
  grid-column: 29;
  grid-row: 3;
}
.hariWidalTest-one-input-28 {
  grid-column: 30;
  grid-row: 3;
}
.hariWidalTest-one-input-29 {
  grid-column: 31;
  grid-row: 3;
}
.hariWidalTest-one-input-30 {
  grid-column: 32;
  grid-row: 3;
}
.hariWidalTest-one-input-31 {
  grid-column: 33;
  grid-row: 3;
}
.hariWidalTest-one-input-32 {
  grid-column: 34;
  grid-row: 3;
}
.hariWidalTest-one-input-33 {
  grid-column: 35;
  grid-row: 3;
}
.hariWidalTest-one-input-34 {
  grid-column: 36;
  grid-row: 3;
}
.hariWidalTest-border-1 {
  grid-column: 1 / span 36;
  grid-row: 4;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 1px;
}
/* ---------------------row 3------------------------- */
.hariWidalTest-two-input-1 {
  grid-column: 1;
  grid-row: 5;
}
.hariWidalTest-two-input-2 {
  grid-column: 2;
  grid-row: 5;
}
.hariWidalTest-two-input-3 {
  grid-column: 3 / span 2;
  grid-row: 5;
}
.hariWidalTest-two-input-4 {
  grid-column: 5 / span 2;
  grid-row: 5;
}
.hariWidalTest-two-input-5 {
  grid-column: 7;
  grid-row: 5;
}
.hariWidalTest-two-input-6 {
  grid-column: 8;
  grid-row: 5;
}
.hariWidalTest-two-input-7 {
  grid-column: 9;
  grid-row: 5;
}
.hariWidalTest-two-input-8 {
  grid-column: 10;
  grid-row: 5;
}
.hariWidalTest-two-input-9 {
  grid-column: 11;
  grid-row: 5;
}
.hariWidalTest-two-input-10 {
  grid-column: 12;
  grid-row: 5;
}
.hariWidalTest-two-input-11 {
  grid-column: 13;
  grid-row: 5;
}
.hariWidalTest-two-input-12 {
  grid-column: 14;
  grid-row: 5;
}
.hariWidalTest-two-input-13 {
  grid-column: 15;
  grid-row: 5;
}
.hariWidalTest-two-input-14 {
  grid-column: 16;
  grid-row: 5;
}
.hariWidalTest-two-input-15 {
  grid-column: 17;
  grid-row: 5;
}
.hariWidalTest-two-input-16 {
  grid-column: 18;
  grid-row: 5;
}
.hariWidalTest-two-input-17 {
  grid-column: 19;
  grid-row: 5;
}
.hariWidalTest-two-input-18 {
  grid-column: 20;
  grid-row: 5;
}
.hariWidalTest-two-input-19 {
  grid-column: 21;
  grid-row: 5;
}
.hariWidalTest-two-input-20 {
  grid-column: 22;
  grid-row: 5;
}
.hariWidalTest-two-input-21 {
  grid-column: 23;
  grid-row: 5;
}
.hariWidalTest-two-input-22 {
  grid-column: 24;
  grid-row: 5;
}
.hariWidalTest-two-input-23 {
  grid-column: 25;
  grid-row: 5;
}
.hariWidalTest-two-input-24 {
  grid-column: 26;
  grid-row: 5;
}
.hariWidalTest-two-input-25 {
  grid-column: 27;
  grid-row: 5;
}
.hariWidalTest-two-input-26 {
  grid-column: 28;
  grid-row: 5;
}
.hariWidalTest-two-input-27 {
  grid-column: 29;
  grid-row: 5;
}
.hariWidalTest-two-input-28 {
  grid-column: 30;
  grid-row: 5;
}
.hariWidalTest-two-input-29 {
  grid-column: 31;
  grid-row: 5;
}
.hariWidalTest-two-input-30 {
  grid-column: 32;
  grid-row: 5;
}
.hariWidalTest-two-input-31 {
  grid-column: 33;
  grid-row: 5;
}
.hariWidalTest-two-input-32 {
  grid-column: 34;
  grid-row: 5;
}
.hariWidalTest-two-input-33 {
  grid-column: 35;
  grid-row: 5;
}
.hariWidalTest-two-input-34 {
  grid-column: 36;
  grid-row: 5;
}
.hariWidalTest-border-2 {
  grid-column: 1 / span 36;
  grid-row: 6;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 1px;
}

/* -----------------------row 4---------------------------- */

.hariWidalTest-three-input-1 {
  grid-column: 1;
  grid-row: 7;
}

.hariWidalTest-three-input-2 {
  grid-column: 2;
  grid-row: 7;
}
.hariWidalTest-three-input-3 {
  grid-column: 3 / span 2;
  grid-row: 7;
}
.hariWidalTest-three-input-4 {
  grid-column: 5 / span 2;
  grid-row: 7;
}
.hariWidalTest-three-input-5 {
  grid-column: 7;
  grid-row: 7;
}
.hariWidalTest-three-input-6 {
  grid-column: 8;
  grid-row: 7;
}
.hariWidalTest-three-input-7 {
  grid-column: 9;
  grid-row: 7;
}
.hariWidalTest-three-input-8 {
  grid-column: 10;
  grid-row: 7;
}
.hariWidalTest-three-input-9 {
  grid-column: 11;
  grid-row: 7;
}
.hariWidalTest-three-input-10 {
  grid-column: 12;
  grid-row: 7;
}

.hariWidalTest-three-input-11 {
  grid-column: 13;
  grid-row: 7;
}

.hariWidalTest-three-input-12 {
  grid-column: 14;
  grid-row: 7;
}
.hariWidalTest-three-input-13 {
  grid-column: 15;
  grid-row: 7;
}
.hariWidalTest-three-input-14 {
  grid-column: 16;
  grid-row: 7;
}
.hariWidalTest-three-input-15 {
  grid-column: 17;
  grid-row: 7;
}
.hariWidalTest-three-input-16 {
  grid-column: 18;
  grid-row: 7;
}
.hariWidalTest-three-input-17 {
  grid-column: 19;
  grid-row: 7;
}
.hariWidalTest-three-input-18 {
  grid-column: 20;
  grid-row: 7;
}
.hariWidalTest-three-input-19 {
  grid-column: 21;
  grid-row: 7;
}
.hariWidalTest-three-input-20 {
  grid-column: 22;
  grid-row: 7;
}
.hariWidalTest-three-input-21 {
  grid-column: 23;
  grid-row: 7;
}
.hariWidalTest-three-input-22 {
  grid-column: 24;
  grid-row: 7;
}
.hariWidalTest-three-input-23 {
  grid-column: 25;
  grid-row: 7;
}
.hariWidalTest-three-input-24 {
  grid-column: 26;
  grid-row: 7;
}
.hariWidalTest-three-input-25 {
  grid-column: 27;
  grid-row: 7;
}
.hariWidalTest-three-input-26 {
  grid-column: 28;
  grid-row: 7;
}
.hariWidalTest-three-input-27 {
  grid-column: 29;
  grid-row: 7;
}
.hariWidalTest-three-input-28 {
  grid-column: 30;
  grid-row: 7;
}
.hariWidalTest-three-input-29 {
  grid-column: 31;
  grid-row: 7;
}
.hariWidalTest-three-input-30 {
  grid-column: 32;
  grid-row: 7;
}
.hariWidalTest-three-input-31 {
  grid-column: 33;
  grid-row: 7;
}
.hariWidalTest-three-input-32 {
  grid-column: 34;
  grid-row: 7;
}
.hariWidalTest-three-input-33 {
  grid-column: 35;
  grid-row: 7;
}
.hariWidalTest-three-input-34 {
  grid-column: 36;
  grid-row: 7;
}
.hariWidalTest-border-3 {
  grid-column: 1 / span 36;
  grid-row: 8;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 1px;
}

/* -----------------------row 5---------------------------- */

.hariWidalTest-four-input-1 {
  grid-column: 1;
  grid-row: 9;
}

.hariWidalTest-four-input-2 {
  grid-column: 2;
  grid-row: 9;
}
.hariWidalTest-four-input-3 {
  grid-column: 3 / span 2;
  grid-row: 9;
}
.hariWidalTest-four-input-4 {
  grid-column: 5 / span 2;
  grid-row: 9;
}
.hariWidalTest-four-input-5 {
  grid-column: 7;
  grid-row: 9;
}
.hariWidalTest-four-input-6 {
  grid-column: 8;
  grid-row: 9;
}
.hariWidalTest-four-input-7 {
  grid-column: 9;
  grid-row: 9;
}
.hariWidalTest-four-input-8 {
  grid-column: 10;
  grid-row: 9;
}
.hariWidalTest-four-input-9 {
  grid-column: 11;
  grid-row: 9;
}
.hariWidalTest-four-input-10 {
  grid-column: 12;
  grid-row: 9;
}

.hariWidalTest-four-input-11 {
  grid-column: 13;
  grid-row: 9;
}

.hariWidalTest-four-input-12 {
  grid-column: 14;
  grid-row: 9;
}
.hariWidalTest-four-input-13 {
  grid-column: 15;
  grid-row: 9;
}
.hariWidalTest-four-input-14 {
  grid-column: 16;
  grid-row: 9;
}
.hariWidalTest-four-input-15 {
  grid-column: 17;
  grid-row: 9;
}
.hariWidalTest-four-input-16 {
  grid-column: 18;
  grid-row: 9;
}
.hariWidalTest-four-input-17 {
  grid-column: 19;
  grid-row: 9;
}
.hariWidalTest-four-input-18 {
  grid-column: 20;
  grid-row: 9;
}
.hariWidalTest-four-input-19 {
  grid-column: 21;
  grid-row: 9;
}
.hariWidalTest-four-input-20 {
  grid-column: 22;
  grid-row: 9;
}
.hariWidalTest-four-input-21 {
  grid-column: 23;
  grid-row: 9;
}
.hariWidalTest-four-input-22 {
  grid-column: 24;
  grid-row: 9;
}
.hariWidalTest-four-input-23 {
  grid-column: 25;
  grid-row: 9;
}
.hariWidalTest-four-input-24 {
  grid-column: 26;
  grid-row: 9;
}
.hariWidalTest-four-input-25 {
  grid-column: 27;
  grid-row: 9;
}
.hariWidalTest-four-input-26 {
  grid-column: 28;
  grid-row: 9;
}
.hariWidalTest-four-input-27 {
  grid-column: 29;
  grid-row: 9;
}
.hariWidalTest-four-input-28 {
  grid-column: 30;
  grid-row: 9;
}
.hariWidalTest-four-input-29 {
  grid-column: 31;
  grid-row: 9;
}
.hariWidalTest-four-input-30 {
  grid-column: 32;
  grid-row: 9;
}
.hariWidalTest-four-input-31 {
  grid-column: 33;
  grid-row: 9;
}
.hariWidalTest-four-input-32 {
  grid-column: 34;
  grid-row: 9;
}
.hariWidalTest-four-input-33 {
  grid-column: 35;
  grid-row: 9;
}
.hariWidalTest-four-input-34 {
  grid-column: 36;
  grid-row: 9;
}
.hariWidalTest-border-4 {
  grid-column: 1 / span 36;
  grid-row: 10;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 1px;
}
/* -----------------------row 6---------------------------- */

.hariWidalTest-five-input-1 {
  grid-column: 1;
  grid-row: 11;
}

.hariWidalTest-five-input-2 {
  grid-column: 2;
  grid-row: 11;
}
.hariWidalTest-five-input-3 {
  grid-column: 3 / span 2;
  grid-row: 11;
}
.hariWidalTest-five-input-4 {
  grid-column: 5 / span 2;
  grid-row: 11;
}
.hariWidalTest-five-input-5 {
  grid-column: 7;
  grid-row: 11;
}
.hariWidalTest-five-input-6 {
  grid-column: 8;
  grid-row: 11;
}
.hariWidalTest-five-input-7 {
  grid-column: 9;
  grid-row: 11;
}
.hariWidalTest-five-input-8 {
  grid-column: 10;
  grid-row: 11;
}
.hariWidalTest-five-input-9 {
  grid-column: 11;
  grid-row: 11;
}
.hariWidalTest-five-input-10 {
  grid-column: 12;
  grid-row: 11;
}

.hariWidalTest-five-input-11 {
  grid-column: 13;
  grid-row: 11;
}

.hariWidalTest-five-input-12 {
  grid-column: 14;
  grid-row: 11;
}
.hariWidalTest-five-input-13 {
  grid-column: 15;
  grid-row: 11;
}
.hariWidalTest-five-input-14 {
  grid-column: 16;
  grid-row: 11;
}
.hariWidalTest-five-input-15 {
  grid-column: 17;
  grid-row: 11;
}
.hariWidalTest-five-input-16 {
  grid-column: 18;
  grid-row: 11;
}
.hariWidalTest-five-input-17 {
  grid-column: 19;
  grid-row: 11;
}
.hariWidalTest-five-input-18 {
  grid-column: 20;
  grid-row: 11;
}
.hariWidalTest-five-input-19 {
  grid-column: 21;
  grid-row: 11;
}
.hariWidalTest-five-input-20 {
  grid-column: 22;
  grid-row: 11;
}
.hariWidalTest-five-input-21 {
  grid-column: 23;
  grid-row: 11;
}
.hariWidalTest-five-input-22 {
  grid-column: 24;
  grid-row: 11;
}
.hariWidalTest-five-input-23 {
  grid-column: 25;
  grid-row: 11;
}
.hariWidalTest-five-input-24 {
  grid-column: 26;
  grid-row: 11;
}
.hariWidalTest-five-input-25 {
  grid-column: 27;
  grid-row: 11;
}
.hariWidalTest-five-input-26 {
  grid-column: 28;
  grid-row: 11;
}
.hariWidalTest-five-input-27 {
  grid-column: 29;
  grid-row: 11;
}
.hariWidalTest-five-input-28 {
  grid-column: 30;
  grid-row: 11;
}
.hariWidalTest-five-input-29 {
  grid-column: 31;
  grid-row: 11;
}
.hariWidalTest-five-input-30 {
  grid-column: 32;
  grid-row: 11;
}
.hariWidalTest-five-input-31 {
  grid-column: 33;
  grid-row: 11;
}
.hariWidalTest-five-input-32 {
  grid-column: 34;
  grid-row: 11;
}
.hariWidalTest-five-input-33 {
  grid-column: 35;
  grid-row: 11;
}
.hariWidalTest-five-input-34 {
  grid-column: 36;
  grid-row: 11;
}
.hariWidalTest-border-5 {
  grid-column: 1 / span 36;
  grid-row: 12;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 1px;
}

/* --------------------------------- */
/* -------------------------row 7--------------------------------- */
.hariWidalTest-six-input-1 {
  grid-column: 1;
  grid-row: 11;
}
.hariWidalTest-six-input-2 {
  grid-column: 2;
  grid-row: 11;
}
.hariWidalTest-six-input-3 {
  grid-column: 3 / span 2;
  grid-row: 11;
}
.hariWidalTest-six-input-4 {
  grid-column: 5 / span 2;
  grid-row: 11;
}
.hariWidalTest-six-input-5 {
  grid-column: 7;
  grid-row: 11;
}
.hariWidalTest-six-input-6 {
  grid-column: 8;
  grid-row: 11;
}
.hariWidalTest-six-input-7 {
  grid-column: 9;
  grid-row: 11;
}
.hariWidalTest-six-input-8 {
  grid-column: 10;
  grid-row: 11;
}
.hariWidalTest-six-input-9 {
  grid-column: 11;
  grid-row: 11;
}
.hariWidalTest-six-input-10 {
  grid-column: 12;
  grid-row: 11;
}
.hariWidalTest-six-input-11 {
  grid-column: 13;
  grid-row: 11;
}
.hariWidalTest-six-input-12 {
  grid-column: 14;
  grid-row: 11;
}
.hariWidalTest-six-input-13 {
  grid-column: 15;
  grid-row: 11;
}
.hariWidalTest-six-input-14 {
  grid-column: 16;
  grid-row: 11;
}
.hariWidalTest-six-input-15 {
  grid-column: 17;
  grid-row: 11;
}
.hariWidalTest-six-input-16 {
  grid-column: 18;
  grid-row: 11;
}
.hariWidalTest-six-input-17 {
  grid-column: 19;
  grid-row: 11;
}
.hariWidalTest-six-input-18 {
  grid-column: 20;
  grid-row: 11;
}
.hariWidalTest-six-input-19 {
  grid-column: 21;
  grid-row: 11;
}
.hariWidalTest-six-input-20 {
  grid-column: 22;
  grid-row: 11;
}
.hariWidalTest-six-input-21 {
  grid-column: 23;
  grid-row: 11;
}
.hariWidalTest-six-input-22 {
  grid-column: 24;
  grid-row: 11;
}
.hariWidalTest-six-input-23 {
  grid-column: 25;
  grid-row: 11;
}
.hariWidalTest-six-input-24 {
  grid-column: 26;
  grid-row: 11;
}
.hariWidalTest-six-input-25 {
  grid-column: 27;
  grid-row: 11;
}
.hariWidalTest-six-input-26 {
  grid-column: 28;
  grid-row: 11;
}
.hariWidalTest-six-input-27 {
  grid-column: 29;
  grid-row: 11;
}
.hariWidalTest-six-input-28 {
  grid-column: 30;
  grid-row: 11;
}
.hariWidalTest-six-input-29 {
  grid-column: 31;
  grid-row: 11;
}
.hariWidalTest-six-input-30 {
  grid-column: 32;
  grid-row: 11;
}
.hariWidalTest-six-input-31 {
  grid-column: 33;
  grid-row: 11;
}
.hariWidalTest-six-input-32 {
  grid-column: 34;
  grid-row: 11;
}
.hariWidalTest-six-input-33 {
  grid-column: 35;
  grid-row: 11;
}
.hariWidalTest-six-input-34 {
  grid-column: 36;
  grid-row: 11;
}
.hariWidalTest-border-6 {
  grid-column: 1 / span 36;
  grid-row: 12;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 1px;
}
/* ---------------------row 3------------------------- */
.hariWidalTest-seven-input-1 {
  grid-column: 1;
  grid-row: 13;
}
.hariWidalTest-seven-input-2 {
  grid-column: 2;
  grid-row: 13;
}
.hariWidalTest-seven-input-3 {
  grid-column: 3 / span 2;
  grid-row: 13;
}
.hariWidalTest-seven-input-4 {
  grid-column: 5 / span 2;
  grid-row: 13;
}
.hariWidalTest-seven-input-5 {
  grid-column: 7;
  grid-row: 13;
}
.hariWidalTest-seven-input-6 {
  grid-column: 8;
  grid-row: 13;
}
.hariWidalTest-seven-input-7 {
  grid-column: 9;
  grid-row: 13;
}
.hariWidalTest-seven-input-8 {
  grid-column: 10;
  grid-row: 13;
}
.hariWidalTest-seven-input-9 {
  grid-column: 11;
  grid-row: 13;
}
.hariWidalTest-seven-input-10 {
  grid-column: 12;
  grid-row: 13;
}
.hariWidalTest-seven-input-11 {
  grid-column: 13;
  grid-row: 13;
}
.hariWidalTest-seven-input-12 {
  grid-column: 14;
  grid-row: 13;
}
.hariWidalTest-seven-input-13 {
  grid-column: 15;
  grid-row: 13;
}
.hariWidalTest-seven-input-14 {
  grid-column: 16;
  grid-row: 13;
}
.hariWidalTest-seven-input-15 {
  grid-column: 17;
  grid-row: 13;
}
.hariWidalTest-seven-input-16 {
  grid-column: 18;
  grid-row: 13;
}
.hariWidalTest-seven-input-17 {
  grid-column: 19;
  grid-row: 13;
}
.hariWidalTest-seven-input-18 {
  grid-column: 20;
  grid-row: 13;
}
.hariWidalTest-seven-input-19 {
  grid-column: 21;
  grid-row: 13;
}
.hariWidalTest-seven-input-20 {
  grid-column: 22;
  grid-row: 13;
}
.hariWidalTest-seven-input-21 {
  grid-column: 23;
  grid-row: 13;
}
.hariWidalTest-seven-input-22 {
  grid-column: 24;
  grid-row: 13;
}
.hariWidalTest-seven-input-23 {
  grid-column: 25;
  grid-row: 13;
}
.hariWidalTest-seven-input-24 {
  grid-column: 26;
  grid-row: 13;
}
.hariWidalTest-seven-input-25 {
  grid-column: 27;
  grid-row: 13;
}
.hariWidalTest-seven-input-26 {
  grid-column: 28;
  grid-row: 13;
}
.hariWidalTest-seven-input-27 {
  grid-column: 29;
  grid-row: 13;
}
.hariWidalTest-seven-input-28 {
  grid-column: 30;
  grid-row: 13;
}
.hariWidalTest-seven-input-29 {
  grid-column: 31;
  grid-row: 13;
}
.hariWidalTest-seven-input-30 {
  grid-column: 32;
  grid-row: 13;
}
.hariWidalTest-seven-input-31 {
  grid-column: 33;
  grid-row: 13;
}
.hariWidalTest-seven-input-32 {
  grid-column: 34;
  grid-row: 13;
}
.hariWidalTest-seven-input-33 {
  grid-column: 35;
  grid-row: 13;
}
.hariWidalTest-seven-input-34 {
  grid-column: 36;
  grid-row: 13;
}
.hariWidalTest-border-7 {
  grid-column: 1 / span 36;
  grid-row: 14;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 1px;
}

/* -----------------------row 4---------------------------- */

.hariWidalTest-eight-input-1 {
  grid-column: 1;
  grid-row: 15;
}

.hariWidalTest-eight-input-2 {
  grid-column: 2;
  grid-row: 15;
}
.hariWidalTest-eight-input-3 {
  grid-column: 3 / span 2;
  grid-row: 15;
}
.hariWidalTest-eight-input-4 {
  grid-column: 5 / span 2;
  grid-row: 15;
}
.hariWidalTest-eight-input-5 {
  grid-column: 7;
  grid-row: 15;
}
.hariWidalTest-eight-input-6 {
  grid-column: 8;
  grid-row: 15;
}
.hariWidalTest-eight-input-7 {
  grid-column: 9;
  grid-row: 15;
}
.hariWidalTest-eight-input-8 {
  grid-column: 10;
  grid-row: 15;
}
.hariWidalTest-eight-input-9 {
  grid-column: 11;
  grid-row: 15;
}
.hariWidalTest-eight-input-10 {
  grid-column: 12;
  grid-row: 15;
}

.hariWidalTest-eight-input-11 {
  grid-column: 13;
  grid-row: 15;
}

.hariWidalTest-eight-input-12 {
  grid-column: 14;
  grid-row: 15;
}
.hariWidalTest-eight-input-13 {
  grid-column: 15;
  grid-row: 15;
}
.hariWidalTest-eight-input-14 {
  grid-column: 16;
  grid-row: 15;
}
.hariWidalTest-eight-input-15 {
  grid-column: 17;
  grid-row: 15;
}
.hariWidalTest-eight-input-16 {
  grid-column: 18;
  grid-row: 15;
}
.hariWidalTest-eight-input-17 {
  grid-column: 19;
  grid-row: 15;
}
.hariWidalTest-eight-input-18 {
  grid-column: 20;
  grid-row: 15;
}
.hariWidalTest-eight-input-19 {
  grid-column: 21;
  grid-row: 15;
}
.hariWidalTest-eight-input-20 {
  grid-column: 22;
  grid-row: 15;
}
.hariWidalTest-eight-input-21 {
  grid-column: 23;
  grid-row: 15;
}
.hariWidalTest-eight-input-22 {
  grid-column: 24;
  grid-row: 15;
}
.hariWidalTest-eight-input-23 {
  grid-column: 25;
  grid-row: 15;
}
.hariWidalTest-eight-input-24 {
  grid-column: 26;
  grid-row: 15;
}
.hariWidalTest-eight-input-25 {
  grid-column: 27;
  grid-row: 15;
}
.hariWidalTest-eight-input-26 {
  grid-column: 28;
  grid-row: 15;
}
.hariWidalTest-eight-input-27 {
  grid-column: 29;
  grid-row: 15;
}
.hariWidalTest-eight-input-28 {
  grid-column: 30;
  grid-row: 15;
}
.hariWidalTest-eight-input-29 {
  grid-column: 31;
  grid-row: 15;
}
.hariWidalTest-eight-input-30 {
  grid-column: 32;
  grid-row: 15;
}
.hariWidalTest-eight-input-31 {
  grid-column: 33;
  grid-row: 15;
}
.hariWidalTest-eight-input-32 {
  grid-column: 34;
  grid-row: 15;
}
.hariWidalTest-eight-input-33 {
  grid-column: 35;
  grid-row: 15;
}
.hariWidalTest-eight-input-34 {
  grid-column: 36;
  grid-row: 15;
}
.hariWidalTest-border-8 {
  grid-column: 1 / span 36;
  grid-row: 16;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 1px;
}

/* -----------------------row 5---------------------------- */

.hariWidalTest-nine-input-1 {
  grid-column: 1;
  grid-row: 17;
}

.hariWidalTest-nine-input-2 {
  grid-column: 2;
  grid-row: 17;
}
.hariWidalTest-nine-input-3 {
  grid-column: 3 / span 2;
  grid-row: 17;
}
.hariWidalTest-nine-input-4 {
  grid-column: 5 / span 2;
  grid-row: 17;
}
.hariWidalTest-nine-input-5 {
  grid-column: 7;
  grid-row: 17;
}
.hariWidalTest-nine-input-6 {
  grid-column: 8;
  grid-row: 17;
}
.hariWidalTest-nine-input-7 {
  grid-column: 9;
  grid-row: 17;
}
.hariWidalTest-nine-input-8 {
  grid-column: 10;
  grid-row: 17;
}
.hariWidalTest-nine-input-9 {
  grid-column: 11;
  grid-row: 17;
}
.hariWidalTest-nine-input-10 {
  grid-column: 12;
  grid-row: 17;
}

.hariWidalTest-nine-input-11 {
  grid-column: 13;
  grid-row: 17;
}

.hariWidalTest-nine-input-12 {
  grid-column: 14;
  grid-row: 17;
}
.hariWidalTest-nine-input-13 {
  grid-column: 15;
  grid-row: 17;
}
.hariWidalTest-nine-input-14 {
  grid-column: 16;
  grid-row: 17;
}
.hariWidalTest-nine-input-15 {
  grid-column: 17;
  grid-row: 17;
}
.hariWidalTest-nine-input-16 {
  grid-column: 18;
  grid-row: 17;
}
.hariWidalTest-nine-input-17 {
  grid-column: 19;
  grid-row: 17;
}
.hariWidalTest-nine-input-18 {
  grid-column: 20;
  grid-row: 17;
}
.hariWidalTest-nine-input-19 {
  grid-column: 21;
  grid-row: 17;
}
.hariWidalTest-nine-input-20 {
  grid-column: 22;
  grid-row: 17;
}
.hariWidalTest-nine-input-21 {
  grid-column: 23;
  grid-row: 17;
}
.hariWidalTest-nine-input-22 {
  grid-column: 24;
  grid-row: 17;
}
.hariWidalTest-nine-input-23 {
  grid-column: 25;
  grid-row: 17;
}
.hariWidalTest-nine-input-24 {
  grid-column: 26;
  grid-row: 17;
}
.hariWidalTest-nine-input-25 {
  grid-column: 27;
  grid-row: 17;
}
.hariWidalTest-nine-input-26 {
  grid-column: 28;
  grid-row: 17;
}
.hariWidalTest-nine-input-27 {
  grid-column: 29;
  grid-row: 17;
}
.hariWidalTest-nine-input-28 {
  grid-column: 30;
  grid-row: 17;
}
.hariWidalTest-nine-input-29 {
  grid-column: 31;
  grid-row: 17;
}
.hariWidalTest-nine-input-30 {
  grid-column: 32;
  grid-row: 17;
}
.hariWidalTest-nine-input-31 {
  grid-column: 33;
  grid-row: 17;
}
.hariWidalTest-nine-input-32 {
  grid-column: 34;
  grid-row: 17;
}
.hariWidalTest-nine-input-33 {
  grid-column: 35;
  grid-row: 17;
}
.hariWidalTest-nine-input-34 {
  grid-column: 36;
  grid-row: 17;
}
.hariWidalTest-border-9 {
  grid-column: 1 / span 36;
  grid-row: 18;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 1px;
}
/* -----------------------row 11---------------------------- */

.hariWidalTest-ten-input-1 {
  grid-column: 1;
  grid-row: 19;
}

.hariWidalTest-ten-input-2 {
  grid-column: 2;
  grid-row: 19;
}
.hariWidalTest-ten-input-3 {
  grid-column: 3 / span 2;
  grid-row: 19;
}
.hariWidalTest-ten-input-4 {
  grid-column: 5 / span 2;
  grid-row: 19;
}
.hariWidalTest-ten-input-5 {
  grid-column: 7;
  grid-row: 19;
}
.hariWidalTest-ten-input-6 {
  grid-column: 8;
  grid-row: 19;
}
.hariWidalTest-ten-input-7 {
  grid-column: 9;
  grid-row: 19;
}
.hariWidalTest-ten-input-8 {
  grid-column: 10;
  grid-row: 19;
}
.hariWidalTest-ten-input-9 {
  grid-column: 11;
  grid-row: 19;
}
.hariWidalTest-ten-input-10 {
  grid-column: 12;
  grid-row: 19;
}

.hariWidalTest-ten-input-11 {
  grid-column: 13;
  grid-row: 19;
}

.hariWidalTest-ten-input-12 {
  grid-column: 14;
  grid-row: 19;
}
.hariWidalTest-ten-input-13 {
  grid-column: 15;
  grid-row: 19;
}
.hariWidalTest-ten-input-14 {
  grid-column: 16;
  grid-row: 19;
}
.hariWidalTest-ten-input-15 {
  grid-column: 17;
  grid-row: 19;
}
.hariWidalTest-ten-input-16 {
  grid-column: 18;
  grid-row: 19;
}
.hariWidalTest-ten-input-17 {
  grid-column: 19;
  grid-row: 19;
}
.hariWidalTest-ten-input-18 {
  grid-column: 20;
  grid-row: 19;
}
.hariWidalTest-ten-input-19 {
  grid-column: 21;
  grid-row: 19;
}
.hariWidalTest-ten-input-20 {
  grid-column: 22;
  grid-row: 19;
}
.hariWidalTest-ten-input-21 {
  grid-column: 23;
  grid-row: 19;
}
.hariWidalTest-ten-input-22 {
  grid-column: 24;
  grid-row: 19;
}
.hariWidalTest-ten-input-23 {
  grid-column: 25;
  grid-row: 19;
}
.hariWidalTest-ten-input-24 {
  grid-column: 26;
  grid-row: 19;
}
.hariWidalTest-ten-input-25 {
  grid-column: 27;
  grid-row: 19;
}
.hariWidalTest-ten-input-26 {
  grid-column: 28;
  grid-row: 19;
}
.hariWidalTest-ten-input-27 {
  grid-column: 29;
  grid-row: 19;
}
.hariWidalTest-ten-input-28 {
  grid-column: 30;
  grid-row: 19;
}
.hariWidalTest-ten-input-29 {
  grid-column: 31;
  grid-row: 19;
}
.hariWidalTest-ten-input-30 {
  grid-column: 32;
  grid-row: 19;
}
.hariWidalTest-ten-input-31 {
  grid-column: 33;
  grid-row: 19;
}
.hariWidalTest-ten-input-32 {
  grid-column: 34;
  grid-row: 19;
}
.hariWidalTest-ten-input-33 {
  grid-column: 35;
  grid-row: 19;
}
.hariWidalTest-ten-input-34 {
  grid-column: 36;
  grid-row: 19;
}
.hariWidalTest-border-10 {
  grid-column: 1 / span 36;
  grid-row: 20;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 1px;
}
/* --------------------------------------------------- */
.hariWidalTest-pad {
  width: 100%;
  min-width: 100px;
}
.hariWidalTest-text {
  padding-top: 1px;
}
.hariWidalTest-text textarea {
  height: 32px;
  border-radius: 4px;
  border: 1px solid rgb(210, 210, 210);
}
.hariWidalTest-border-text {
  grid-column: 1 / span 2;
  grid-row: 2;
  border-bottom: 1px solid #d9d9d9;
}
.hariWidalTest-pad {
  padding: 7px 0 0 5px;
}
.hariWidalTest-foot {
  margin-top: 20px;
  padding-left: 10px;
}
.hariWidalTest-input {
  height: 29px;
  width: 80%;
  border-radius: 4px;
}
.hariWidalTest-foot .add-image-input {
  background-color: white;
}
