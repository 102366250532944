.invoice-container .ant-table-thead > tr >th {
    color: #000000;
    background-color: white !important;
    border: 0.5px solid black;
    text-align: left;
    padding: 0px 0px 0px 5px;
    border-collapse: none;
    background-color: gray;

}

.invoice-container .ant-table-thead > tr:not(:last-child) > th[colspan] {
    border-bottom: 0;
    text-align: left;
}
.invoice-container .ant-table table {
     border-collapse: collapse;
     text-align: left;
     padding: 0px;
     
}
.invoice-container .ant-table-tbody > tr >td {
    border: 0.1px solid black;
    line-height: 01;
    padding: 10px 0px 10px 2px;
}
.invoice-container .ant-table-pagination.ant-pagination {
    display: none;
}