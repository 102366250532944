button {
    border-radius: 5px;
    font-size: 12px;
    font-weight: 700;
    border: none;
    color: white;
    background-color: #1E9902;
    cursor: pointer;
    padding: 6px 10px;
  }
  .ant-select{
    width: 100%;
  }