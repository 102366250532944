.login-container{
    background: #E7EFF3;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.login-details{
    display: flex;
    flex-direction: column;
    background: #ffffff;
    padding: 24px;
    border-radius: 10px;
}
.doctor-image-login{
    display: flex;
    justify-content: center;
}
.doctor-image-login img{
    width: 185px;
    height: 185px;
    border-radius: 50%;
}
.login-details input{
    width: 270px;
    margin: 20px 0px 0px 0px;
    height: 30px;
    border: 1px solid grey;
    border-radius: 5px;
    font-size: 13px;
    
    padding: 5px 30px;
}
.login-details button{
    width: 270px;
    height: 30px;
    background: #FFB600;
    color: #ffffff;
    border-radius: 10px;
    border: none;
    margin-top: 20px;
    font-weight: 600;
    letter-spacing: 1px;
}
.login-details input::placeholder{
    color:#CCC4CF;
    font-size: 12px;
}
.login-details input:focus{
  outline: 1px solid  #dcdfe6 ;
}
.login-icons{
    position: absolute;
    margin: 25px 0px 0px 5px;

}
.login-icons-1{
    position: absolute;
    margin: 27px 0px 0px 10px;
}