.medicine-master-container {
    width: 90%;
    margin: 0px 20px 80px 5%;
    background-color: white;

}

.medicine-master-container p {
    padding-left: 20px;
    color: #000000;
    font-size: 18px;
    font-weight: 700;
    padding-bottom: 10px;
}

.medicine-master-header {
    display: flex;
    justify-content: stretch;
    height: 55px;
    margin: 0 auto;
    margin-bottom: 5px;
    width: 100%;
    padding-left: 20px;
    gap: 5px;
}

.medicine-master-search-input {
    height: 35px;
    margin-right: 10px;
    width: 44vw;

}

.download-button-grey {
    background-color: #a6a6a6;
    height: 28px;
    margin-top: 4px;
    font-weight: 600;
    padding: 6px 4px;
}

.download-button-grey a {
    color: white;
}

.download-button-icon {
    width: 28px;
    padding: 5px;
    padding-bottom: 5px;
    padding-top: 0px !important;
}

.upload-button-grey {
    height: 28px;
    margin-top: 4px;
    font-weight: 600;
    padding: 6px 4px;
}

.update-button-grey {
    background-color: #a6a6a6;
    height: 28px;
    margin-top: 4px;
    font-weight: 600;
    padding: 6px 4px;
}

.update-button-icon {
    width: 26px;
    padding: 5px;
    padding-bottom: 5px;
    padding-top: 0px !important;
}

.active-button-green{   
    background-color: #a6a6a6; 
    margin-top: 4px;  
    padding: 5px 10px;

}

.deactive-button-black{
    background-color: black;
    margin-top: 4px;
    padding: 5px 10px;
}