
.view-right-width{
  width:60%
}

.view-vital-heading {
    display: flex;
}
.view-vital-section{
    width: 60%;
}
.view-vital-heading p{
    width: 50%;
}
.view-med-sec{
    width: 60%;
}
.view-medicine-name{
    width: 65%;
}
.view-medicine-headings{
    display: flex;
}
.view-medicine-days{
    width: 35%;
}
.view-opd-note{
    width: 59%;
}
.opd-reference-sec{
    margin-left: 40px;
}