.clinicalFindings-div {
    display: flex;
    margin: 20px;
    /* margin-bottom: 30px ; */
}

.clinicalFindings-6-div {
    width: 23%;
    line-height: 20px;
}

.clinicalFindings-6-div h6 {
    font-size: 13px;
    padding: 5px 10px;
    font-weight: 600;
}

.clinicalFindings-6-div p {
    padding-left:10px;
    font-weight: 500;
    font-weight: 12px;
    color: #19b3f0;
    line-height: 0px;

}



.clinical-findings-multiselect {
    width: 42vw;
}

.add-new-classname {
    cursor: pointer;
}