.MT-grid {
  display: grid;
  gap: 0;
  background-color: white;
  padding: 5px 10px 0 10px;
  font-size: 12px;
}
.MT-parent {
  display: flex;
}
.MT-left {
  width: 77%;
  margin: 15px 0 0 5px;
}
.MT-1 {
  grid-column: 1;
  grid-row: 1;
}
.MT-2 {
  grid-column: 2;
  grid-row: 1;
}
.MT-text {
  padding-top: 1px;
}
.MT-text textarea {
  height: 32px;
  border-radius: 4px;
  border: 1px solid rgb(210, 210, 210);
}
.MT-border {
  grid-column: 1 / span 2;
  grid-row: 2;
  border-bottom: 1px solid #d9d9d9;
}
.MT-border1 {
  grid-column: 1 / span 2;
  grid-row: 4;
  border-bottom: 1px solid #d9d9d9;
}
.MT-pad {
  padding: 7px 0 0 5px;
}
.MT-foot {
  margin-top: 20px;
  padding-left: 10px;
}
.MT-foot .add-image-input {
  background-color: white;
}
.MT-method {
  font-size: 12px;
  margin-top: 20px;
  padding-left: 10px;
  font-weight: 600;
}
.MT-method p {
  font-weight: 500;
}
