.template-div {
    display: flex;
    margin: 20px;
    margin-bottom: 10px !important;
}

.template-2-div {
    width: 23%;
    /* padding: 8px; */
}

.template-2-div h6 {
    font-size: 13px;
    padding: 5px 10px;
    font-weight: 600;
}

.template-antSelect {
    display: flex;
    margin-right: 5px;
    width: 21%;
}


.template-apply-btn {
    /* margin-top: 1px; */
    background-color: blue;
    padding: 6px 8px;

}

.template-delete-btn {
    margin-top: 0px;
    background-color: red;
    padding: 6px 8px;
}

.template-save-btn {
    margin-top: 0px;
    padding: 6px 8px;
    margin-left: 3px;
}

.template-multiselect {
    width: 15vw;
}

.template-input {
    width: 15vw;
    border: 1px solid rgb(128,128,128,0.4);
}
