.medicines-div {
    display: flex;
    margin: 20px;

}

.medicines-9-div {
    width: 25%;
    line-height: 20px;
}

.medicines-9-div h6 {
    font-size: 13px;
    padding: 5px 10px;
    font-weight: 600;
}

.med-dose-morning-timings {
    padding: 5px 10px;
    font-size: 11px;
    font-weight: 500;
    margin-right: 20px;

}

.edit-update-button {
    display: none;
}

.med-dose-desc-sec {
    display: flex;
    margin-top: 10px;
}

.med-count-text-add-btn {
    font-size: 13px;
    font-weight: 500;
    padding: 5px 15px;
    margin-left: 20px;
    margin-top: 10px;

}

.add-med-weeks .ant-checkbox+span {
    padding-right: 8px;
    padding-left: 8px;
    padding-bottom: 14px;
    font-size: 14px;
    font-weight: 500;
}

.add-med-weeks .ant-checkbox-wrapper+.ant-checkbox-wrapper {
    margin-left: 0px;
}


.med-dose-after-timing-sec {
    display: flex;
    margin-bottom: 10px;
}

.med-dose-after-timing-sec p {
    width: 15%;
}

.med-name-select-sec .ant-radio {
    display: none;
}

.med-name-select-sec .ant-radio-wrapper {
    background: #D9D9D9;
    color: #929292;
    border-radius: 3px;
}

.med-dose-timing-sec .ant-checkbox {
    display: none;
}

.med-dose-after-timing-sec .ant-checkbox {
    display: none;
}

.med-dose-timing-sec .ant-checkbox-checked+span {
    padding-bottom: 0px;
    font-size: 13px;
    background: #1E9902 !important;
    color: #ffffff !important;
    border-radius: 5px;

}

.med-dose-timing-sec .ant-checkbox+span {
    padding-bottom: 0px;
    font-size: 13px;
    background: #D9D9D9;
    color: #929292;
    border-radius: 5px;

}

.med-dose-after-timing-sec .ant-checkbox+span {
    padding-bottom: 0px;
    font-size: 13px;
    background: #D9D9D9;
    color: #929292;
    border-radius: 5px;

}

.med-dose-desc-sec h1 {
    width: 96%;
}

.med-note-ht {
    height: 100px;

}

.med-dose-desc-sec textarea.ant-input {
    max-width: 96%;
    height: 100px;
}

.warning-sec {
    margin: 10px 0px;
    width: 96%;

}

.od-bd-timings-section .ant-radio {
    display: none;
}

.od-bd-timings-section .ant-radio-wrapper {
    background: #D9D9D9;
    color: #929292;
    border-radius: 3px;
}

.od-bd-timings-section .ant-radio-wrapper-checked {
    background: #1E9902;
    color: #ffffff;
    border-radius: 3px;
}
.med-dose-timing-sec p {
    width: 20%;
}

.med-days-sec {
    width: 20%;
}

.medicines-9-div p {
    padding-left: 10px;
    font-weight: 500;
    font-weight: 12px;
    color: #19b3f0;
    line-height: 0px;
    cursor: pointer;

}

.medicine-headings {
    display: flex;
}

.medicine-headings p {
    font-size: 15px;
    width: 20%;
    margin-bottom: 8px;
}

.selected-med-sec {
    width: 75%;
}

.medicine-days {
    width: 17%;
}

.medicine-doseage {
    width: 13%;
}

.medicine-intake {
    width: 160px;
}

.action {
    width: 30px;
    height: 30px;
    background-color: red;
    border-radius: 15px;

}

.medicine-names-days {
    display: flex;
    margin-top: 10px;

}

.suggested-med-sec {
    height: 200px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin-left: 11%;
}

.suggested-med-sec::-webkit-scrollbar {
    display: none;
}

.medicine-names-days p {
    font-size: 13px;
    font-weight: 600;
    width: 20%;
}

.medicine-details-dropdowns {
    display: flex;
    margin-bottom: 40px;
}

.medicine-details-dropdowns-name p {
    margin: 0;
    font-size: 12px;
}

.medicine-details-dropdowns-days p {
    margin: 0;
    font-size: 12px;
}

.medicine-details-dropdowns-intake p {
    margin: 0;
    font-size: 12px;
}

.medicine-details-dropdowns-doseage p {
    margin: 0;
    font-size: 12px;
}

.all-medicines-add-div {
    margin-top: 20px
}

.medicines-add-btn {
    margin-top: 2px;
    padding: 2px 8px;
}

.medicines-multiselect1 {
    width: 14vw;
    margin: 0 5px 0 0;
}

.medicines-multiselect2 {
    width: 7vw;
    margin: 0 5px 0 0;
}

.medicines-multiselect3 {
    width: 12vw;
    margin: 0 5px 0 0;
}

.medicines-multiselect4 {
    width: 7vw;
    margin: 0 5px 0 0;
}

.add-medicine-search-title {
    display: flex;
}

.addmedicine-main-section {
    display: flex;
}

.addmedicine-left-section {
    width: 40%;
}

.addmedicine-right-section {
    width: 81%;
}



.v1 {
    background: #d9d9d9;
    height: auto;
    padding: 1px;
    margin-right: 10px;
    margin-left: 10px;
}

.v-line-search {
    width: 82%;
    padding-left: 7px;
}


.od-bd-timings-section {
    margin-bottom: 25px;
}

.med-dose-timing-sec {
    margin-bottom: 15px;
}

.med-dose-count-sec {
    display: flex;
    width: 15%;
}

.med-dose-type {
    margin: 0 10px;
}

.med-dose-count-sec p {
    /* margin: 5px 12px 5px 0px; */
    padding: 0px;
}

.morning-count {
    justify-content: center;
    display: flex;
    font-size: 16px;
    align-items: center;
    width: 75px;
}

.med-name-select-sec {
    display: flex;
    width: 100%;
    margin-bottom: 14px;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
}

.med-name-select-sec > div:nth-child(2) {
    width: 100%;
}
.med-name-select-sec > div:nth-child(1) {
    display: flex;
}

.med-weekly-course {
    display: flex;
    gap: 5px;
}

.route-continaer {
    padding-right: 10px;
}

.med-dose-timing-sec {
    display: flex;
    align-items: center;
}

.medicine-search-input {
    width: 50%;
}

.search-med-list p {
    background: #FC6661;
    border-radius: 10px;
    padding: 5px 10px;
    font-size: 11px;
    color: #ffffff;
    cursor: pointer;
    margin: 10px;
}

.search-med-list {
    display: flex;
    flex-wrap: wrap;
}

.selected-med-list {
    display: flex;
    flex-wrap: wrap;
}

.selected-med-list p {
    background: #909399;
    border-radius: 10px;
    padding: 5px 10px;
    color: #ffffff;
    font-size: 11px;
    margin: 10px;
    cursor: pointer;
}

.selected-med-crs {
    display: flex;
    align-items: center;
}

.selected-med-list img {
    width: 20px;
    height: 20px;
    margin-top: 10px;
    cursor: pointer;
}

.select_dropdown {
    display: none !important;
}

.select_dropdown_show {
    display: block !important;
}

.add-med-place-sec {
    width: 15%;
}

.opd-remarkstemplete-sec {
    display: flex;
    flex-wrap: wrap;
}

.opd-remarkstemplete-sec p {
    color: #1E9902;
    padding-right: 18px;
    cursor: pointer;
}

.opd-remarkstemplete-clear {
    color: #FC6661 !important;
    cursor: pointer;
}

.suggested-med-divider {
    border: 1px solid grey;
}

.suggested-generic span {
    width: 30px;
    height: 30px;
    background: #1E9902;
    cursor: pointer;
    padding: 8px;
    border-radius: 50%;
    transform: rotate(90deg);
    color: #ffffff;
}
.add-medicine-main-tab {
    min-height: 620px;
}
.add-medicine-main-tab .ant-tabs-tab {
    margin: 0 15px;
    border: none !important;
}

.add-medicine-tab-title {
    padding: 10px;
    font-weight: 800;
}

.custommodel-maincontainer {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9999;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, .4);
}

.custommodel-centercontainer {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    animation-name: CustomModelContainer;
    animation-duration: 0.3s;
    animation-timing-function: linear;
}

.custommodel-container {
    background: white;
    padding: 10px;
    border-radius: 5px;
    animation-name: example;
    animation-duration: 0.5s;
    z-index: 1;
    width: 400px;
    height: 500px;
    overflow-y: scroll;

}
.custommodel-container::-webkit-scrollbar {
    width: 10px;
}

.custommodel-container::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  .custommodel-container::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 10px;

  }
  
  .custommodel-container::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

.custommodel-container span {
    display: flex;
    justify-content: end;
    cursor: pointer;

}


.custommodel-container p {
    padding: 6px;
    cursor: pointer;
    background: #A6A9AD;
    margin-top: 7px;
    border-radius: 10px;
    color: #ffffffff;
}

.emptyspace-custom-container {
    background: transparent;
    width: 100%;
    height: 100%;
    position: fixed;
}

.med-name-select-sec .ant-radio-wrapper-checked {
    background: #1E9902;
    color: #ffffff;
    border-radius: 3px;
}

@keyframes CustomModelContainer {
    0% {
        height: 0px;
    }

    83% {
        height: 100%;
    }

    93% {
        height: 90%;
    }

    95% {
        height: 100%;
    }

    97% {
        height: 94%;
    }

    98% {
        height: 100%;
    }

    99% {
        height: 99%;
    }

    100% {
        height: 100%;
    }
}

@keyframes example {
    from {
        background-color: rgba(253, 253, 253, 0);
    }

    to {
        background-color: white;
    }
}

@media screen and (min-width:1280px) {
    .addmedicine-right-section {
        width: 68%;
    }

}

@media screen and (min-width:1880px) {
    .addmedicine-right-section {
        width: 80%;
    }

}


.custom-search-options-parent {
    position: relative;
}

.custom-search-options {
    max-height: 50vh;
    overflow-y: scroll;
    position: absolute;
    z-index: 4;
    background: #fff;
    width: 100%;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}
.custom-search-options::-webkit-scrollbar {
    width: 10px;
}

.custom-search-options::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  .custom-search-options::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 10px;

  }
  
  .custom-search-options::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }



.custom-search-option-item {
    padding: 10px 15px;
}

.custom-search-option-item:hover {
    padding: 10px 15px;
    background: #c4c4c41a;
}

.cross-medicine {
    top: 7px;
    display: block !important;
    position: absolute;
    left: 92%;
    z-index: 1;
    background: white;
    padding: 3px;
    border-radius: 8px;
    color: #929292;
    cursor: pointer;
}

.medicine-input {
    position: relative;
}

.medicine-input input {
    width: 100%;
    border: 1px solid #d9d9d9;
    padding: 5px;
    font-size: 14px;
    border-radius: 3px;
    position: relative;
}

.medicine-input input:focus {
    border: 1px solid #19b3f0;
    outline: none;
    box-shadow: 2px #19b3f0;
    transition: all 0.2s;
}

.custom-search-option-item span {
    font-size: 10px;
    margin: 0px;
    color: rgb(158, 158, 158);
}

.opd-drops-input {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    gap: 5px;
}

.opd-drops-input > div {
    width: 100%;
}

.lens-advised {
    display: flex;
    justify-content: start;
    gap: 5px;
    align-items: center;
}

.glass-prescription-values td{
    height: 34px;
}
.weekly-course-array {
    width: 45%;
}
.weekly-course-array-div {
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
}