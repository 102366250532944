.vitality-main {
  display: flex;
}
.vitality-container {
  width: 80%;
}
.vitality-grid-container {
  display: grid;
  background-color: #ffff;
  text-align: left;
  padding: 24px 10px 0 16px;
  font-size: 12px;
}
.vitality-main textarea {
  height: 32px;
  border-radius: 4px;
}
.vital1 {
  grid-column: 1;
  grid-row: 1;
}
.vital-text1 {
  grid-column: 3 / span 5;
  grid-row: 1;
}
.vital2 {
  grid-column: 1;
  grid-row: 3;
}
.vital-text2 {
  grid-column: 3 / span 5;
}
.vital3 {
  grid-column: 1;
  grid-row: 5;
}
.vital-text3 {
  grid-column: 3 / span 5;
}
.vital4 {
  grid-column: 1;
  grid-row: 7;
}
.vital-text4 {
  grid-column: 3 / span 5;
}
.vital5 {
  grid-column: 1;
  grid-row: 9;
}
.vital-text5 {
  grid-column: 3 / span 5;
}
.vital6 {
  grid-column: 1;
  grid-row: 11;
}
.vital-text6 {
  grid-column: 3 / span 5;
}
