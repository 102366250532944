.hariSemenAnalysis-parent {
    display: flex;
}

.hariSemenAnalysis-left {
    width: 77%;
    margin-top: 20px;
    margin-left: 5px;
}

.hariSemenAnalysis-grid {
    display: grid;
    background-color: white;
    padding: 5px 10px 0 10px;
    font-size: 12px;
}

.hariSemenAnalysis-1 {
    grid-column: 1;
    grid-row: 1
}

.hariSemenAnalysis-2 {
    grid-column: 2;
    grid-row: 1
}

.hariSemenAnalysis-3 {
    grid-column: 3;
    grid-row: 1
}

.hariSemenAnalysis-4 {
    grid-column: 4;
    grid-row: 1
}

.hariSemenAnalysis-5 {
    grid-column: 5;
    grid-row: 1
}

.hariSemenAnalysis-border-1 {
    grid-column: 1 / span 5;
    grid-row: 2;
    border-bottom: 1px solid #d9d9d9;
}

.hariSemenAnalysis-6 {
    grid-column: 1;
    grid-row: 3
}

.hariSemenAnalysis-7 {
    grid-column: 2;
    grid-row: 3
}

.hariSemenAnalysis-8 {
    grid-column: 3;
    grid-row: 3
}

.hariSemenAnalysis-9 {
    grid-column: 4;
    grid-row: 3
}

.hariSemenAnalysis-10 {
    grid-column: 5;
    grid-row: 3
}


.hariSemenAnalysis-border-2 {
    grid-column: 1 / span 5;
    grid-row: 4;
    border-bottom: 1px solid #d9d9d9;
}

.hariSemenAnalysis-11 {
    grid-column: 1;
    grid-row: 5
}

.hariSemenAnalysis-12 {
    grid-column: 2;
    grid-row: 5
}

.hariSemenAnalysis-13 {
    grid-column: 3;
    grid-row: 5
}

.hariSemenAnalysis-14 {
    grid-column: 4;
    grid-row: 5
}

.hariSemenAnalysis-15 {
    grid-column: 5;
    grid-row: 5
}

.hariSemenAnalysis-border-3 {
    grid-column: 1 / span 5;
    grid-row: 6;
    border-bottom: 1px solid #d9d9d9;
}

.hariSemenAnalysis-border-4 {
    grid-column: 1 / span 5;
    grid-row: 8;
    border-bottom: 1px solid #d9d9d9;
}

.hariSemenAnalysis-border-5 {
    grid-column: 1 / span 5;
    grid-row: 10;
    border-bottom: 1px solid #d9d9d9;
}

.hariSemenAnalysis-border-6 {
    grid-column: 1 / span 5;
    grid-row: 12;
    border-bottom: 1px solid #d9d9d9;
}

.hariSemenAnalysis-border-7 {
    grid-column: 1 / span 5;
    grid-row: 14;
    border-bottom: 1px solid #d9d9d9;
}

.hariSemenAnalysis-border-8 {
    grid-column: 1 / span 5;
    grid-row: 16;
    border-bottom: 1px solid #d9d9d9;
}

.hariSemenAnalysis-border-9 {
    grid-column: 1 / span 5;
    grid-row: 18;
    border-bottom: 1px solid #d9d9d9;
}

.hariSemenAnalysis-border-10 {
    grid-column: 1 / span 5;
    grid-row: 20;
    border-bottom: 1px solid #d9d9d9;
}

.hariSemenAnalysis-border-11 {
    grid-column: 1 / span 5;
    grid-row: 22;
    border-bottom: 1px solid #d9d9d9;
}

.hariSemenAnalysis-border-12 {
    grid-column: 1 / span 5;
    grid-row: 24;
    border-bottom: 1px solid #d9d9d9;
}

.hariSemenAnalysis-border-13 {
    grid-column: 1 / span 5;
    grid-row: 26;
    border-bottom: 1px solid #d9d9d9;
}

.hariSemenAnalysis-border-14 {
    grid-column: 1 / span 5;
    grid-row: 28;
    border-bottom: 1px solid #d9d9d9;
}

.hariSemenAnalysis-border-15 {
    grid-column: 1 / span 5;
    grid-row: 30;
    border-bottom: 1px solid #d9d9d9;
}
.hariSemenAnalysis-border-16 {
    grid-column: 1 / span 5;
    grid-row: 32;
    border-bottom: 1px solid #d9d9d9;
}
.hariSemenAnalysis-border-17 {
    grid-column: 1 / span 5;
    grid-row: 34;
    border-bottom: 1px solid #d9d9d9;
}
.hariSemenAnalysis-border-18 {
    grid-column: 1 / span 5;
    grid-row: 36;
    border-bottom: 1px solid #d9d9d9;
}
.hariSemenAnalysis-border-19 {
    grid-column: 1 / span 5;
    grid-row: 38;
    border-bottom: 1px solid #d9d9d9;
}

.hariSemenAnalysis-input {
    width: 120px;
    border-radius: 5px;
    margin-top: 1px;
}

.hariSemenAnalysis-pad {
    padding-top: 7px;
}

.hariSemenAnalysis-foot {
    margin-top: 20px;
    padding-left: 10px;
}

.hariSemenAnalysis-foot .add-image-input {
    background-color: white;
}

.hariSemenAnalysis-p {
    padding: 15px 10px;
    font-size: 12px;
    line-height: 015px;
    font-weight: 400;
}

.hariSemenAnalysis-p h4 {
    font-weight: 800;
    padding-bottom: 5px;
}