.patient-updateRisks-div {
    width: 16%;
    background-color: white;
}

.profile-container {
    width: 85%;
}

.pause-active-div .anticon[tabindex] {
    margin-top: 2px;
}


.active-visit-container {
    padding-left: 8px;
    display: flex;
    background-color: white;
}

.pause {
    padding-right: 5px;
    font-size: 20px;
}

.active-visit {
    font-size: 12px;
    color: green;
    font-weight: 600;
    margin-right: 10px;
}

.pause-active-div {
    width: 12%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.report-btns-div {
    display: flex;
    gap: 5px;
}

.appointment-details {
    display: flex;
}

.active {
    background-color: #fdc132;
    padding: 6px 7px;
    font-weight: 600;
    color: black;
}

.unactive {
    background-color: #cce5eb;
    font-weight: 600;
    color: black;
    padding: 6px 7px;
}

/* .history-btn {
    padding: 6px 7px;
    background-color:#cce5eb;
    color: black;
    font-weight: 600;
} */

.opd-save-btn {
    background-color: lightgrey;
    color: black;
    padding: 1px 8px;

}

.checkout-btn {
    background-color: lightgrey;
    color: black;
    padding: 1px 8px;
}

@media screen and (min-width:1880px) {

    .print-invoice-prescription {
        display: flex;
        background: white;
        justify-content: space-between;

    }
}

@media screen and (min-width:2560px) {
    .report-btns-div {
        width: 15%;
    }
}