.profile-style-image{
    width: 193px;
    height: 100px;
    margin-top: 20px;
}
.profile-style-trash{
    background: #EF4036;
    padding: 5px;
    font-size: 13px;
    border-radius: 50%;
    margin-top: 50px;
    margin-left: 10px;
    cursor: pointer;
}
.update-style-btn-sec{
text-align: right;
margin-top: 17px;

}
.each-profile-style-record{
    display: flex;
    margin-top: 10px;
}

.each-profile-style-record p{
    width: 40%;
    text-align: right;
    padding-right: 30px;
    padding-top: 3px;
    font-weight: 500;
}
.each-profile-style-record h4{
    padding-top: 3px;
    font-size: 15px;
}
.profile-style-img-sec{
    width: 60%;
    display: flex;
}