:root{
    --variablename: default;
}


.ant-layout-sider {
    position: fixed !important;
    margin-top: 70px !important;
    min-width: 12% !important;
    max-width: 12% !important;
    width: 12% !important;
    height: 100vh;
    background:var(--variablename) !important;
    transition: all 0.2s;
    color: #FFFFFF;
    cursor: pointer;

}

.ant-layout{
    background:#E8F0F2!important;
    height: auto;
    min-height: auto;
}

.sider-section img{
width: 23px;
height: 23px;
}
.sider-section p{
    padding: 10px 0px 10px 8px;
    font-size: 14px;
    font-weight: 600;
    margin: 0px;
    border-bottom: 1px solid #000000;

}
.sider-section span{
    padding:0px 5px 0px 0px;
}
.sider-section p{
    color: #ffffff;
}

.active-background{
    background: #FDC132 ;
    color: #000000;
    
}

@media screen and (min-width: 1700px){
    .sider-section p{
        font-size: 15px;
    }
    
}

