.report-right-main {
  width: 94%;
  display: flex;
  flex-direction: column;
  gap: 11px;
  margin-left: 10px;
  margin-top: 32px;
}

.report-btn-right-sec {
  margin-bottom: 20px;
}

.report-btn-right-sec Button {
  background-color: gray;
  border-radius: 15px;
  padding: 6px 16px 4px 20px;
}

.report-right-main img {
  padding-right: 5px;
}

.report-right-main Textarea {
  background-color: #edf6f7;
}

.report-save-btn-right Button {
  border-radius: 15px;
  padding: 6px 16px 4px 20px;
}

.report-switch-right-sec .ant-switch {
  width: 25%;
  background-color: red;
}

.test-report-input {
  border-radius: 5px;
  width: 100%;
}

.tesinput {
  border-radius: 50px;
}

.test-report-input Textarea {
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  outline: none;
  width: 99%;
}

.test-report-input Textarea::placeholder {
  padding-left: 10px;
  color: #d9d9d9;
}

.test-report-input Textarea:focus {
  border-color: gray;
}

.report-btn-right-save {
  background-color: #1e9902;
  /* background-color: gray; */
  border-radius: 15px;
  padding: 6px 16px 4px 34px;
}

.test-report-select {
  margin-bottom: 10px;
}
