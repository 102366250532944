.rbi-main-content {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: unset !important;
  position: fixed;
  /* background-color: #1a8e6b !important; */

  @media (min-width: 768px) {
    height: 100%;
    display: flex;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .helpline {
    text-align: center;
    @media (min-width: 768px) {
      padding-top: 60px;
    }
  }

  .login-content {
    background-color: white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    position: fixed;
    bottom: 0;
    width: 100%;
    @media (min-width: 768px) {
      width: 390px;
      margin: 0 auto;
      border-radius: 20px;
    }

    .login-text {
      padding: 25px;
      .login-input {
        padding-top: 32px;
        p {
          padding-bottom: 6px;
        }
        .login-phone-input-style {
          padding: 10px;
          background-color: #f2f2f2;
        }
      }
      .login-btn-secction {
        text-align: center;
        padding-top: 30px;
      }
      .login-btn {
        width: 100%;
        background-color: orange;
      }
      .login-btn:hover {
        color: black;
      }
    }
  }
}

.otp-input {
  text-align: center;
  padding-top: 25px;
}

.error-message {
  color: orangered;
}
.resend-otp {
  color: #1a8e6b;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}
