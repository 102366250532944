.invoice-container.from-to-date .ant-space-item {
    margin-top: -7px;
}

.table-col {
    white-space: nowrap;
}

.table-col1 {
    width: 20%;
    white-space: nowrap;
}