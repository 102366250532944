:root{
    --variablename: default;
}

*{
    box-sizing: border-box;
}

.admin-tab-list {
    /* padding: 10px 20px; */
    font-size: 15px;
    font-weight: 600;
}
.admin-tab-list p{
    line-height: 15px;
}
/* .admin-tabs .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list{
    margin-left: 80px;
} */
.ant-tabs .ant-tabs-tab {
    padding-left: 0;
    padding-right: 0;
}
.ant-tabs .ant-tabs{
    width: 100%;
}
/* .last-tab{
    margin-right: 150px;
} */
.ant-tabs-nav-operations {
    display: none !important;
}

.admin-header-left {
    display: flex;
    justify-content: space-between;
    background: var(--variablename);
}

.admin-head h1 {
    font-size: 18px;
    color: white;
    margin: 18px 0px 11px 32px;
    font-weight: 600;
    letter-spacing: 1px;
}

.admin-header-right {
    display: flex;
    justify-content: space-between;
    width: 14%;
    padding: 10px 0px;
    border-left: 0.1px solid rgb(177, 173, 173);
}

.admin-name {
    display: flex;
}


.arrow-img img{
    width: 11px;
}

.admin-align {
    color: #fff;
    margin-top: 5px;
    line-height: 10px;
    letter-spacing: 1px;
}

.admin-align h4 {
    color: #fff;
}

.admin-align p {
    font-size: 10px;
}
.admin-tabs .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap{
    justify-content: center;
}
.admin-tabs .ant-tabs-nav {
    width: 100% !important;
  }
  
.admin-tabs .ant-tabs-tab {
    display: block; 
    flex: 1;
    text-align: center; 
  }
  
.admin-tabs .ant-tabs-nav > div:nth-of-type(1) {
    display: unset !important;
    width: 100% !important;
  }
.admin-tabs .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list{
    cursor: pointer;
    height: 60px;
}
.arrow-img {
    width: 20%;
    padding-top: 9px;
    padding-right: 21px;
}
.ant-dropdown .ant-dropdown-placement-bottomRight{
    width: 100%;
}
.admin-dropdown{
    width: 100%;
}
.ant-dropdown-menu {
    position: relative;
    width: 100%;
    margin-left: 20px;
    margin-top: 14px;
    padding: 4px 0;
    text-align: center;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 2px;
    outline: none;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
}

.ant-dropdown-menu-item-icon {
    font-size: 18px;
    margin-right: -10px;
}
.dropdown-user-name{
    text-align: left;
    margin-left: 2px;
}