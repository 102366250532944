.diagnostic-heading {
    border-bottom: 2px solid #d2d2d2;
    padding-top: 0px;
    padding-left: 10px;
}

.diagnostics-left-section {
    background: rgb(255, 255, 255);
    width: 20%; 
    margin-right: 7px;
}

.diagnostics-right-section{
    width: 80%;
}


.diagnostics-left-right-main-section {
    display: flex;
    /* margin: 5px; */
    margin: 0px 5px 5px 0px;
    height: 134px;
    
}
.diagnostic-heading-title {
    font-size: 22px;
    font-weight: 700;
    margin-top: 7px;
}
.diagnostic-tab-section .ant-tabs>.ant-tabs-nav{
    height: 34px;
}