.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}

.glass-prescription-editable-row > td.ant-table-cell{
  padding: 0 !important;
}

.editable-cell-value-wrap {
  padding-right: 24px;
  min-height: 34px;
  display: flex;
  align-items: center;
}

.glass-prescription-action-div {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.glass-prescription-title {
  display: flex;
  justify-content: space-around;
}

.glass-prescription-title h3 {
  margin: 0;
}

.pres-instruction-container {
  margin-top: 30px;
}

.pres-instruction-table td {
  padding: 10px 10px 0;
}

.pre-instruction-input-row, .weekly-course-table-input-row {
  height: 75px;
}
.glass-prescription-lens-advised {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}