.apollo-video-conference-div {
    background-color: #00809c;
}

.apollo-video-conference-div h1 {
    color: white;
    font-size: 20px;
    padding: 8px 0px 8px 10px;
}

.patient-room-join {
    width: 98.5%;
    margin: 10px;
    height: 43vw;
    background-color: white;
    border-radius: 6px;
    /* border: 15px solid white; */
}

.new-join-message {
    width: 100%;
    padding: 5px;
    background-color: #d1e5ea;
}

.video-icon {
    display: flex;
    justify-content:right;
    align-items: flex-end;
    position:absolute;
    width: auto;
    height: auto;
    background-color: yellow;
    top: 0;
    right: 0;
    border: 50%;
    margin-top: 30px;
    margin-right: 20px;
    border-radius: 50%;
}

/* .video {
    font-size: 30px;
    padding: 10px;
} */