:root{
    --variablename: default;
}

.emptydiv {
    padding-left: 11px;

}

.all-tabs {
    display: flex;
    column-gap: 21px;
    padding-left: 101px;
}


.invoice-summary {
    display: flex;
    border-right: 1px solid #B4B6B6;
    padding-top: 10px;
    padding-right: 19px;
}

.invoice-summary-icon {
    padding-right: 5px;
    font-size: 25px;
}

.summary-title {

    font-size: 12px;
    font-weight: 500;
    padding-top: 10px;

}

.invoice-details {
    display: flex;
    border-right: 1px solid #B4B6B6;
    padding-top: 10px;
    padding-right: 19px;
}

.invoice-detail-title {

    font-size: 12px;
    font-weight: 500;
    padding-top: 10px;

}

.invoice-detail-icon {
    padding-right: 5px;
    font-size: 25px;
}

.prescription-stats {
    display: flex;
    border-right: 1px solid #B4B6B6;
    padding-top: 10px;
    padding-right: 19px;
}

.prescription-stat-title {

    font-size: 12px;
    font-weight: 500;
    padding-top: 10px;

}

.invoice-stat-icon {
    padding-right: 5px;
    font-size: 25px;
}

.prescription-analysis-title {

    font-size: 12px;
    font-weight: 500;
    padding-top: 10px;
}

.prescription-analysis {
    display: flex;
    border-right: 1px solid #B4B6B6;
    padding-top: 10px;
    padding-right: 19px;
}

.invoice-analysis-icon {
    padding-right: 5px;
    font-size: 25px;
}

.visit-analysis {
    display: flex;
    border-right: 1px solid #B4B6B6;
    padding-top: 10px;
    padding-right: 19px;
}

.visit-analysis-title {

    font-size: 12px;
    font-weight: 500;
    padding-top: 10px;

}

.invoice-visit-icon {
    padding-right: 5px;
    font-size: 25px;
}

.daily-collection {
    display: flex;
    padding-top: 10px;
    padding-right: 19px;
}




.ant-tabs-nav-list {
    transform: translate(0px, 0px);
}

.fa-file-lines {
    font-size: 25px;
    padding-top: 4px;
    margin-right: 10px;
}

.fa-file.svg-inline--fa {
    font-size: 25px;
    padding-top: 4px;
    margin-right: 10px;
}

.fa-user.svg-inline--fa {
    font-size: 25px;
    padding-top: 4px;
    margin-right: 10px;
}

.fa-prescription-bottle-medical {

    font-size: 25px;
    padding-top: 4px;
    margin-right: 10px;
}


.fa-chart-bar {
    font-size: 25px;
    padding-top: 4px;
    margin-right: 10px;
}

.fa-indian-rupee-sign {
    font-size: 25px;
    padding-top: 4px;
    margin-right: 10px;
}



.ant-tabs-tab.active {
    color: white;
}
.ant-tabs-tab + .ant-tabs-tab {
    margin: 0px;
}

.ant-tabs-tab.ant-tabs-tab-active {
    border-bottom: 2px red;
    background: var(--variablename) ;
    color: white !important;

}
.ant-tabs-ink-bar{
    background: none !important;
}

.ant-tabs-tab.ant-tabs-tab-active svg {
    fill: white;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #ffffff;
    text-shadow: 0 0 0.25px currentcolor;
}

.ant-tabs-tab-btn {
    color: black;
}

.ant-tabs-tab {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 0px;
    font-size: 12px;
    padding-right: 20px;
    background: transparent;
    border-right: 2px solid rgb(214, 214, 214) !important;
    outline: none;
    cursor: pointer;
}

.ant-tabs-tab:nth-of-type() {
    position: relative;
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    padding-left: 10px;
    border-right: unset;
    outline: none;
    cursor: pointer;
}


.ant-tabs-nav {
    margin: auto;
}

.six-tab {
    border-right: none;
}

.ant-tabs-tab+.ant-tabs-tab {
    margin: 0px;
}


.ant-tabs-nav {
    margin: auto;
}


.ant-tabs>.ant-tabs-nav,
.ant-tabs>div>.ant-tabs-nav {
    position: relative;
    display: flex;
    flex: none;
    height: 53px;

}

.ant-tabs-tab.last-child {
    border-right: none;
}

.rc-tabs-0-tab-6.ant-tabs-tab-btn:last-child {
    border-right: none;
}

.daily-collection-icon-title-tab {
    display: flex;
    align-items: center;
    justify-content: center;
}

.daily-collection-title {
    font-size: 12px;
    font-weight: 500;
    padding-top: 5px;
    padding-left: 10px;
}

.visit-analysis-icon-title-tab {
    display: flex;
    align-items: center;
    justify-content: center;

}



.visit-analysis-tab-title {
    padding-top: 5px;
    padding-left: 10px;
    font-weight: 500;
}

.invoice-summary-icon-title-tab {
    display: flex;
    align-items: center;
    justify-content: center;
}

.analytic-tab-title {
    font-weight: 800;
    padding-top: 6px;
    padding-left: 10px;

}

.invoice-detail-icon-tab {
    display: flex;
    align-items: center;
    justify-content: center;
}

.invoice-detail-icon-tab img{
    height: 28px;
    width: 28px;
}

.invoice-detail-title {
    font-size: 12px;
    font-weight: 500;
    margin-left: 10px;
    padding-top: 6px;
}

.prescription-analysis-icon-tab {
    display: flex;
    align-items: center;
    justify-content: center;
}

.prescription-icon-title {
    font-weight: 500;
    padding-top: 7px;
    margin-left: 10px;

}

.patient-stats-icon-tab {
    display: flex;
    align-items: center;
    justify-content: center;
}

.patient-stats-icon-title {

    padding-top: 3px;
    margin-left: 10px;
    font-weight: 500;
}


.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #ffffff !important;
    background: #3DA3B8;
    text-shadow: 0 0 0.25px currentcolor;
}

.ant-tabs-nav-list {

    transform: translate(0px, 0px);



}

.mytabs {

    margin: auto;
}

.ant-tabs-tab.ant-tabs-tab-active:nth-child(6) {
    border-bottom: none;
    background: var(--variablename);
    color: white;

}
.analytic-tab .ant-tabs-tab {
    display: block; 
    flex: 1;
    text-align: center;
    height: 53px;
  }
  
.analytic-tab .ant-tabs-nav > div:nth-of-type(1) {
    display: unset !important;
    width: 100% !important;
  }
.analytic-tab .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list{
    cursor: pointer;
    text-align: center;
}