.abdomenAndPelvic-grid {
  display: grid;
  gap: 0;
  background-color: white;
  padding: 5px 10px 0 10px;
  font-size: 12px;
}
.abdomenAndPelvic-parent {
  display: flex;
  width: 100%;
}
.abdomenAndPelvic-left {
  width: 77%;
  margin: 15px 0 0 5px;
}
.abdomenAndPelvic-1 {
  grid-column: 1;
  grid-row: 1;
}
.abdomenAndPelvic-2 {
  grid-column: 2;
  grid-row: 1;
}
.abdomenAndPelvic-text {
  padding-top: 1px;
}
.abdomenAndPelvic-text textarea {
  height: 32px;
  border-radius: 4px;
  border: 1px solid rgb(210, 210, 210);
}
.abdomenAndPelvic-border {
  grid-column: 1 / span 2;
  grid-row: 2;
  border-bottom: 1px solid #d9d9d9;
}
.abdomenAndPelvic-border1 {
  grid-column: 1 / span 2;
  grid-row: 4;
  border-bottom: 1px solid #d9d9d9;
}
.abdomenAndPelvic-border2 {
  grid-column: 1 / span 2;
  grid-row: 6;
  border-bottom: 1px solid #d9d9d9;
}
.abdomenAndPelvic-border3 {
  grid-column: 1 / span 2;
  grid-row: 8;
  border-bottom: 1px solid #d9d9d9;
}
.abdomenAndPelvic-border4 {
  grid-column: 1 / span 2;
  grid-row: 10;
  border-bottom: 1px solid #d9d9d9;
}
.abdomenAndPelvic-border5 {
  grid-column: 1 / span 2;
  grid-row: 12;
  border-bottom: 1px solid #d9d9d9;
}
.abdomenAndPelvic-border6 {
  grid-column: 1 / span 2;
  grid-row: 14;
  border-bottom: 1px solid #d9d9d9;
}
.abdomenAndPelvic-border7 {
  grid-column: 1 / span 2;
  grid-row: 16;
  border-bottom: 1px solid #d9d9d9;
}
.abdomenAndPelvic-border8 {
  grid-column: 1 / span 2;
  grid-row: 18;
  border-bottom: 1px solid #d9d9d9;
}
.abdomenAndPelvic-border9 {
  grid-column: 1 / span 2;
  grid-row: 20;
  border-bottom: 1px solid #d9d9d9;
}
.abdomenAndPelvic-pad {
  padding: 7px 0 0 5px;
}
.abdomenAndPelvic-foot {
  margin-top: 20px;
  padding-left: 10px;
}
.abdomenAndPelvic-foot .add-image-input {
  background-color: white;
}
