.number-button-1 {
    width: 30px;
    height: 30px;
    background-color: #cccccc;
    border-radius: 18px;
    border: none;
    font-size: 14px;
    font-weight: 600;
    color: black;
    padding: 0;
    margin: 0;
}
.number-button{
    width: 45px;
    height: 36px;
    background-color: #cccccc;
    border-radius: 18px;
    border: none;
    font-size: 17px;
    font-weight: 600;
    color: black;
    padding: 0;
    margin: 0;

}