.diagnosis-type-title {
    font-weight: 600;
}

.pres-diagnosis-type-table {
    display: flex;
    padding: 15px 20px 0px 0px;
    margin: 5px;
    flex-direction: column;
}

.diagnosis-section {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid black;
}

