.invoice-det-top-sec {
    background-color: white;
    display: flex;
    margin: 10px 2%;
    align-items: center;

}

.invoice-det-main-sec {
    width: 100%;
    padding: 10px 15px;
}

.invoice-right-sec {
    display: flex;
    width: 30%;
    justify-content: center;

}

.prescript-consultant-one .ant-picker-suffix {
    display: none;
}

.prescript-consultant-one {
    width: 33%;
    padding-right: 10px;
}

.prescript-consultant-section-two {
    width: 38%;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
}

.count-btn-main-sec {
    display: flex;
}

.prescript-consultant-one-btn {
    width: 33%;
    padding-right: 10px;
}

.inv-sub-container-one {
    display: flex;
    justify-content: space-between;
}

.inv-sub-container-two {
    padding-top: 20px;
}

.inv-dtl-pres-select-date {
    width: 100%;
}

.inv-dtl-prescript-consultant {
    width: 100%;
}

.inv-dtl-range {
    display: flex;
}

.inv-logo {
    width: 40px;
    height: 40px;
    margin: 20px 8px;
}

.inv-sub-container-three {
    margin-top: -4px;
    display: flex;
    justify-content: space-between;
    width: 50%;
}

.inv-service {
    color: gray;
    margin-left: 20px;
}

.inv-det-pres-generate-btn {
    padding: 5px 25px;
}

.invoice-container .ant-table-wrapper {
    max-width: 100%;
    padding: 0px 6px 0px 6px;
}

.invoice-container .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td{
    padding: 16px 0px;
}

.inv-breakdown {
    margin-left: 20px;
}