.iron-parent {
  display: flex;
}
.iron-left {
  width: 80%;
  margin-left: 5px;
  margin-top: 20px;
}
.iron-grid {
  display: grid;
  background-color: white;
  padding: 5px 10px 0 10px;
  font-size: 12px;
}
.clinical-blood {
  display: grid;
  background-color: white;
  padding: 5px 10px 0 10px;
  font-size: 12px;
  height: 450px;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: rgb(218, 218, 221) rgb(207, 204, 204);
}
.clinical-blood::-webkit-scrollbar {
  width: 10px;
}
.clinical-blood::-webkit-scrollbar-track {
  background: rgb(255, 255, 255);
}
.clinical-blood::-webkit-scrollbar-thumb {
  background-color: rgb(214, 214, 218);
  border-radius: 20px;
  border: 3px solid rgb(255, 255, 255);
}
.iron1 {
  grid-column: 1;
  grid-row: 1;
  width: 150px;
}
.iron2 {
  grid-column: 2;
  grid-row: 1;
}
.iron3 {
  grid-column: 3;
  grid-row: 1;
}
.iron4 {
  grid-column: 4;
  grid-row: 1;
}
.iron5 {
  grid-column: 5;
  grid-row: 1;
}
.iron6 {
  grid-column: 6;
  grid-row: 1;
}
.iron7 {
  grid-column: 1 / span 2;
  grid-row: 47;
}
.iron8 {
  grid-column: 3 / span 4;
  grid-row: 47;
}
.iron9 {
  grid-column: 1 / span 2;
  grid-row: 49;
}
.iron10 {
  grid-column: 3 / span 4;
  grid-row: 49;
}
.iron11 {
  grid-column: 1 / span 2;
  grid-row: 51;
}
.iron12 {
  grid-column: 3 / span 4;
  grid-row: 51;
}
.iron13 {
  grid-column: 1 / span 2;
  grid-row: 53;
}
.iron14 {
  grid-column: 3 / span 4;
  grid-row: 53;
}
.iron-input {
  width: 120px;
  border-radius: 5px;
  margin-top: 2px;
}
.iron-border {
  grid-column: 1 / span 6;
  grid-row: 2;
  border-bottom: 1px solid #d9d9d9;
}
.iron-border1 {
  grid-column: 1 / span 6;
  grid-row: 4;
  border-bottom: 1px solid #d9d9d9;
}
.iron-border2 {
  grid-column: 1 / span 6;
  grid-row: 6;
  border-bottom: 1px solid #d9d9d9;
}
.iron-border3 {
  grid-column: 1 / span 6;
  grid-row: 8;
  border-bottom: 1px solid #d9d9d9;
}
.iron-border4 {
  grid-column: 1 / span 6;
  grid-row: 10;
  border-bottom: 1px solid #d9d9d9;
}
.iron-border5 {
  grid-column: 1 / span 6;
  grid-row: 12;
  border-bottom: 1px solid #d9d9d9;
}
.iron-border6 {
  grid-column: 1 / span 6;
  grid-row: 14;
  border-bottom: 1px solid #d9d9d9;
}
.iron-border7 {
  grid-column: 1 / span 6;
  grid-row: 16;
  border-bottom: 1px solid #d9d9d9;
}
.iron-border8 {
  grid-column: 1 / span 6;
  grid-row: 18;
  border-bottom: 1px solid #d9d9d9;
}
.iron-border9 {
  grid-column: 1 / span 6;
  grid-row: 20;
  border-bottom: 1px solid #d9d9d9;
}
.iron-border10 {
  grid-column: 1 / span 6;
  grid-row: 22;
  border-bottom: 1px solid #d9d9d9;
}
.iron-border11 {
  grid-column: 1 / span 6;
  grid-row: 24;
  border-bottom: 1px solid #d9d9d9;
}
.iron-border12 {
  grid-column: 1 / span 6;
  grid-row: 26;
  border-bottom: 1px solid #d9d9d9;
}
.iron-border13 {
  grid-column: 1 / span 6;
  grid-row: 28;
  border-bottom: 1px solid #d9d9d9;
}
.iron-border14 {
  grid-column: 1 / span 6;
  grid-row: 30;
  border-bottom: 1px solid #d9d9d9;
}
.iron-border15 {
  grid-column: 1 / span 6;
  grid-row: 32;
  border-bottom: 1px solid #d9d9d9;
}
.iron-border16 {
  grid-column: 1 / span 6;
  grid-row: 34;
  border-bottom: 1px solid #d9d9d9;
}
.iron-border17 {
  grid-column: 1 / span 6;
  grid-row: 36;
  border-bottom: 1px solid #d9d9d9;
}
.iron-border18 {
  grid-column: 1 / span 6;
  grid-row: 38;
  border-bottom: 1px solid #d9d9d9;
}
.iron-border19 {
  grid-column: 1 / span 6;
  grid-row: 40;
  border-bottom: 1px solid #d9d9d9;
}
.iron-border20 {
  grid-column: 1 / span 6;
  grid-row: 42;
  border-bottom: 1px solid #d9d9d9;
}
.iron-border21 {
  grid-column: 1 / span 6;
  grid-row: 44;
  border-bottom: 1px solid #d9d9d9;
}
.iron-border22 {
  grid-column: 1 / span 6;
  grid-row: 46;
  border-bottom: 1px solid #d9d9d9;
}
.iron-border23 {
  grid-column: 1 / span 6;
  grid-row: 48;
  border-bottom: 1px solid #d9d9d9;
}
.iron-border24 {
  grid-column: 1 / span 6;
  grid-row: 50;
  border-bottom: 1px solid #d9d9d9;
}
.iron-border25 {
  grid-column: 1 / span 6;
  grid-row: 52;
  border-bottom: 1px solid #d9d9d9;
}
.iron-border26 {
  grid-column: 1 / span 6;
  grid-row: 54;
  border-bottom: 1px solid #d9d9d9;
}
.h-pad {
  padding: 7px 5px;
}
.iron-foot {
  margin-top: 20px;
  padding-left: 5px;
}
.iron-foot .add-image-input {
  background-color: white;
}
.irn-method {
  margin-top: 20px;
  padding-left: 10px;
  font-weight: 600;
}
.irn-method P {
  font-weight: 400;
}
.irn-method li {
  margin-left: 25px;
  font-weight: 400;
  font-size: 12px;
}
.clinic1 {
  width: 180px;
}
.in-pad {
  margin-top: 7px;
}
.haemo-w {
  width: 160px;
}
.g-pad {
  margin-top: 15px;
}
