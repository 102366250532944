.stats-container {
    display: flex;
    align-items: center;
    margin: 20px 0 0 50px;
}

.prescriptionanalysis-top-left-section {
    background: white;
    width: 70%;
}

.invoice-logo {
    width: 96%;
    margin: 10px 2%;

}

.prescription-top-left-sec {
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 7% 23px;
    margin: 10px 2% 10px 2%;
}

.prescript-consultant {
    width: 30%;
}

.prescript-date {
    margin-left: 2%;
}

.prescription-select-export {
    display: flex;
    width: 94%;
    margin-left: 6%;
    margin-bottom: 5px;
}

.export-pres-icon {
    display: flex;
    padding-top: 0px;
    padding-left: 20px;
}

.pres-diagnosis-first-sect {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.pres-diagnosis-second-section {
    background: white;
    width: 22%;
    padding: 10px;
}


.pres-lab-section {
    background: white;
    width: 22%;
    padding: 10px;
    display: flex;
    justify-content: space-between;
}

.pre-procedure-section {
    background: white;
    width: 21%;
    padding: 10px;
    display: flex;
    justify-content: space-between;
}

.pre-bottom-main-section {
    display: flex;
    column-gap: 10px;
    margin: 1%;
}

.pres-diagnosis-second-section {
    display: flex;
    justify-content: space-between;

}

.diagnosis-search {
    margin-right: 8px;
    font-size: 25px;
    font-weight: 300;
    color: #959595;
}

.from-to-date .anticon{
    display: none;
}
.analytics-calender{
    position: absolute;
    margin-left: -20px;
    margin-top: 9px;
}

.diagnosis-add-btn {
    background: #00AEEF;
    border-radius: 50%;
    color: white;
    padding: 4px;
    clear: both;
    width: 13px;
}

.consultant-title {
    margin-bottom: 0px;
    font-size: 11px;
    font-weight: 700;
}

.pre-main-first-section {
    width: 39%;
    background: white;
}

.pres-generate-btn {
    font-size: 12px;
    padding: 5px 25px;
    position: relative;
    bottom: 5px;
}


.diagnosis-search-add {
    display: flex;
    column-gap: 10px;
}

.pre-diagnosis-main-second-section {
    background: white;
    width: 23%;
}

.pre-diagnosis-type-section {
    width: 22%;
    background: white;

}

.pres-diagnosis-type-first-sect {
    display: flex;
    justify-content: space-between;
    padding-left: 5px;

}

.pre-main-lab-section {
    width: 20%;
    background: white;

}

.pres-diagnosis-procedure-sect {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    font-weight: 600;
}

.pre-main-procedure-section {
    background: white;
    width: 20%;
}

.pres-procedure-first-table {
    padding: 20px;
    padding-right: 30px;
    padding-top: 10px;
}

.pre-diagnosis-type-section {
    padding: 10px;
}

.procedure-medicine-type {
    margin-bottom: 0px;
}

.procedure-count {
    margin-bottom: 0px;
}

.diagnosis-type-name {
    margin-bottom: 0px;
    
}

.pres-diagnosis-type-count {
    margin-bottom: 0px;
}

.pres-diagnosis-type-table {
    display: flex;
    justify-content: space-between;
    padding: 15px 20px 0px 0px;
    margin: 5px;
}

.diagnosis-type-section {
    width: 100%;
}

.diagnosis-lab-names {
    margin-bottom: 0px;
    width: 100%;
}

.pres-lab {
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    padding-right: 30px;
    padding-top: 10px;
}

.pres-diagnosis-lab-num {
    margin-bottom: 0px;
   
}

.diagnosis-lab-title {
    width: 100%;
}

.procedure-medicine-section {
    width: 100%;
}

.date-range {
    margin: 30px 0 0 20px;
    font-size: 14px;
}