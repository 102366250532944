*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}
.user-container {
    background-color: #F0F8FA;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 70px ;
}
/* 1px solid #cccc */

.user-title{
   margin-right: 472px;
   font-weight: 600;
}
.signup-button1{
    height: 38px; 
    width: 90px; 
    font-size: 15px;
}
.signup-button2{
    height: 38px;
    width: 90px;
    font-size: 15px; 
    background-color: #ccc;

}
.user-subcontainer {
    background: #ffffff;
    padding: 30px 130px;
    margin-right: 22px;
}
.user-record {
    display: flex;
    align-items: center;
    font-weight: 600;

}

.user-record p {
    width: 33%;
    text-align: right;
    padding-right: 20px;
    color: #B5B5B5;
    font-size: 12px;
    font-weight: 500;
    
}
.user-record h4 {
    width: 70%;
  
}
.role{
    width: 70%;
    margin-bottom: 8px;
}
