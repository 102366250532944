.entro-parent {
  display: flex;
}
.entro-left {
  width: 75%;
  margin-left: 5px;
  margin-top: 15px;
}
.entro-grid {
  display: grid;
  gap: 0 40px;
  background-color: white;
  padding: 5px 10px 0 10px;
  font-size: 12px;
  border-bottom: 1px solid #d9d9d9;
}
.entro1 {
  grid-column: 1;
  grid-row: 1;
}
.entro2 {
  grid-column: 2;
  grid-row: 1;
}
.entro-pad {
  padding: 5px;
}
.entro-grid textarea {
  height: 32px;
  border-radius: 4px;
  border: 1px solid rgb(210, 210, 210);
}
.entro-foot {
  padding-top: 30px;
  padding-left: 10px;
}
.entro-foot .add-image-input {
  background-color: white;
}
