
.search-input {
    height: 35px;
    width: 58%;
    margin: 10px 0px 5px 10px;
}
.adv-search{
    margin-left: 10px;
    color: gray;
}
.adv-search-division{
    display: flex;
}
.search-division{
    display: flex;
}
.search-select{
    width: 154px;
    margin: 5px 0 0 10px;
}
.search-input1 {
    height: 32px;
    width: 94%;
    margin: 5px 0 0 7px;
}
.search-container {
    background-color: white;
    margin: 21px;
    width: 94%;
}
.search-patient-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-top: 10px;
}
.patient-heading{
    padding-left: 10px;
    border-bottom: 2px solid #F0F8FA;
}

.buttons-division {
    display: flex;
    flex-direction: column;
    width: 40%;
}
.button-edit{
    background-color: lightgray;
    color: black;
    padding: 2px 6px;
    
}
.button-select{
    background-color: green;
    color: white;
    padding: 2px 6px;
    margin-bottom: 5px;
}
.button-profile{
    background-color: lightgray;
    color: black;
    padding: 2px 6px;
}
.name-phone {
    display: flex;
    justify-content: space-between;
    width: 193px;
}

.search-patient-header{
    margin-left: 10px;
}
.print-image{
    margin-top: 35px;
}
.print-table-img{
    width:160px;
    height:120px;
    border: 4px solid gray;
}
.appointment-modal .ant-select {
    width: 18%;
    margin-right: 10px;
}
.walkin-single-select{
    width: 20%;
}
.appoint-walk-in {
    width: 30%;
}
.close-icon{
    float: right;
    margin: -33px 126px 0 0;
    background-color: gray;
    color: white;
    padding: 2px 6px;
}
.appointment-location-select {
    width: 88% !important;
}
@media screen and (min-width:1111px) {
    .search-input1 {
        height: 32px;
        width: 133%;
        margin: 5px 0 0 7px;
    }
    .buttons-division {
        display: flex;
        flex-direction: column;
        width: 24%;
    }
    .name-phone {
        display: flex;
        justify-content: space-between;
        width: 304px;
    }
  
}