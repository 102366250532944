.MalariaParasite-grid {
  display: grid;
  gap: 0;
  background-color: white;
  padding: 5px 10px 0 10px;
  font-size: 12px;
}
.MalariaParasite-parent {
  display: flex;
}
.MalariaParasite-left {
  width: 77%;
  margin: 15px 0 0 5px;
}
.MalariaParasite-1 {
  grid-column: 1;
  grid-row: 1;
}
.MalariaParasite-2 {
  grid-column: 2;
  grid-row: 1;
}
.MalariaParasite-text {
  padding-top: 1px;
}
.MalariaParasite-text textarea {
  height: 32px;
  border-radius: 4px;
  border: 1px solid rgb(210, 210, 210);
}
.MalariaParasite-border {
  grid-column: 1 / span 2;
  grid-row: 2;
  border-bottom: 1px solid #d9d9d9;
}
.MalariaParasite-border1 {
  grid-column: 1 / span 2;
  grid-row: 4;
  border-bottom: 1px solid #d9d9d9;
}
.MalariaParasite-border2 {
  grid-column: 1 / span 2;
  grid-row: 6;
  border-bottom: 1px solid #d9d9d9;
}
.MalariaParasite-pad {
  padding: 7px 0 0 5px;
}
.MalariaParasite-foot {
  margin-top: 20px;
  padding-left: 10px;
}
.MalariaParasite-foot .add-image-input {
  background-color: white;
}
.MalariaParasite-method {
  font-size: 12px;
  margin-top: 20px;
  padding-left: 10px;
  font-weight: 600;
}
.MalariaParasite-method p {
  font-weight: 500;
}
