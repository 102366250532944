.doNotUseTwo-grid {
  display: grid;
  gap: 0;
  background-color: white;
  padding: 5px 10px 0 10px;
  font-size: 12px;
}
.doNotUseTwo-parent {
  display: flex;
}
.doNotUseTwo-left {
  width: 77%;
  margin: 15px 0 0 5px;
}
.doNotUseTwo-1 {
  grid-column: 1;
  grid-row: 1;
}
.doNotUseTwo-2 {
  grid-column: 2;
  grid-row: 1;
}
.doNotUseTwo-text {
  padding-top: 1px;
}
.doNotUseTwo-text textarea {
  height: 32px;
  border-radius: 4px;
  border: 1px solid rgb(210, 210, 210);
}
.doNotUseTwo-border {
  grid-column: 1 / span 2;
  grid-row: 2;
  border-bottom: 1px solid #d9d9d9;
}
.doNotUseTwo-border1 {
  grid-column: 1 / span 2;
  grid-row: 4;
  border-bottom: 1px solid #d9d9d9;
}
.doNotUseTwo-border2 {
  grid-column: 1 / span 2;
  grid-row: 6;
  border-bottom: 1px solid #d9d9d9;
}
.doNotUseTwo-border3 {
  grid-column: 1 / span 2;
  grid-row: 8;
  border-bottom: 1px solid #d9d9d9;
}
.doNotUseTwo-border4 {
  grid-column: 1 / span 2;
  grid-row: 10;
  border-bottom: 1px solid #d9d9d9;
}
.doNotUseTwo-border5 {
  grid-column: 1 / span 2;
  grid-row: 12;
  border-bottom: 1px solid #d9d9d9;
}
.doNotUseTwo-border6 {
  grid-column: 1 / span 2;
  grid-row: 14;
  border-bottom: 1px solid #d9d9d9;
}
.doNotUseTwo-border7 {
  grid-column: 1 / span 2;
  grid-row: 16;
  border-bottom: 1px solid #d9d9d9;
}
.doNotUseTwo-border8 {
  grid-column: 1 / span 2;
  grid-row: 18;
  border-bottom: 1px solid #d9d9d9;
}
.doNotUseTwo-border9 {
  grid-column: 1 / span 2;
  grid-row: 20;
  border-bottom: 1px solid #d9d9d9;
}
.doNotUseTwo-border10 {
  grid-column: 1 / span 2;
  grid-row: 22;
  border-bottom: 1px solid #d9d9d9;
}
.doNotUseTwo-border11 {
  grid-column: 1 / span 2;
  grid-row: 24;
  border-bottom: 1px solid #d9d9d9;
}
.doNotUseTwo-border12 {
  grid-column: 1 / span 2;
  grid-row: 26;
  border-bottom: 1px solid #d9d9d9;
}
.doNotUseTwo-border13 {
  grid-column: 1 / span 2;
  grid-row: 28;
  border-bottom: 1px solid #d9d9d9;
}
.doNotUseTwo-border14 {
  grid-column: 1 / span 2;
  grid-row: 30;
  border-bottom: 1px solid #d9d9d9;
}
.doNotUseTwo-border15 {
  grid-column: 1 / span 2;
  grid-row: 32;
  border-bottom: 1px solid #d9d9d9;
}
.doNotUseTwo-border16 {
  grid-column: 1 / span 2;
  grid-row: 34;
  border-bottom: 1px solid #d9d9d9;
}
.doNotUseTwo-pad {
  padding: 7px 0 0 5px;
}
.doNotUseTwo-foot {
  margin-top: 20px;
  padding-left: 10px;
}
.doNotUseTwo-foot .add-image-input {
  background-color: white;
}

.doNotUseTwo-no-data {
  padding: 25px;
}
