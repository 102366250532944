.ant-multiselect-wd{
    width: 700px !important;
}



.analytics-name {
    margin-top:10px;
    margin-left: 20px;
    font-size: 18px;
    font-weight: 700;
}
.analytic-title {
    font-size: 18px;
    font-weight: 900;
    text-shadow: 0 0 black;
    margin-left: 10px;
   
    padding-top: 3px;
}

.analytic-title-bg {
    background: white;
    padding: 10px;
    display: flex;
    justify-content: space-between;
}


.prescriptionanalysisexport-section {
    display: flex;
    width: 36%;
    padding-top: 4px;
   
}
.export-tit {
    width: 40%;
    font-size: 12px;
    margin-left: 0px;
    padding-left: 10px;
    padding-top: 10px;
}
.pre-select-file{
    width: 90%;
}

