@import url("https://fonts.googleapis.com/css2?family=Abel&family=Poppins:wght@400;600;700&family=Source+Sans+Pro:wght@600&display=swap");

* {
  font-family: "poppins";
}

.calls-header {
  width: 100%;
  height: 100%;
  background-color: white;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  padding: 20px;
}

.calls-container2 {
  margin-top: 10px;
}

.calls-container3 {
  display: flex;
  justify-content: space-between;

  width: 90%;
  margin: auto;
}

.calls-container4 {
  display: flex;
  justify-content: space-between;
  width: 50%;
  font-weight: 600;
  font-size: 12px;
}

.calls-container5 {
  display: flex;
  flex-direction: row;
}

.calls-input {
  margin: 20px;
  padding: 20px;
}

.calls-table {
  width: 90%;
  margin: auto;
  background: #ffffff;
}

/* modal css */

.switch {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: green;
}

input:focus + .slider {
  box-shadow: 0 0 1px green;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(45px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.ant-modal-footer {
  display: none;
}

.ant-modal-header {
  border-bottom: 0px;
}

.call-modal-one .ant-modal-close-x {
  padding: 0px;
}

.total-call-text {
  font-size: 15px;
}

.follow-up-text {
  font-size: 15px;
}
.calls-modal {
  margin-top: -35px;
  padding-left: 35px;
}

.ant-modal-title {
  font-size: 20px;
  font-weight: 700;
}

.calls-inp {
  width: 100%;
  display: inline-flex;
  padding: 10px;
}

.calls-input-label {
  font-weight: 600;
  margin-right: 20px;
  margin-top: 5px;
}

.toggle-label {
  padding: 10px;
  font-weight: 900;
  margin-right: 65px;
}

.toggle-label1 {
  padding: 10px;
  font-weight: 900;
  margin-right: 100px;
  margin-top: 20px;
}

.calls-n {
  margin-left: -25px;
  margin-top: 30px;
  font-weight: 500;
  font-size: 20px;
}

.calls-y {
  margin-left: 10px;
  font-weight: 500;
  font-size: 20px;
}

.calls-modal-btn {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 5px;
}

.totalcalls-section {
  display: flex;
}

.totalfollowups-section {
  display: flex;
}

.addnewbtn {
  margin-top: 7px;
}

.calls-table-delete {
  padding: 4px;
  height: 22px;
  width: 22px;
  background: #ef4036;
  border-radius: 50%;
  cursor: pointer;
}

.calls-table-edit {
  padding: 5px;

  background: #00aeef;
  border-radius: 50%;
  margin-left: 7px;
  cursor: pointer;
}

.totalcal-icon {
  padding-right: 10px;
}

.totalfollowup-secion {
  padding-right: 10px;
}

.totalfollowup-icon {
  padding-right: 10px;
}

.calls-input1 {
  height: 35px;
  margin-right: 20px;
  margin-top: 5px;
  width: 200px;
}

.calls-modal-inp1 {
  border: 1px solid #cccc;
  height: 35px;
  margin-left: 10px;
  width: 360px;
}
.calls-modal-inp2 {
  border: 1px solid #cccc;
  height: 35px;
  margin-left: 25px;
  width: 360px;
}
.calls-modal-inp3 {
  border: 1px solid #cccc;
  height: 35px;
  margin-left: 90px;
  width: 360px;
}
.calls-modal-inp4 {
  border: 1px solid #cccc;
  height: 35px;
  margin-left: 43px;
  width: 360px;
}
.calls-modal-inp5 {
  border: 1px solid #cccc;
  height: 35px;
  margin-left: 83px;
  width: 360px;
}
.calls-modal-btn1 {
  height: 37px;
  width: 70px;
  font-size: 15px;
}
.calls-modal-btn2 {
  height: 37px;
  width: 90px;
  font-size: 15px;
  background-color: #ccc;
}
.calls-model-img {
  margin-left: 10px;
  width: 35px;
  height: 31px;
}
.calls-date {
  height: 35px;
  margin-left: 40px;
  width: 360px;
}
.marg {
  margin-right: 100px;
}
.calls-inp .ant-select {
  width: 361px;
}

.search-table-flex {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  padding-left: 8px;
  padding-right: 8px;
}

.calls-table {
  width: 100%;
  /* margin: auto; */
  background: #ffffff;
  /* animation-delay: 2s;   */
  /* animation-property: margin-ri/ght; */
}
.calls-shrink-table {
  width: 100%;
  max-width: 70%;
  /* margin: auto; */
  background: #ffffff;
  /* transition: width 5s ease-in-out 3s; */
  animation-duration: 4s;

}
.search-result {
  display: block;
  max-height: 70vh;
  max-width: 30%;
  overflow: scroll;
  overflow-x: hidden;
  width: 100%;
  background: white;
  animation-duration: 4s;

}

.no-search {
  display: none;
}

.search-pop {
  border-bottom: 1px solid black;
  padding-bottom: 20px;
  padding-left: 10px;
}

.search-call-history {
  margin-top: 7px;
  height: 32px;
}

.result-heading {
  height: 40px;
  color: #ffffff;
  background-color: #3da3b8;
  padding-left: 10px;
  padding-top: 7px;
}

.result-heading h3 {
  color: #ffffff;
}
.appointment-inp textarea{
  width: 88%;
}