.apt-main {
  display: flex;
}
.apt-container {
  width: 80%;
}
.apt-grid-container {
  display: grid;
  background-color: #ffff;
  text-align: left;
  padding: 24px 10px 0 16px;
  font-size: 12px;
}
.aptinputs {
  width: 120px;
  height: 29px;
  border-radius: 4px;
}
