@import "~antd/dist/antd.css";

:root {
  --variablename: default;
}

/* @media print {

  html,
  body {
    height: 100vh;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
} */

@media print {

  html,
  body {
    border: 1px solid white;
    height: 98% !important;
    page-break-after: avoid;
    page-break-before: avoid;
  }
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif !important;
  background: #F0F8FA !important;
  margin: 0px !important;
  padding: 0px !important;
}

.main-section {
  position: relative;
  margin-top: 72px;
  top: 30%;
  left: 12%;
  width: 88%;
}

.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
  color: black !important;
}

.ant-input[disabled] {
  color: black !important;
}

/* @media(min-width:1600px) and (max-width:2560px){
  .main-section{
  position: relative;
  min-width: 1450px;
    max-width: 1450px;
    margin: 0px 10%;
    margin-top: 72px;
  }
<<<<<<< HEAD
} */


p {
  margin-bottom: 0px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}


/* .edit-medicine-modal-div .anticon svg {
  display: none;
} */

.ant-modal-header {
  background: #F0F8FA !important;
  border-radius: 15px !important;
}

.ant-modal-content {
  background: #F0F8FA !important;
  border: 4px solid var(--variablename) !important;
  border-radius: 15px !important;
}