.tumarMarker-parent {
  display: flex;
}
.tumarMarker-left {
  width: 77%;
  margin-top: 20px;
  margin-left: 5px;
}
.tumarMarker-grid {
  display: grid;
  background-color: white;
  padding: 5px 10px 0 10px;
  font-size: 12px;
  border-bottom: 1px solid #d9d9d9;
}
.tumarMarker-1 {
  grid-column: 1;
  grid-row: 1;
}
.tumarMarker-2 {
  grid-column: 2;
  grid-row: 1;
}

.tumarMarker-3 {
  grid-column: 3;
  grid-row: 1;
}
.tumarMarker-4 {
  grid-column: 4;
  grid-row: 1;
}
.tumarMarker-5 {
  grid-column: 5;
  grid-row: 1;
}

.tumarMarker-input {
  width: 120px;
  border-radius: 5px;
}
.tumarMarker-pad {
  padding-top: 7px;
}
.tumarMarker-foot {
  margin-top: 20px;
  padding-left: 10px;
}
.tumarMarker-foot .add-image-input {
  background-color: white;
}
.tumarMarker-p {
  padding: 15px 10px;
  font-size: 12px;
  line-height: 015px;
  /* font-weight: 700; */
}

.tumarMarker-table {
  width: 40%;
}

.tumarMarker-table td {
  border: 1px solid black;
  padding: 6px;
}

.tumarMaker-note-section ol {
  margin-left: 35px;
}

.tumarMaker-clinical-section ul {
  margin-left: 35px;
}
