.immunology-parent {
  display: flex;
}
.immunology-left {
  width: 77%;
  margin-top: 20px;
  margin-left: 5px;
}
.immunology-grid {
  display: grid;
  background-color: white;
  padding: 5px 10px 0 10px;
  font-size: 12px;
}

.immunology-text {
  padding-top: 1px;
}
.immunology-text textarea {
  height: 32px;
  border-radius: 4px;
  border: 1px solid rgb(210, 210, 210);
}
.immunology-1 {
  grid-column: 1;
  grid-row: 1;
}
.immunology-2 {
  grid-column: 3 / span 3;
  grid-row: 1;
}
.immunology-border-1 {
  grid-column: 1 / span 5;
  grid-row: 2;
  border-bottom: 1px solid #d9d9d9;
}

.immunology-3 {
  grid-column: 1;
  grid-row: 3;
}
.immunology-4 {
  grid-column: 3 / span 3;
  grid-row: 3;
}

.immunology-border-2 {
  grid-column: 1 / span 5;
  grid-row: 4;
  border-bottom: 1px solid #d9d9d9;
}

.immunology-5 {
  grid-column: 1;
  grid-row: 5;
}
.immunology-6 {
  grid-column: 2;
  grid-row: 5;
}
.immunology-7 {
  grid-column: 3;
  grid-row: 5;
}

.immunology-8 {
  grid-column: 4;
  grid-row: 5;
}
.immunology-9 {
  grid-column: 5;
  grid-row: 5;
}
/* ------------------------------------------- */

.immunology-10 {
  grid-column: 1;
  grid-row: 7;
}
.immunology-11 {
  grid-column: 3 / span 3;
  grid-row: 7;
}

.immunology-12 {
  grid-column: 1;
  grid-row: 9;
}
.immunology-13 {
  grid-column: 3 / span 3;
  grid-row: 9;
}

.immunology-14 {
  grid-column: 1;
  grid-row: 11;
}
.immunology-15 {
  grid-column: 3 / span 3;
  grid-row: 11;
}

.immunology-16 {
  grid-column: 1;
  grid-row: 13;
}
.immunology-17 {
  grid-column: 3 / span 3;
  grid-row: 13;
}

.immunology-18 {
  grid-column: 1;
  grid-row: 15;
}
.immunology-19 {
  grid-column: 3 / span 3;
  grid-row: 15;
}

.immunology-20 {
  grid-column: 1;
  grid-row: 17;
}
.immunology-21 {
  grid-column: 3 / span 3;
  grid-row: 17;
}

.immunology-22 {
  grid-column: 1;
  grid-row: 19;
}
.immunology-23 {
  grid-column: 3 / span 3;
  grid-row: 19;
}

.immunology-24 {
  grid-column: 1;
  grid-row: 21;
}
.immunology-25 {
  grid-column: 3 / span 3;
  grid-row: 21;
}

.immunology-26 {
  grid-column: 1;
  grid-row: 23;
}
.immunology-27 {
  grid-column: 3 / span 3;
  grid-row: 23;
}

.immunology-28 {
  grid-column: 1;
  grid-row: 25;
}
.immunology-29 {
  grid-column: 3 / span 3;
  grid-row: 25;
}

.immunology-border-3 {
  grid-column: 1 / span 5;
  grid-row: 6;
  border-bottom: 1px solid #d9d9d9;
}

.immunology-border-4 {
  grid-column: 1 / span 5;
  grid-row: 8;
  border-bottom: 1px solid #d9d9d9;
}

.immunology-border-5 {
  grid-column: 1 / span 5;
  grid-row: 10;
  border-bottom: 1px solid #d9d9d9;
}
.immunology-border-6 {
  grid-column: 1 / span 5;
  grid-row: 12;
  border-bottom: 1px solid #d9d9d9;
}
.immunology-border-7 {
  grid-column: 1 / span 5;
  grid-row: 14;
  border-bottom: 1px solid #d9d9d9;
}
.immunology-border-8 {
  grid-column: 1 / span 5;
  grid-row: 16;
  border-bottom: 1px solid #d9d9d9;
}
.immunology-border-9 {
  grid-column: 1 / span 5;
  grid-row: 18;
  border-bottom: 1px solid #d9d9d9;
}
.immunology-border-10 {
  grid-column: 1 / span 5;
  grid-row: 20;
  border-bottom: 1px solid #d9d9d9;
}
.immunology-border-11 {
  grid-column: 1 / span 5;
  grid-row: 22;
  border-bottom: 1px solid #d9d9d9;
}

.immunology-border-12 {
  grid-column: 1 / span 5;
  grid-row: 24;
  border-bottom: 1px solid #d9d9d9;
}
.immunology-border-13 {
  grid-column: 1 / span 5;
  grid-row: 26;
  border-bottom: 1px solid #d9d9d9;
}
.immunology-input {
  width: 120px;
  border-radius: 5px;
  margin-top: 1px;
}
.immunology-pad {
  padding-top: 7px;
}
.immunology-foot {
  margin-top: 20px;
  padding-left: 10px;
}
.immunology-foot .add-image-input {
  background-color: white;
}
.immunology-p {
  padding: 15px 10px;
  font-size: 12px;
  line-height: 015px;
  font-weight: 400;
}
.immunology-p h4 {
  font-weight: 800;
  padding-bottom: 5px;
}

.immunology-text {
  padding-top: 1px;
}
.immunology textarea {
  height: 32px;
  border-radius: 4px;
  border: 1px solid rgb(210, 210, 210);
}
