.iui-main {
  display: flex;
}

.iui-container {
  width: 80%;
}

.iui-grid-container {
  display: grid;
  column-gap: 10px;
  background-color: #ffff;
  text-align: left;
  padding: 24px 10px 0 16px;
  font-size: 12px;
  overflow-y: scroll;
}

.iui-main textarea {
  height: 32px;
  border-radius: 4px;
}
.iui-inputs {
  height: 29px;
  border-radius: 4px;
}

.iui-1 {
  grid-column: 1;
  grid-row: 1;
}

.iui-text1 {
  grid-column: 4 / span 4;
  grid-row: 1;
}

.iui-border1 {
  grid-column: 1 / span 7;
  grid-row: 2;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.iui-2 {
  grid-column: 1;
  grid-row: 3;
}
.iui-text2 {
  grid-column: 4 / span 4;
  grid-row: 3;
}
.iui-border2 {
  grid-column: 1 / span 7;
  grid-row: 4;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.iui-3 {
  grid-column: 1;
  grid-row: 5;
}
.iui-text3 {
  grid-column: 4 / span 4;
  grid-row: 5;
}
.iui-border3 {
  grid-column: 1 / span 7;
  grid-row: 6;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.iui-4 {
  grid-column: 1;
  grid-row: 7;
}
.iui-text4 {
  grid-column: 4 / span 4;
  grid-row: 7;
}
.iui-border4 {
  grid-column: 1 / span 7;
  grid-row: 8;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.iui-5 {
  grid-column: 1;
  grid-row: 9;
}
.iui-text5 {
  grid-column: 4 / span 4;
  grid-row: 9;
}
.iui-border5 {
  grid-column: 1 / span 7;
  grid-row: 10;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.iui-6 {
  grid-column: 1;
  grid-row: 11;
}
.iui-text6 {
  grid-column: 4 / span 4;
  grid-row: 11;
}
.iui-border6 {
  grid-column: 1 / span 7;
  grid-row: 12;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.iui-7 {
  grid-column: 1;
  grid-row: 13;
}
.iui-text7 {
  grid-column: 4 / span 4;
  grid-row: 13;
}
.iui-border7 {
  grid-column: 1 / span 7;
  grid-row: 14;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.iui-8 {
  grid-column: 1;
  grid-row: 15;
}
.iui-text8 {
  grid-column: 4 / span 4;
  grid-row: 15;
}
.iui-border8 {
  grid-column: 1 / span 7;
  grid-row: 16;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.iui-9 {
  grid-column: 3;
  grid-row: 17;
}
.iui-10 {
  grid-column: 5;
  grid-row: 17;
}
.iui-border9 {
  grid-column: 1 / span 7;
  grid-row: 18;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.iui-11 {
  grid-column: 1;
  grid-row: 19;
}
.iui-12 {
  grid-column: 3 / span 2;
  grid-row: 19;
}
.iui-13 {
  grid-column: 5 / span 3;
  grid-row: 19;
}
.iui-border10 {
  grid-column: 1 / span 7;
  grid-row: 20;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.iui-14 {
  grid-column: 1;
  grid-row: 21;
}
.iui-15 {
  grid-column: 3 / span 2;
  grid-row: 21;
}
.iui-16 {
  grid-column: 5 / span 3;
  grid-row: 21;
}
.iui-border11 {
  grid-column: 1 / span 7;
  grid-row: 22;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.iui-17 {
  grid-column: 1;
  grid-row: 23;
}
.iui-18 {
  grid-column: 3 / span 2;
  grid-row: 23;
}
.iui-19 {
  grid-column: 5 / span 3;
  grid-row: 23;
}
.iui-border12 {
  grid-column: 1 / span 7;
  grid-row: 24;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.iui-20 {
  grid-column: 1;
  grid-row: 25;
}
.iui-21 {
  grid-column: 3 / span 2;
  grid-row: 25;
}
.iui-22 {
  grid-column: 5 / span 3;
  grid-row: 25;
}
.iui-border13 {
  grid-column: 1 / span 7;
  grid-row: 26;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.iui-23 {
  grid-column: 1;
  grid-row: 27;
}
.iui-24 {
  grid-column: 3 / span 2;
  grid-row: 27;
}
.iui-25 {
  grid-column: 5 / span 3;
  grid-row: 27;
}
.iui-border14 {
  grid-column: 1 / span 7;
  grid-row: 28;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.iui-26 {
  grid-column: 1;
  grid-row: 29;
}
.iui-27 {
  grid-column: 3 / span 2;
  grid-row: 29;
}
.iui-28 {
  grid-column: 5 / span 3;
  grid-row: 29;
}
.iui-border15 {
  grid-column: 1 / span 7;
  grid-row: 30;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.iui-29 {
  grid-column: 1;
  grid-row: 31;
}
.iui-30 {
  grid-column: 3 / span 2;
  grid-row: 31;
}
.iui-31 {
  grid-column: 5 / span 3;
  grid-row: 31;
}
.iui-border16 {
  grid-column: 1 / span 7;
  grid-row: 32;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.iui-32 {
  grid-column: 1;
  grid-row: 33;
}
.iui-33 {
  grid-column: 3 / span 2;
  grid-row: 33;
}
.iui-34 {
  grid-column: 5 / span 3;
  grid-row: 33;
}
.iui-border17 {
  grid-column: 1 / span 7;
  grid-row: 34;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.iui-35 {
  grid-column: 1;
  grid-row: 35;
}
.iui-36 {
  grid-column: 3 / span 2;
  grid-row: 35;
}
.iui-37 {
  grid-column: 5 / span 3;
  grid-row: 35;
}
.iui-border18 {
  grid-column: 1 / span 7;
  grid-row: 36;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
