.sputum-grid {
  display: grid;
  gap: 0;
  background-color: white;
  padding: 5px 10px 0 10px;
  font-size: 12px;
}
.sputum-parent {
  display: flex;
}
.sputum-left {
  width: 77%;
  margin: 15px 0 0 5px;
}
.sputum1 {
  grid-column: 1;
  grid-row: 1;
}
.sputum2 {
  grid-column: 2;
  grid-row: 1;
}
.sputum-text textarea {
  height: 32px;
  border-radius: 4px;
  border: 1px solid rgb(210, 210, 210);
  margin-top: 2px;
}
.sputum-border {
  grid-column: 1 / span 2;
  grid-row: 2;
  border-bottom: 1px solid #d9d9d9;
}
.sputum-border2 {
  grid-column: 1 / span 2;
  grid-row: 4;
  border-bottom: 1px solid #d9d9d9;
}
.sputum-border3 {
  grid-column: 1 / span 2;
  grid-row: 6;
  border-bottom: 1px solid #d9d9d9;
}
.sputum-border4 {
  grid-column: 1 / span 2;
  grid-row: 8;
  border-bottom: 1px solid #d9d9d9;
}
.sputum-border5 {
  grid-column: 1 / span 2;
  grid-row: 10;
  border-bottom: 1px solid #d9d9d9;
}
.sputum-border6 {
  grid-column: 1 / span 2;
  grid-row: 12;
  border-bottom: 1px solid #d9d9d9;
}
.sputum-border7 {
  grid-column: 1 / span 2;
  grid-row: 14;
  border-bottom: 1px solid #d9d9d9;
}

.sputum-pad {
  padding: 7px 0 0 5px;
}
.sputum-foot {
  margin-top: 20px;
  padding-left: 10px;
}
.sputum-foot .add-image-input {
  background-color: white;
}
.sputum-method {
  margin-top: 20px;
  padding-left: 10px;
  font-weight: 400;
  font-size: 13px;
}
.widal {
  margin-left: 20px;
}
