.hiv-grid {
  display: grid;
  gap: 0;
  background-color: white;
  padding: 5px 10px 0 10px;
  font-size: 12px;
}
.hiv-parent {
  display: flex;
}
.hiv-left {
  width: 77%;
  margin: 15px 0 0 5px;
}
.hiv1 {
  grid-column: 1;
  grid-row: 1;
}
.hiv2 {
  grid-column: 2;
  grid-row: 1;
}
.hiv-text textarea {
  height: 32px;
  border-radius: 4px;
  border: 1px solid rgb(210, 210, 210);
  margin-top: 2px;
}
.hiv-border {
  grid-column: 1 / span 2;
  grid-row: 2;
  border-bottom: 1px solid #d9d9d9;
}
.hiv-border1 {
  grid-column: 1 / span 2;
  grid-row: 4;
  border-bottom: 1px solid #d9d9d9;
}
.hiv-pad {
  padding: 7px 0 0 5px;
}
.hiv-foot {
  margin-top: 20px;
  padding-left: 10px;
}
.hiv-foot .add-image-input {
  background-color: white;
}
.hiv-method {
  font-size: 12px;
  margin-top: 20px;
  padding-left: 10px;
  font-weight: 600;
}
.hiv-method p {
  font-weight: 500;
}
