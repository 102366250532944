.profile-name{
    padding: 13px 10px;
    background: #ffffff;
}
.profile-name h1{
    font-size: 22px;
    font-weight: 700;
    color: #000000;
    margin: 5px 0px 0px 15px
}
.profile-middle-section{
    display: flex;
}
.profile-left-sec{
    width: 30%;
}
.profile-right-sec{
    width: 70%;
}