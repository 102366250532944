*{
    margin: 0;
    box-sizing: border-box;
}
.sub-domain{
    width: 80%;
    margin: 0 auto;
    height: auto;
}
.sub-domain-container{
  padding: 10px 20px 30px 20px;
  background-color: white;
  margin-bottom: 10px;
}
.sub-domain-container h2{
    font-weight: 600;
    margin-bottom: 0.1em;
}
.sub-domain-container p{
    padding-bottom: 3px;
    color: #B5B5B5;
    font-size: 12px;
    font-weight: 500;
}
.domain-input{
    height:35px;
    width:25vw;
}
.domain-input-two{
    height:33px;
    width:15vw;
}
.sub-domain-container2{
    padding: 10px 20px 30px 20px;
    background-color: white;
    margin-bottom: 10px;
    display: flex;
}
.sub-domain-container2 p{
    padding-bottom: 3px;
    color: #B5B5B5;
    font-size: 12px;
    font-weight: 500;
}
.domain-button-one{
    margin-left: 5px;
    height: 33px;
}
.domain-button{
    margin-top: 21px;
    margin-left: 5px;
    height: 30px;
}
.domain-button-upload{
    margin-left: 5px;
    height: 30px;
    width: 135px;
}
.domain-input-button{
    display: flex;
}
.register-dropdown {
    width: 25vw;
    display: flex;
}
.register-profile-pic {
    width: 55vw;
    display: flex;
}

.drop-down-sec{
    margin-left: 25px;
}

.file-upload {
    width: 100px;
    min-width: 100px;
    height: 30px;
    background-color: #cfcfcf;
    color: black;
    padding: 5px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    text-align: center;
}
.image-name{
    margin-left: 5px;
    margin-top: 4px;
    font-size: 12px;
    color: rgb(54, 52, 52);
    white-space: nowrap; 
    width: 90px; 
    overflow: hidden;
    text-overflow: ellipsis; 
}