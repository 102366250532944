.diagnostic-patient-details {
    display: flex;
    background: white;
    padding: 10px 40px;
    width: 100%;
}
.diagnostic-uhid-name-blood-address {
    display: flex;
    width: 80%;
}
.diagnostic-phone-age-email-section{
    display: flex;
    width: 80%;
}
.diagnostic-uhid-left-details{
    margin-right: 10px;
    font-weight: 500;
    font-size: 12px;
    color: #ADADAD;
    
}
.diagnostic-uhid-right-details{
    color: black;
    font-weight: 500;
    font-size: 12px;
}

.diagnostic-phone-email-left-section{
    margin-right: 10px;
    font-weight: 500;
    font-size: 12px;
    width: 16%;
    color: #ADADAD;
}


.diagnostic-phone-email-right-section{
    color: black;
    font-weight: 500;
    font-size: 12px;
}

