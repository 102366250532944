.pick-location-title-section {
  padding: 10px;
}
.diagnostic-patient-close-btn-section {
  background: lightgray;
  padding: 5px 10px;
}
.diagnostic-patient-name-section {
  display: flex;
  justify-content: space-between;
}
.diagnostic-patient-date-left-section {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: white;
  align-items: center;
}
.dot-bg {
  background: #ec008c;
  border-radius: 50%;
  padding: 5px;
  width: 1%;
}
.diagnostic-invoice-status-left-section {
  padding-left: 5px;
  line-height: 15px;
  font-size: 12px;
  color: black;
}
.dot-date-section {
  display: flex;
}
.report-pending-heading {
  color: #f6ad5b;
}

.diagnostic-patient-date-left-ready-section {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.diagnostic {
  font-size: 14px;
  font-weight: 500;
}
.diagnostic-patient-invoice {
  font-size: 14px;
  font-weight: 500;
}

.deletewhite-ready-icon-sec {
  background: #ef4036;
  border-radius: 50%;
  padding: 1px 5px 2px 5px;
}
.report-ready-heading {
  color: #5cb04b;
  font-weight: 600;
}
.dot-ready-bg {
  background: #f7941d;
  border-radius: 50%;
  padding: 5px;
  width: 1%;
}

.dot-ready-second-bg {
  background: #5cb04b;
  border-radius: 50%;
  padding: 5px;
  width: 1%;
}
.picklocation-title {
  display: flex;
  font-size: 12px;
}
.pick-date-title {
  font-size: 12px;
}
.diagnostics-folder {
  width: 30px;
  height: 30px;
  margin-left: 10px;
  cursor: pointer;
}
.delete-diagnostic-patient {
  display: flex;
  justify-content: space-evenly;
}

.folder-modal .ant-modal-close-x {
  font-size: 20px;
  padding: 20px;
  cursor: pointer;
}
.folder-modal .ant-modal-title {
  margin-top: 15px;
  color: orange;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  word-wrap: break-word;
}
.folder-modal .folder-modal {
  padding: 20px 24px 0 24px;
}
.folder-modal .ant-modal-body {
  padding: 0 24px 24px 24px;
}
.folder-container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 10px;
  color: orange;
  border-bottom: 1px solid lightgray;
  width: 100%;
  cursor: pointer;
}
.folder-container:hover {
  background: #f0f8fa;
}
.folder-container .diagnostics-folder {
  margin-left: 20px;
  margin-top: 20px;
}
.folder-section {
  width: 40%;
}
.drawer-container .anticon {
  position: relative;
  left: 446px;
}
.ant-drawer-title {
  display: none;
}
.ant-drawer-header {
  border-bottom: 0px;
}
.drawer-descrption {
  font-size: 12px;
}
.ant-drawer-content-wrapper {
  width: 531px !important;
}
.drawer-title {
  font-size: 14px;
  font-weight: 900;
}
.ant-drawer-header-title {
  display: flex;
  flex-direction: row-reverse;
  margin-left: 322px !important;
}
.ant-drawer-body {
  flex-grow: 1;
  overflow: auto;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
  padding-top: 9px;
}
.drawer-search-division {
  width: 92%;
  display: flex;
}
.drawer-search-input {
  height: 35px;
  width: 100%;
  margin: 10px 0px;
}
.drawer-adv-search {
  margin-left: 5px;
  color: gray;
  font-size: 13px;
}
.ant-drawer-close {
  display: inline-block;
  margin-right: -6px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
  text-rendering: auto;
}
.drawer-search-container {
  background-color: white;
  margin-top: 10px;
  width: 100%;
  border: 1px solid lightgray;
  padding: 10px;
}
.drawer-buttons-division {
  width: 23%;
}
.drawer-patient-heading {
  padding-left: 2px;
  border-bottom: 1px solid lightgray;
}
.analytics-dates {
  width: 50%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.analytics-title {
  margin-right: 10px;
}
.analytics-btn {
  margin: 0px 10px;
}
.diagnostic-date {
  width: 100%;
}
.diag-search-input {
  width: 115%;
  margin-left: 7px;
  margin-top: 5px;
}
