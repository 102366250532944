.haemo-parent {
  display: flex;
}
.haemo-left {
  width: 80%;
  margin-left: 5px;
  margin-top: 20px;
}
.haemo-grid {
  display: grid;
  background-color: white;
  padding: 5px 10px 0 10px;
  font-size: 12px;
}
.haemo1 {
  grid-column: 1;
  grid-row: 1;
  width: 150px;
}
.haemo2 {
  grid-column: 2;
  grid-row: 1;
}
.haemo3 {
  grid-column: 3;
  grid-row: 1;
}
.haemo4 {
  grid-column: 4;
  grid-row: 1;
}
.haemo5 {
  grid-column: 5;
  grid-row: 1;
}
.haemo6 {
  grid-column: 6;
  grid-row: 1;
}
.haemo-input {
  width: 120px;
  border-radius: 5px;
  margin-top: 2px;
}
.haemo-border {
  grid-column: 1 / span 6;
  grid-row: 2;
  border-bottom: 1px solid #d9d9d9;
}
.haemo-border1 {
  grid-column: 1 / span 6;
  grid-row: 4;
  border-bottom: 1px solid #d9d9d9;
}
.haemo-border2 {
  grid-column: 1 / span 6;
  grid-row: 6;
  border-bottom: 1px solid #d9d9d9;
}
.haemo-border3 {
  grid-column: 1 / span 6;
  grid-row: 8;
  border-bottom: 1px solid #d9d9d9;
}
.haemo-border4 {
  grid-column: 1 / span 6;
  grid-row: 10;
  border-bottom: 1px solid #d9d9d9;
}
.haemo-border5 {
  grid-column: 1 / span 6;
  grid-row: 12;
  border-bottom: 1px solid #d9d9d9;
}
.haemo-border6 {
  grid-column: 1 / span 6;
  grid-row: 14;
  border-bottom: 1px solid #d9d9d9;
}
.haemo-border7 {
  grid-column: 1 / span 6;
  grid-row: 16;
  border-bottom: 1px solid #d9d9d9;
}
.haemo-border8 {
  grid-column: 1 / span 6;
  grid-row: 18;
  border-bottom: 1px solid #d9d9d9;
}
.h-pad {
  padding: 6px 5px;
}
.haemo-foot {
  margin-top: 20px;
  padding-left: 5px;
}
.haemo-foot .add-image-input {
  background-color: white;
}
