.thyroidProfile-parent {
  display: flex;
}
.thyroidProfile-left {
  width: 77%;
  margin-top: 20px;
  margin-left: 5px;
}
.thyroidProfile-grid {
  display: grid;
  background-color: white;
  padding: 5px 10px 0 10px;
  font-size: 12px;
}
.thyroidProfile-1 {
  grid-column: 1;
  grid-row: 1;
}
.thyroidProfile-2 {
  grid-column: 2;
  grid-row: 1;
}

.thyroidProfile-3 {
  grid-column: 3;
  grid-row: 1;
}
.thyroidProfile-4 {
  grid-column: 4;
  grid-row: 1;
}
.thyroidProfile-5 {
  grid-column: 5;
  grid-row: 1;
}

.thyroidProfile-border-1 {
  grid-column: 1 / span 5;
  grid-row: 2;
  border-bottom: 1px solid #d9d9d9;
}
.thyroidProfile-6 {
  grid-column: 1;
  grid-row: 3;
}
.thyroidProfile-7 {
  grid-column: 2;
  grid-row: 3;
}

.thyroidProfile-8 {
  grid-column: 3;
  grid-row: 3;
}
.thyroidProfile-9 {
  grid-column: 4;
  grid-row: 3;
}
.thyroidProfile-10 {
  grid-column: 5;
  grid-row: 3;
}

.thyroidProfile-border-2 {
  grid-column: 1 / span 5;
  grid-row: 4;
  border-bottom: 1px solid #d9d9d9;
}
.thyroidProfile-11 {
  grid-column: 1;
  grid-row: 5;
}
.thyroidProfile-12 {
  grid-column: 2;
  grid-row: 5;
}

.thyroidProfile-13 {
  grid-column: 3;
  grid-row: 5;
}
.thyroidProfile-14 {
  grid-column: 4;
  grid-row: 5;
}
.thyroidProfile-15 {
  grid-column: 5;
  grid-row: 5;
}

.thyroidProfile-border-3 {
  grid-column: 1 / span 5;
  grid-row: 6;
  border-bottom: 1px solid #d9d9d9;
}

.thyroidProfile-input {
  width: 120px;
  border-radius: 5px;
}
.thyroidProfile-pad {
  padding-top: 7px;
}
.thyroidProfile-foot {
  margin-top: 20px;
  padding-left: 10px;
}
.thyroidProfile-foot .add-image-input {
  background-color: white;
}
.thyroidProfile-p {
  padding: 15px 10px;
  font-size: 12px;
  line-height: 015px;
  font-weight: 400;
}
.thyroidProfile-p h4 {
  font-weight: 800;
  padding-bottom: 5px;
}
