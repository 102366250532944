.file-div {
    margin: 20px;
}

.file-upload-num {
    margin-right: 20px;
}

.upload-visit-sec {
    display: flex;
    margin-bottom: 10px;
}

.file-13-div {
    width: 23%;
    line-height: 20px;
}

.file-13-div h6 {
    font-size: 14px;
    padding: 5px 10px;
    font-weight: 500;
}

.file-13-div p {
    padding-left: 10px;
    font-weight: 500;
    font-weight: 12px;
    color: #19b3f0;
    line-height: 0px;

}

.file-upload-image {
    text-align: left;
}

.file-upload-image p {
    color: black;
    font-weight: 400;
    margin-right: 10px;
}

.opd-pdf-remove {
    color: rgb(244, 108, 107);
    margin-left: 5px;
    cursor: pointer;
}

.upload-attach-btn {
    background-color: #007d9d;
    margin: 0 0 0 10px;
}

.files-delete-icon-div {
    width: 24px;
    height: 24px;
    background-color: red;
    border-radius: 50%;
    text-align: center;
}

.opd-image-sec {
    display: flex;
    background: #E3E7E7;
    padding: 20px 20px 10px 20px;
    cursor: pointer;
    flex-wrap: wrap;
}

.opd-image-sec div {
    margin-right: 13px;
    margin-bottom: 10px;
}

.opd-image-del {
    color: rgb(244, 108, 107);
    text-align: center;
    cursor: pointer;

}

.opd-file-img-button-sec {
    display: flex;
    width: 100%;
}

.opd-file-img-button-sec-1 {
    width: 19%;
}

.file-upload-num-1 {
    margin-right: 46PX;

}

.selected-images-modal {
    background: #E3E7E7;
    padding: 10px;
    height: 400px;
    overflow-y: scroll;
}

.selected-images-modal::-webkit-scrollbar {
    width: 10px;
}

.selected-images-modal::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.selected-images-modal::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;

}

.selected-images-modal::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.selcted-image-date-sec img {
    width: 120px;
    height: 80px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.pdf-show-sec embed {
    width: 110px;
    height: 200px;
}

.pdf-show-sec a {
    text-align: center;
}

.opd-selected-doc {
    display: flex;
    flex-wrap: wrap;
}

.selected-document-sec p {
    margin-bottom: 10px;
}

.single-image-sec {
    margin: 14px;
}