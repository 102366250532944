.note-div {
    display: flex;
    margin: 20px;
}

.note-4-div {
    width: 23%;
}

.note-4-div h6 {
    font-size: 13px;
    padding: 5px 10px;
    font-weight: 600;
}

.note-antSelect {
    width: 56%;
    display: flex;
    align-items: center;
}

.note-save-btn {
    padding: 6px 8px;
}

.note-input-field {
    margin-right: 5px;
    border: 1px solid rgb(128,128,128,0.4);
}
