.abg-main {
  display: flex;
}
.abg-container {
  width: 80%;
}
.abg-grid-container {
  display: grid;
  column-gap: 10px;
  background-color: #ffff;
  text-align: left;
  padding: 24px 10px 0 16px;
  font-size: 12px;
}
.bil-border4 {
  grid-column: 1 / span 7;
  grid-row: 8;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.bil-border5 {
  grid-column: 1 / span 7;
  grid-row: 10;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.bil-border6 {
  grid-column: 1 / span 7;
  grid-row: 12;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.bil-border7 {
  grid-column: 1 / span 7;
  grid-row: 14;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.bil-border8 {
  grid-column: 1 / span 7;
  grid-row: 16;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.bil-border9 {
  grid-column: 1 / span 7;
  grid-row: 18;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.bil-border10 {
  grid-column: 1 / span 7;
  grid-row: 20;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.bil-border11 {
  grid-column: 1 / span 7;
  grid-row: 22;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.bil-border12 {
  grid-column: 1 / span 7;
  grid-row: 24;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.abg-footer {
  margin-left: 15px;
}
.abg-note {
  font-weight: 600;
  margin-bottom: 7px !important;
}
.abg-hct {
  font-weight: 500;
}
