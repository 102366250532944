.dnd-container{
    width: 90%;
    margin: 0px 20px 80px 6%;
    background-color: white;
    font-weight: 400;
    
}
.dnd-top-sec{
    width: 70%;
    margin-top: 35px;
    margin-bottom: -10px;
    margin-left: 15%;
    display: flex;
    justify-content: space-between;
}

.dnd-top-sec h2{
    font-weight: bold;
}

.select-dnd{
    width:360px;
} 

.dnd-container tr td{
    font-weight: 500;
    padding-left: 10px;
}

/* ---------------modal styles----------- */

.dnd-modal{
    margin-top: 60px;
}

.dnd-container-two {
    width: 100%;
    margin-top: 20px;
    padding: 0 24px 0px 24px;
}
.dnd-section{
    background-color: white;
}

.dnd-inp{
    display: flex;
    justify-content: center;
}

.dnd-label{
    margin-top: 7px;
}

.dnd-title{
   margin-right: 370px;
   font-weight: 600;
}
.dnd-inp-one{
    border:1px solid #cccc;
    height:35px;
    margin-left: 12px;
    width:360px;
}
.dnd-modal .ant-modal-close-x{
    padding: 0;
    margin-top: 0;
}


@media screen and (min-width:1880px) {
    
    .dnd-top-sec{
        width: 90%;
        margin-top: 35px;
        margin-bottom: -10px;
        margin-left: 6%;
        display: flex;
        justify-content: space-between;
    }
}


@media screen and (min-width:1336px) {
    
    .dnd-top-sec{
        width: 90%;
        margin-top: 35px;
        margin-bottom: -10px;
        margin-left: 6%;
        display: flex;
        justify-content: space-between;
    }
}