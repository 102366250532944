.header-container {
    background: #007D9D;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    color: white;
    display: flex;
    height: 72px;
    justify-content: space-between;
    z-index: 10;
    /* z-index: 1100; */
}

.header-left {
    display: flex;
}

.header-right {
    display: flex;
    justify-content: space-between;
}

.avatar-name {
    margin: 10px 10px 0px 10px;

}
.clinic{
    padding-top: 6px;
}

.clinic h1 {
    font-size: 24px;
    color: #FFFFFF;
    font-weight: 600;

    margin: 0px;
}

.clinic p {
    font-size: 13px;
    color: #F0F8FA;
}

.edit-pencil-sec {
    margin: 10px 0px 0px 10px;
}

.edit-icon-header {
    background: black;
    padding: 10px;
    line-height: 2.5em;
    height: 2.5em;
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    width: 2.5em;
    font-size: 10px;

}

.ant-avatar-lg {
    width: 50px !important;
    height: 50px !important;
    line-height: 50px !important;
    border-radius: 50%;
}

.ant-avatar-string {
    position: absolute;
    left: 50%;
    font-size: 25px;
    font-weight: 700;
    transform-origin: 0 center;
}

.font-icon-section {
    border-left: 1px solid white;
    border-right: 1px solid white;
    padding: 20px 20px 20px 20px;

}

.font-icon-section-last {

    border-right: 1px solid white;
    padding: 20px 15px 15px 15px;

}

.font-icon-size {
    font-size: 30px;
}

.ant-avatar.ant-avatar-icon {
    font-size: 30px;
}

.ant-avatar {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-block;
    overflow: hidden;
    color: #ffffff;
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    width: 40px;
    height: 40px;
    line-height: 32px;
    border-radius: 50%;
    background: none;
}

.ant-badge-count {
    color: #000000 !important;
    background: #FDC132 !important;

}

.user-info {
    padding-top: 12px;
}

.user-icon-header {
    margin: 7px 9px;
}

.user-info h5 {
    font-size: 18px;
    font-weight: 400;
    margin: 0px 5px;
    color: #ffffff;
}

.user-info p {
    margin: 0px 5px;
    font-size: 12px;
    color: #F0F8FA;

}

.arrow-image.ant-dropdown {
    margin-top: 12px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    position: absolute;
    top: -9999px;
    left: -9999px;
    z-index: 1050;
    display: block;
    width: 19.3%;
}

.arrow-image {
    padding-top: 9px;
    padding-right: 21px;
}

.arrow-image img {
    width: 11px;
}

.doctor-dropdown {
    padding-top: 9px;
    padding-right: 21px;
}

.doctor-dropdown img {
    width: 11px;
}

.ant-dropdown.ant-dropdown-placement-bottomRight {
    width: 17.4%;
    margin: 91px 159px;
}

/* .doctor-dropdown .ant-dropdown-menu {
    margin: 54px 159px;
} */
.ant-dropdown-menu {
    position: relative;
    width: 100%;
    margin-left: 21px;
    margin-top: 14px;
    padding: 4px 0;
    width: 100%;
    text-align: center;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 2px;
    outline: none;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
}



google-cast-launcher {
    width: 25px;
    height: 35px;
    display: block !important;
    --disconnected-color: #ffffff;
    --connected-color: purple;
}
