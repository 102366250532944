.embr-main-container {
  display: flex;
}
.embr-main-left-sec {
  width: 80%;
}
.embr-container {
  display: grid;
  background-color: #ffff;
  text-align: left;
  padding: 10px 0px 0 10px;
  font-size: 12px;
}
.embr-container textarea {
  height: 32px;
  border-radius: 4px;
}
.embr-footer .add-image-input {
  background-color: #ffff;
}
.test-report-input textarea {
  background-color: #ffff;
}
.embr-footer {
  margin-left: 10px;
}

.embr-1 {
  grid-column: 1;
  grid-row: 1;
}
.embr-2 {
  grid-column: 4 / span 4;
  grid-row: 1;
}
.embr-border1 {
  grid-column: 1 / span 7;
  grid-row: 2;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.embr-3 {
  grid-column: 1;
  grid-row: 3;
}
.embr-4 {
  grid-column: 4 / span 4;
  grid-row: 3;
}
.embr-border2 {
  grid-column: 1 / span 7;
  grid-row: 4;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.embr-5 {
  grid-column: 1;
  grid-row: 5;
}
.embr-6 {
  grid-column: 4 / span 4;
  grid-row: 5;
}
.embr-border3 {
  grid-column: 1 / span 7;
  grid-row: 6;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.embr-7 {
  grid-column: 1;
  grid-row: 7;
}
.embr-8 {
  grid-column: 4 / span 4;
  grid-row: 7;
}
.embr-border4 {
  grid-column: 1 / span 7;
  grid-row: 8;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.embr-9 {
  grid-column: 1;
  grid-row: 9;
}
.embr-10 {
  grid-column: 4 / span 4;
  grid-row: 9;
}
.embr-border5 {
  grid-column: 1 / span 7;
  grid-row: 10;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.embr-11 {
  grid-column: 1 / span 2;
  grid-row: 11;
}
.embr-12 {
  grid-column: 4 / span 4;
  grid-row: 11;
}
.embr-border6 {
  grid-column: 1 / span 7;
  grid-row: 12;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.embr-13 {
  grid-column: 1;
  grid-row: 13;
}
.embr-14 {
  grid-column: 4 / span 4;
  grid-row: 13;
}
.embr-border7 {
  grid-column: 1 / span 7;
  grid-row: 14;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.embr-15 {
  grid-column: 1;
  grid-row: 15;
}
.embr-16 {
  grid-column: 4 / span 4;
  grid-row: 15;
}
.embr-border8 {
  grid-column: 1 / span 7;
  grid-row: 16;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.embr-17 {
  grid-column: 1;
  grid-row: 17;
}
.embr-18 {
  grid-column: 4 / span 4;
  grid-row: 17;
}
.embr-border9 {
  grid-column: 1 / span 7;
  grid-row: 18;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.embr-19 {
  grid-column: 1;
  grid-row: 19;
}
.embr-20 {
  grid-column: 4 / span 4;
  grid-row: 19;
}
.embr-border10 {
  grid-column: 1 / span 7;
  grid-row: 20;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.embr-21 {
  grid-column: 1;
  grid-row: 21;
}
.embr-22 {
  grid-column: 4 / span 4;
  grid-row: 21;
}
.embr-border11 {
  grid-column: 1 / span 7;
  grid-row: 22;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.embr-23 {
  grid-column: 1;
  grid-row: 23;
}
.embr-24 {
  grid-column: 4 / span 4;
  grid-row: 23;
}
.embr-border12 {
  grid-column: 1 / span 7;
  grid-row: 24;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.embr-25 {
  grid-column: 1;
  grid-row: 25;
}
.embr-26 {
  grid-column: 4 / span 4;
  grid-row: 25;
}
.embr-border13 {
  grid-column: 1 / span 7;
  grid-row: 26;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.embr-27 {
  grid-column: 1;
  grid-row: 27;
}
.embr-28 {
  grid-column: 4 / span 4;
  grid-row: 27;
}
.embr-border14 {
  grid-column: 1 / span 7;
  grid-row: 28;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.embr-29 {
  grid-column: 1;
  grid-row: 29;
}
.embr-30 {
  grid-column: 4 / span 4;
  grid-row: 29;
}
.embr-border15 {
  grid-column: 1 / span 7;
  grid-row: 30;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
/* -------------------------------------- */

.embr-31 {
  grid-column: 1;
  grid-row: 31;
}
.embr-32 {
  grid-column: 4 / span 4;
  grid-row: 31;
}
.embr-border16 {
  grid-column: 1 / span 7;
  grid-row: 32;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.embr-33 {
  grid-column: 1 / span 2;
  grid-row: 33;
}
.embr-34 {
  grid-column: 4 / span 4;
  grid-row: 33;
}
.embr-border17 {
  grid-column: 1 / span 7;
  grid-row: 34;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.embr-35 {
  grid-column: 1;
  grid-row: 35;
}
.embr-36 {
  grid-column: 4 / span 4;
  grid-row: 35;
}
.embr-border18 {
  grid-column: 1 / span 7;
  grid-row: 36;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.embr-37 {
  grid-column: 1;
  grid-row: 37;
}
.embr-38 {
  grid-column: 4 / span 4;
  grid-row: 37;
}
.embr-border19 {
  grid-column: 1 / span 7;
  grid-row: 38;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.embr-39 {
  grid-column: 1;
  grid-row: 39;
}
.embr-40 {
  grid-column: 4 / span 4;
  grid-row: 39;
}
.embr-border20 {
  grid-column: 1 / span 7;
  grid-row: 40;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.embr-41 {
  grid-column: 1;
  grid-row: 41;
}
.embr-42 {
  grid-column: 4 / span 4;
  grid-row: 41;
}
.embr-border21 {
  grid-column: 1 / span 7;
  grid-row: 42;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.embr-43 {
  grid-column: 1;
  grid-row: 43;
}
.embr-44 {
  grid-column: 4 / span 4;
  grid-row: 43;
}
.embr-border22 {
  grid-column: 1 / span 7;
  grid-row: 44;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.embr-45 {
  grid-column: 1;
  grid-row: 45;
}
.embr-46 {
  grid-column: 4 / span 4;
  grid-row: 45;
}
.embr-border23 {
  grid-column: 1 / span 7;
  grid-row: 46;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.embr-47 {
  grid-column: 1;
  grid-row: 47;
}
.embr-48 {
  grid-column: 4 / span 4;
  grid-row: 47;
}
.embr-border24 {
  grid-column: 1 / span 7;
  grid-row: 48;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}

.embr-49 {
  grid-column: 3 / span 2;
  grid-row: 49;
  /* width: 50px; */
}

.embr-50 {
  grid-column: 6 / span 2;
  grid-row: 49;
}
.embr-border25 {
  grid-column: 1 / span 7;
  grid-row: 50;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}

/* ------------------- */
.embr-5-input {
  height: 29px;
  border-radius: 4px;
}

/* ----------------------- */

.embr-51 {
  grid-column: 1;
  grid-row: 51;
}
.embr-52 {
  grid-column: 3 / span 2;
  grid-row: 51;
}

.embr-53 {
  grid-column: 6 / span 2;
  grid-row: 51;
}

.embr-border26 {
  grid-column: 1 / span 7;
  grid-row: 52;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}

.embr-54 {
  grid-column: 1;
  grid-row: 52;
}
.embr-55 {
  grid-column: 3 / span 2;
  grid-row: 52;
}

.embr-56 {
  grid-column: 6 / span 2;
  grid-row: 52;
}

.embr-border27 {
  grid-column: 1 / span 7;
  grid-row: 53;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.embr-57 {
  grid-column: 1;
  grid-row: 54;
}
.embr-58 {
  grid-column: 3 / span 2;
  grid-row: 54;
}

.embr-59 {
  grid-column: 6 / span 2;
  grid-row: 54;
}

.embr-border28 {
  grid-column: 1 / span 7;
  grid-row: 55;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}

.embr-60 {
  grid-column: 1;
  grid-row: 56;
}
.embr-61 {
  grid-column: 3 / span 2;
  grid-row: 56;
}

.embr-62 {
  grid-column: 6 / span 2;
  grid-row: 56;
}

.embr-border29 {
  grid-column: 1 / span 7;
  grid-row: 57;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}

/* ----------------------- */
.embr-63 {
  grid-column: 1 / span 1;
  grid-row: 70;
}
.embr-64 {
  grid-column: 2 / span 1;
  grid-row: 70;
}
.embr-65 {
  grid-column: 3 / span 1;
  grid-row: 70;
}
.embr-66 {
  grid-column: 4 / span 1;
  grid-row: 70;
}
.embr-67 {
  grid-column: 5 / span 1;
  grid-row: 70;
}
.embr-68 {
  grid-column: 6 / span 1;
  grid-row: 70;
}
.embr-69 {
  grid-column: 7 / span 1;
  grid-row: 70;
}
.embr-border31 {
  grid-column: 1 / span 7;
  grid-row: 71;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}

.embr-70 {
  grid-column: 1 / span 1;
  grid-row: 72;
}
.embr-71 {
  grid-column: 2 / span 1;
  grid-row: 72;
}
.embr-72 {
  grid-column: 3 / span 1;
  grid-row: 72;
}
.embr-73 {
  grid-column: 4 / span 1;
  grid-row: 72;
}
.embr-74 {
  grid-column: 5 / span 1;
  grid-row: 72;
}
.embr-75 {
  grid-column: 6 / span 1;
  grid-row: 72;
}
.embr-76 {
  grid-column: 7 / span 1;
  grid-row: 72;
}
.embr-border32 {
  grid-column: 1 / span 7;
  grid-row: 73;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}

.embr-77 {
  grid-column: 1 / span 1;
  grid-row: 74;
}
.embr-78 {
  grid-column: 2 / span 1;
  grid-row: 74;
}
.embr-79 {
  grid-column: 3 / span 1;
  grid-row: 74;
}
.embr-80 {
  grid-column: 4 / span 1;
  grid-row: 74;
}
.embr-81 {
  grid-column: 5 / span 1;
  grid-row: 74;
}
.embr-82 {
  grid-column: 6 / span 1;
  grid-row: 74;
}
.embr-83 {
  grid-column: 7 / span 1;
  grid-row: 74;
}
.embr-border33 {
  grid-column: 1 / span 7;
  grid-row: 73;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}

.embr-84 {
  grid-column: 1 / span 1;
  grid-row: 74;
}
.embr-85 {
  grid-column: 2 / span 1;
  grid-row: 74;
}
.embr-86 {
  grid-column: 3 / span 1;
  grid-row: 74;
}
.embr-87 {
  grid-column: 4 / span 1;
  grid-row: 74;
}
.embr-88 {
  grid-column: 5 / span 1;
  grid-row: 74;
}
.embr-89 {
  grid-column: 6 / span 1;
  grid-row: 74;
}
.embr-90 {
  grid-column: 7 / span 1;
  grid-row: 74;
}
.embr-border34 {
  grid-column: 1 / span 7;
  grid-row: 75;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}

.embr-91 {
  grid-column: 1 / span 1;
  grid-row: 75;
}
.embr-92 {
  grid-column: 2 / span 1;
  grid-row: 75;
}
.embr-93 {
  grid-column: 3 / span 1;
  grid-row: 75;
}
.embr-94 {
  grid-column: 4 / span 1;
  grid-row: 75;
}
.embr-95 {
  grid-column: 5 / span 1;
  grid-row: 75;
}
.embr-96 {
  grid-column: 6 / span 1;
  grid-row: 75;
}
.embr-97 {
  grid-column: 7 / span 1;
  grid-row: 75;
}
.embr-border35 {
  grid-column: 1 / span 7;
  grid-row: 76;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}

.embr-98 {
  grid-column: 1 / span 1;
  grid-row: 77;
}
.embr-99 {
  grid-column: 2 / span 1;
  grid-row: 77;
}
.embr-100 {
  grid-column: 3 / span 1;
  grid-row: 77;
}
.embr-101 {
  grid-column: 4 / span 1;
  grid-row: 77;
}
.embr-102 {
  grid-column: 5 / span 1;
  grid-row: 77;
}
.embr-103 {
  grid-column: 6 / span 1;
  grid-row: 77;
}
.embr-104 {
  grid-column: 7 / span 1;
  grid-row: 77;
}
.embr-border36 {
  grid-column: 1 / span 7;
  grid-row: 78;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}

.embr-105 {
  grid-column: 1 / span 1;
  grid-row: 79;
}
.embr-106 {
  grid-column: 2 / span 1;
  grid-row: 79;
}
.embr-107 {
  grid-column: 3 / span 1;
  grid-row: 79;
}
.embr-108 {
  grid-column: 4 / span 1;
  grid-row: 79;
}
.embr-109 {
  grid-column: 5 / span 1;
  grid-row: 79;
}
.embr-110 {
  grid-column: 6 / span 1;
  grid-row: 79;
}
.embr-111 {
  grid-column: 7 / span 1;
  grid-row: 79;
}
.embr-border37 {
  grid-column: 1 / span 7;
  grid-row: 80;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}

.embr-112 {
  grid-column: 1 / span 1;
  grid-row: 81;
}
.embr-113 {
  grid-column: 2 / span 1;
  grid-row: 81;
}
.embr-114 {
  grid-column: 3 / span 1;
  grid-row: 81;
}
.embr-115 {
  grid-column: 4 / span 1;
  grid-row: 81;
}
.embr-116 {
  grid-column: 5 / span 1;
  grid-row: 81;
}
.embr-117 {
  grid-column: 6 / span 1;
  grid-row: 81;
}
.embr-118 {
  grid-column: 7 / span 1;
  grid-row: 81;
}
.embr-border38 {
  grid-column: 1 / span 7;
  grid-row: 82;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
