.total-table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 98.7%;
  background-color: white;
  margin-left: 6px;
  color: black;
  font-weight: 500;
}

th.ant-table-cell.total {
  width: 68.67%;
}

.total-table td,
th {
  text-align: left;
}

.total-table tr {
  border: 1px solid black;
}

.total-width {
  width: 52px;
}

.invoice-container {
  position: relative;
  margin-bottom: 10px;
}

.total {
  position: absolute;
  right: 3%;
}

@media screen and (min-width:1280px) {
  .total-table {
    width: 99%;
  }
}

@media screen and (min-width:1440px) {
  .total-table {
    width: 99.03%;
  }
}

@media screen and (min-width: 1880px) {
  .total-table {
    width: 99.3%;
  }
}

@media screen and (min-width: 2560px) {
  .total-table {
    width: 99.47%;
  }
}