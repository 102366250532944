:root{
    --variablename: default;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.doctor-signup-head h1 {
    color: #000000;
    font-size: 20px;
    font-weight: 600;

}
.doctor-signup-head{
    width: 56%;
    display: flex;
    justify-content: space-between;

}
.signup-left-sec-wrapper{
    display: flex;
    flex-direction: column;
}
.initialize-button{
    width: 140px;
    height: 30px;
    margin-top: 5px;
}

.doctor-signup-container {
    margin: 3% 0px 3% 6%;
}

.doctor-signup-left-sec {
    width: 16%;
    background-color: white;
}

.doctorlist-head {
    background: var(--variablename);
    padding: 5px 10px 5px 12px;
    display: flex;
    justify-content: space-between;
    position: relative;
}
.doctorlist-head h3 {
    color: #ffffff;
    margin: 0px;
}

.doctor-list-item {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px 5px 20px;
    border-bottom: 1px solid #ECECEC;
    cursor: pointer;
}
/* .doctor-list-item:active{
    background-color: red !important;
} */
.doctor-list{
    display: flex;
    justify-content: space-between;
    padding: 5px 10px 5px 20px;
    border-bottom: 1px solid #ECECEC;
    cursor: pointer;
    background-color: #F0F8FA !important;

}

.doctorlist-items-sec {
    background: #ffffff;
    max-height: 354px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none; 
}
.doctorlist-items-sec::-webkit-scrollbar {
    display: none;
}

.signup-right-sec {
    width: 84%;
    margin-left: 10px;
}

.black-tick{
    display: none;
}

.black-tick-active{
    display: block;
}


/* assistant */

.assistant-top-sec {
    position: absolute;
    top: 46%;
    left: 48%;

}

.assistant-head {
    display: flex;
}

.assistant-head h3 {
    font-weight: 600;
    padding-left: 10px;
}

.each-doctor-list-container-left-sec {
    background: #ffffff;
    padding: 7px;
    width: 60%;
    margin-right: 32px;
    min-height: 388px;
}

.each-doctor-list-container-right-sec {
    background: #ffffff;
    padding: 7px;
    width: 60%;
    margin-right: 32px;
    height: fit-content;
}

.wrapper-right-sec {
    width: 50%;
}

.each-doctor-record {
    display: flex;
    align-items: center;
    height: 38px;
    justify-content:center ;
}
.each-doctor-head{
    display: flex;
    align-items: center;
    height: 38px;
}
.each-doctor-record p {
    width: 40%;
    text-align: right;
    padding-right: 20px;
    color: #B5B5B5;
    font-size: 12px;
    font-weight: 500;
}

.each-doctor-record h4 {
    width: 45%;
    margin: 7px 0px;
    font-weight: 500;
}
.each-doctor-record-right{
    display: flex;
    align-items: center;
}
.each-doctor-record-right-edit{
    display: flex;
    align-items: center;
}
.each-doctor-record-right p {
    width: 40%;
    text-align: right;
    padding-right: 20px;
    color: #B5B5B5;
    font-size: 12px;
    font-weight: 500;
}
.each-doctor-record-right h4 {
    width: 58%;
    margin: 7px 0px;
    font-weight: 500;
}
.each-doctor-record-right-edit p {
    width: 40%;
    text-align: right;
    padding-right: 20px;
    color: #B5B5B5;
    font-size: 12px;
    font-weight: 500;
}
.each-doctor-record-right-edit h4 {
    width: 58%;
    margin: 7px 0px;
    font-weight: 500;
}
.assistant-switch-mt {
    margin: 10px 0px;
}

.each-doctor-record span {
    margin-left: 5px;
}

.assistant-list-icon {
    background: hsl(204, 100%, 37%);
    padding: 3px 5px;
    height: 34px;
    border-radius: 5px;

}

.assistant-link-sec {
    background: #F1F1F1;
    padding: 10px 0px 20px 0px;
    margin-top: 60px;
}

.assistant-link-sec h3 {
    text-align: center;
    color: #000000;
    font-weight: 600;
}

.assistant-field-wd {
    width: 100%;
    display: flex;
    justify-content: center;    
}
.assistant-field-wd .ant-select{
    width: 75%;
}
.assistant-link-fields {
    display: flex;
    justify-content: center;
    align-items: center;
}

.assistant-link-fields p {
    color: #A7A7A7;
    margin-right: 7px;
}

.assistant-btn-sec {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
}

.assistant-save-btn-left-sec {
    padding: 7px 14px;
    margin: 0 10px;
}

.assistant-save-btn-right-sec {
    padding: 7px 14px;
    margin: 0 10px;
}

.assistant-cancel-btn {
    padding: 7px 10px;
    background: #BFBFBF;
}

.signup-wrapper {
    display: flex;
    justify-content: space-evenly;
}

.active-status {
    margin-top: 10px;
}
.doctor-search{
    width: 40px;
    vertical-align: middle;
    white-space: nowrap;
    /* position: relative; */
}
.input-search-alt{
    display: none;
}
.input-search::placeholder{
    color: #b0b0b0; 
}
.dr-search img{
    float: right;
}
.input-search{
    width: 80%;
    height: 26px;
    font-size: 14px;
    display: inline-block;
    font-weight: 100;
    border: none;
    outline: none;
    color: rgb(0, 0, 0);
    padding-left: 7px;
    position: absolute;
    right: 35px;
    background: white;
    z-index: 3;
}

.icon-sr{
    width: 50%;
    margin-top: 5px;
    cursor: pointer;
}
.clear-input{
    cursor: pointer;
    display: block;
    width: 60%;
}
.pass-hide{
    display: none;
}
.edit-doc{
    min-height: 388px;
}
.edit-save{
    margin-top: 194px ;
}
@media screen and (min-width: 992px) and (max-width:1260px) {
    .input-search{
        width: 75%;
    }
}
@media screen and (min-width: 1610px) and (max-width:1980px) {
    .input-search{
        width: 84%;
    }
}
@media screen and (min-width: 1980px) and (max-width:2310px) {
    .input-search{
        width: 86%;
    }
}
@media screen and (min-width: 2310px) and (max-width:2560px) {
    .input-search{
        width: 88%;
    }
}