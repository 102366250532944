.ivf-main-container {
  display: flex;
}
.ivf-main-left-sec {
  width: 80%;
}
.ivf-container {
  display: grid;
  background-color: #ffff;
  text-align: left;
  padding: 10px 0px 0 10px;
  font-size: 12px;
}
.ivf-footer .add-image-input {
  background-color: #ffff;
}
.test-report-input textarea {
  background-color: #ffff;
  margin-top: 2px;
}
.ivf-footer {
  margin-left: 10px;
}

.ivf1 {
  grid-column: 1;
  grid-row: 1;
}
.ivf2 {
  grid-column: 3 / span 3;
  grid-row: 1;
}
.ivf-border1 {
  grid-column: 1 / span 6;
  grid-row: 2;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.ivf3 {
  grid-column: 1;
  grid-row: 3;
}
.ivf4 {
  grid-column: 3 / span 3;
  grid-row: 3;
}
.ivf-border2 {
  grid-column: 1 / span 6;
  grid-row: 4;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.ivf5 {
  grid-column: 1;
  grid-row: 5;
}
.ivf6 {
  grid-column: 3 / span 3;
  grid-row: 5;
}
.ivf-border3 {
  grid-column: 1 / span 6;
  grid-row: 6;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.ivf7 {
  grid-column: 1;
  grid-row: 7;
}
.ivf8 {
  grid-column: 3 / span 3;
  grid-row: 7;
}
.ivf-border4 {
  grid-column: 1 / span 6;
  grid-row: 8;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.ivf9 {
  grid-column: 1;
  grid-row: 9;
}
.ivf10 {
  grid-column: 3 / span 3;
  grid-row: 9;
}
.ivf-border5 {
  grid-column: 1 / span 6;
  grid-row: 10;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.ivf11 {
  grid-column: 1;
  grid-row: 11;
}
.ivf12 {
  grid-column: 3 / span 3;
  grid-row: 11;
}
.ivf-border6 {
  grid-column: 1 / span 6;
  grid-row: 12;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.ivf13 {
  grid-column: 1;
  grid-row: 13;
}
.ivf14 {
  grid-column: 3 / span 3;
  grid-row: 13;
}
.ivf-border7 {
  grid-column: 1 / span 6;
  grid-row: 14;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.ivf15 {
  grid-column: 1;
  grid-row: 15;
}
.ivf16 {
  grid-column: 3 / span 3;
  grid-row: 15;
}
.ivf-border8 {
  grid-column: 1 / span 6;
  grid-row: 16;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.ivf17 {
  grid-column: 1;
  grid-row: 17;
}
.ivf18 {
  grid-column: 2;
  grid-row: 17;
}
.ivf19 {
  grid-column: 3;
  grid-row: 17;
}
.ivf20 {
  grid-column: 4;
  grid-row: 17;
}
.ivf21 {
  grid-column: 5;
  grid-row: 17;
}
.ivf22 {
  grid-column: 6;
  grid-row: 17;
}
.ivf-border9 {
  grid-column: 1 / span 6;
  grid-row: 18;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.ivf23 {
  grid-column: 1;
  grid-row: 19;
}
.ivf24 {
  grid-column: 2;
  grid-row: 19;
}
.ivf25 {
  grid-column: 3;
  grid-row: 19;
}
.ivf26 {
  grid-column: 4;
  grid-row: 19;
}
.ivf27 {
  grid-column: 5;
  grid-row: 19;
}
.ivf28 {
  grid-column: 6;
  grid-row: 19;
}
.ivf-border10 {
  grid-column: 1 / span 6;
  grid-row: 20;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.ivf29 {
  grid-column: 1;
  grid-row: 21;
}
.ivf30 {
  grid-column: 2;
  grid-row: 21;
}
.ivf31 {
  grid-column: 3;
  grid-row: 21;
}
.ivf32 {
  grid-column: 4;
  grid-row: 21;
}
.ivf33 {
  grid-column: 5;
  grid-row: 21;
}
.ivf34 {
  grid-column: 6;
  grid-row: 21;
}
.ivf-border11 {
  grid-column: 1 / span 6;
  grid-row: 22;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.ivf35 {
  grid-column: 1;
  grid-row: 23;
}
.ivf36 {
  grid-column: 2;
  grid-row: 23;
}
.ivf37 {
  grid-column: 3;
  grid-row: 23;
}
.ivf38 {
  grid-column: 4;
  grid-row: 23;
}
.ivf39 {
  grid-column: 5;
  grid-row: 23;
}
.ivf40 {
  grid-column: 6;
  grid-row: 23;
}
.ivf-border12 {
  grid-column: 1 / span 6;
  grid-row: 24;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.ivf40 {
  grid-column: 6;
  grid-row: 23;
}
.ivf41 {
  grid-column: 1;
  grid-row: 25;
}
.ivf42 {
  grid-column: 2;
  grid-row: 25;
}
.ivf43 {
  grid-column: 3;
  grid-row: 25;
}
.ivf44 {
  grid-column: 4;
  grid-row: 25;
}
.ivf45 {
  grid-column: 5;
  grid-row: 25;
}
.ivf-border13 {
  grid-column: 1 / span 6;
  grid-row: 26;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.ivf46 {
  grid-column: 6;
  grid-row: 27;
}
.ivf47 {
  grid-column: 1;
  grid-row: 27;
}
.ivf48 {
  grid-column: 2;
  grid-row: 27;
}
.ivf49 {
  grid-column: 3;
  grid-row: 27;
}
.ivf50 {
  grid-column: 4;
  grid-row: 27;
}
.ivf51 {
  grid-column: 5;
  grid-row: 27;
}
.ivf-border14 {
  grid-column: 1 / span 6;
  grid-row: 28;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.ivf52 {
  grid-column: 6;
  grid-row: 29;
}
.ivf53 {
  grid-column: 1;
  grid-row: 29;
}
.ivf54 {
  grid-column: 2;
  grid-row: 29;
}
.ivf55 {
  grid-column: 3;
  grid-row: 29;
}
.ivf56 {
  grid-column: 4;
  grid-row: 29;
}
.ivf57 {
  grid-column: 5;
  grid-row: 29;
}
.ivf-border15 {
  grid-column: 1 / span 6;
  grid-row: 30;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.ivf58 {
  grid-column: 6;
  grid-row: 31;
}
.ivf59 {
  grid-column: 1;
  grid-row: 31;
}
.ivf60 {
  grid-column: 2;
  grid-row: 31;
}
.ivf61 {
  grid-column: 3;
  grid-row: 31;
}
.ivf62 {
  grid-column: 4;
  grid-row: 31;
}
.ivf63 {
  grid-column: 5;
  grid-row: 31;
}
.ivf-border16 {
  grid-column: 1 / span 6;
  grid-row: 32;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.ivf64 {
  grid-column: 6;
  grid-row: 33;
}
.ivf65 {
  grid-column: 1;
  grid-row: 33;
}
.ivf66 {
  grid-column: 2;
  grid-row: 33;
}
.ivf67 {
  grid-column: 3;
  grid-row: 33;
}
.ivf68 {
  grid-column: 4;
  grid-row: 33;
}
.ivf69 {
  grid-column: 5;
  grid-row: 33;
}
.ivf70 {
  grid-column: 6;
  grid-row: 33;
}
.ivf-container textarea {
  height: 32px;
  border-radius: 4px;
}
.ivf-border17 {
  grid-column: 1 / span 6;
  grid-row: 34;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.ivf5-input {
  width: 120px;
  height: 32px;
  margin-top: 2px;
}
