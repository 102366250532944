

.ant-space-item {
    width: 84%;
    margin: 0 10px 0 10px;
}
.calendar-container .rbc-btn-group {
    display: inline-block;
    width: 39%;
    white-space: nowrap;
}

.calendar-container .rbc-toolbar .rbc-toolbar-label {
    flex-grow: 1;
    padding: 5px;
    text-align: center;
    font-size: 10px;
    font-weight: 700;
    min-width: 79px;
    margin-left: 103px;
}
.calendar-container .rbc-toolbar {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    font-size: 16px;
    width: 80%;
    flex-direction: column;
    margin-top: 28px;
    align-items: center;
}
.calendar-container .rbc-time-view {
    width: 98%;
}
.calendar-container .rbc-month-view {
    width: 94%;
}
.appointment-modal-container-delete{
    text-align: center;
}
.deleteModal{
    text-align: center;
}
.calendar-container .rbc-calendar {
    margin-left: 10px;
}
.date-time{
    display: flex;
    margin-top: 20px;
}
.date{
    background-color: #f4f4f5;
    border-color: #e9e9eb;
    padding: 5px;
    border-radius: 7px;
}
.time{
    background-color: #fef0f0;
    border-color: #fde2e2;
    color: #f56c6c !important;
    padding: 5px;
    border-radius: 7px;
    margin-left: 10px
}
.apt-type{
    padding: 5px;
    border-radius: 7px;
    margin-left: 10px;
    background-color: #fdf6ec;
    border-color: #faecd8;
    color: #e6a23c;
}
.icons{
    display: flex;
    padding-top: 15px;
    align-items: flex-start;
}
.icon{
    margin-right: 10px;
}
.share-icon-div{
    margin-top: -3px;
}
.cancel-button1{
    background-color: gray;

}
button.confirm-button {
    margin: 22px 0 36px 0;
}
.token{
    background-color: #ebeced;
    border: 1px solid #d7dadc;
    display: inline-block;
    height: 21px;
    padding: 5px;
    line-height: 9px;
    font-size: 12px;
    color: #36454f;
    border-radius: 4px;
    box-sizing: border-box;
    white-space: nowrap;
    position: relative;
    bottom: 4px;
    margin-right: 10px;
}
/* .iframe{
    height:auto;
    width:100%;
    object-fit: cover;
} */

.appointment-location-input{
    width: 88%;
}

.appointment-datepicker {
    width: 43%;
    margin-right: 10px;
}

.slot_to {
    width: 23% !important;
}

.appointment-date-gap {
    gap: 10px !important;
}

@media screen and (min-width: 1240px) {
    .calendar-container .rbc-toolbar .rbc-toolbar-label {
        flex-grow: 1;
        padding: 0px;
        text-align: center;
        font-size: 10px;
        font-weight: 700;
        min-width: 80px;
        margin-left: 47px;
        margin-bottom: 64px;
    }
    .calendar-container .rbc-toolbar {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 0px;
        font-size: 16px;
        width: 80%;
        margin-top: 10px;
        flex-direction: row;
    }
    .calendar-container .rbc-time-view {
        width: 98%;
    }
    .calendar-container .rbc-month-view {
        width: 98%;
    }
    .calendar-container .rbc-calendar {
        margin-left: 10px;
    }
        
}

@media screen and (min-width: 1400px) {
    .calendar-container .rbc-toolbar .rbc-toolbar-label {
        min-width: 79px;
        margin-left: -18px;
    }
    .calendar-container .rbc-toolbar {
        width: 100%;
        margin-left: 0;
    }
}


/* .rbc-toolbar {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    font-size: 16px;
} */

