.empty-header {
    background-color: white;
    height: 4.1rem;
}

.creating-patient-div {
    display: flex;
    justify-content: space-between;
    margin: 10px;
    
}
.patient-attach-photo-div h2{
    font-weight: 700;
    margin-bottom: 5px !important;
}
span.anticon.anticon-close-circle {
    display: none;
}

.photo-atttach-div {
    display: flex;
}
.customer-photo-div {
    width: 230px;
    height: 140px;
    background-color: white;
    border: 2px solid lightgray;
    margin-right: 10px;
    text-align: center;
}
.photo {
    width: 227px;
    height: 136px;
    align-items: center;
}
.capture-button{
    margin-left: 166px;
}
.video-capture{
    width: 454px;
}
.customer-photo-div p {
    text-align: left;
}
.default-img{
    width: 84px;
    margin-top: 20px;
    align-items: center;


}
.new-patient-btn-edit-btn-div {
    display: flex;
}

.photo-radio {
    width: 25px;
    height: 25px;
    margin-right: 5px;
    
}

.create-new-patient-btn {
    padding: 2px 6px;
}
.edit-btn-create-new {
    background-color: lightgray;
    color: black;
    padding: 2px 6px;
}
.attach{
    margin-left: 20px;
}
.buttons{
    display: flex;
    margin: 10px 0 0 166px;
}

