.usg-input {
  display: grid;
  grid: 0;
  background-color: #ffff;
  text-align: left;
  padding: 5px 10px 0 10px;
  font-size: 12px;
  border-bottom: 1px solid rgb(210, 210, 210);
}

.item12 {
  grid-column: 1;
  grid-row: 1;
  width: 125px;
}

.item13 {
  grid-column: 2;
  grid-row: 1;
}

.usg-input textarea {
  width: 100%;
  height: 26px;
  border-radius: 4px;
  border: 1px solid rgb(210, 210, 210);
}

.main-content {
  width: 100%;
  display: flex;
}

.left-side-content {
  width: 80%;
  margin: 15px 0 0 10px;
}

.right-side-content {
  width: 20%;
}
