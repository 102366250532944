.patient-reports {
     background: #F0F8FA !important;
     /* border: 1px solid #F0F8FA ; */
     border-bottom: none;
}

.patient-reports h4 {
    font-size: 19px;
    font-weight: 700;
    padding-top: 15px;
    padding-left: 20px;
}
.patient-reports-title-container {
    display: flex;
    justify-content: space-between;
}
.patient-reports-referal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 0 10px;
}