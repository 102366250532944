.pres-diagnosis-first-table {
    margin: 0% 8% 0% 5%;
    padding-top: 10px;
    padding-right: 30px;
}
.diagnosis-patient-names {
    margin-bottom: 0px;

}
.pres-diagnosis-counts{
    margin-bottom: 0px;
}


.pres-diagnosis-count-num {
    margin-bottom: 0px;
    font-size: 12px;
}
.diagnosis-patient-names{
    font-size: 12px;
    width: 100%;

}
.diagnosis-names{
    width: 100%;
}

.pres-diagnosis-first-sect {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    padding-left: 20px;
    font-weight: 600;
}
.main-first-section {
    background: white;
    width: 45%;
}
.pres-diagnosis-medicine-capsule-count{
 margin-bottom: 0px;
}
.diagnosis-medicine-capsule{
    margin-bottom: 0px;

}
.diagnosis-medicine-capsule-section{
    width: 100%;
}