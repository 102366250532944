.logo-container {
  width: 320px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* height: 60vh; */

  @media (min-width: 768px) {
    height: 35vh;
  }
}

.logo-container-modal {
  width: 320px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 25vh;

  @media (min-width: 768px) {
    height: 35vh;
  }
}

.top-logos {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  justify-content: center;
  gap: 30px;
}

.apollo-pharm {
  width: 100px;
}

.apollo-logo-pharm {
  width: 110px;
}

.logo-container-form {
  width: 320px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.rbi-logo-container {
  margin-top: 20px !important;
}
