.history-container {
    margin: 2% 2% 0px;
}

.history-active-btn button {
    color: black;
    background: none;
    font-weight: 500;
}
.history-active-btn{
    display: flex;
    justify-content: space-between;
}

.history-below-sec {
    display: flex;
    justify-content: space-between;
    margin-left: 5%;
    margin-top: 10px;
}
.history-below-sec p{
    font-weight: 500;
}

.history-below-sec textarea {
    height: 40px;
    border-radius: 4px;
}
.add-image-btn-history img {
    padding-right: 5px;
}
.patient-history-save img{
    padding-right: 5px;
}
.patient-history-save Button{
    border-radius: 15px;
    padding: 6px 16px 4px 20px;
}
.patient-history-save{
    margin-bottom: 10px;
}
.ant-modal-title{
    font-weight: 400;
    font-size: 16px;
}

.add-image-btn-history button {
    font-weight: 500;
    border-radius: 15px;
    padding: 6px 16px 4px 20px;
    background-color: white;
    color: gray;
    border: 1px solid #d9d9d9;
}
.add-image-btn-history{
    margin: 2px 0px 15px 9px;
}
.history-below-sec img{
    padding-right: 5px;
}

.tui-image-editor-container .tui-image-editor-controls-logo > img, .tui-image-editor-container .tui-image-editor-header-logo > img {
    display: none;
}

.tui-image-editor-container .tui-image-editor-help-menu.top{
    left: 35% !important;
}
.history-container .ant-modal-close-x{
    font-size: 18px !;
    color: black;
    margin-top: -30px;
    padding-left: 38px;

}