.thyroid-parent {
  display: flex;
}
.thyroid-left {
  width: 77%;
  margin-top: 20px;
  margin-left: 5px;
}
.thyroid-grid {
  display: grid;
  background-color: white;
  padding: 5px 10px 0 10px;
  font-size: 12px;
  border-bottom: 1px solid #d9d9d9;
}
.thyroid-1 {
  grid-column: 1;
  grid-row: 1;
}
.thyroid-2 {
  grid-column: 2;
  grid-row: 1;
}

.thyroid-3 {
  grid-column: 3;
  grid-row: 1;
}
.thyroid-4 {
  grid-column: 4;
  grid-row: 1;
}
.thyroid-5 {
  grid-column: 5;
  grid-row: 1;
}
.thyroid-input {
  width: 120px;
  border-radius: 5px;
}
.thyroid-pad {
  padding-top: 7px;
}
.thyroid-foot {
  margin-top: 20px;
  padding-left: 10px;
}
.thyroid-foot .add-image-input {
  background-color: white;
}
