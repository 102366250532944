.btn-click {
    color: white;
    background-color: green;
    padding: 7px 12px;
    border: none;
    border-radius: 5px;
    font-size: 13px;
    margin-right: 5px;
}

/* 
.active-visit {
    color: black;
    background-color: green;
    padding: 2px 10px;
    border: none;
    border-radius: 5px;
    width: 180px;
    text-align: left;
    margin-bottom: 10px;
}

.date {
    background-color: gray;
    padding: 2px 10px;
    margin-top: 5px;
    border: none;
    border-radius: 5px;
    width: 180px;
    text-align: left;
} */

