.patient-left {
  width: 90%;
  display: grid;
  grid-gap: 0 10px;
  gap: 0;
  background-color: #ffff;
  text-align: left;
  padding: 10px 0 0 10px;
  font-size: 10px;
  font-weight: 500;
  height: 550px;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: rgb(218, 218, 221) rgb(207, 204, 204);
}
.patient-right {
  width: 16%;
}
.patient-pro-foot {
  margin-top: 20px;
}
.patient-left::-webkit-scrollbar {
  width: 10px;
}
.patient-left::-webkit-scrollbar-track {
  background: rgb(255, 255, 255);
}
.patient-left::-webkit-scrollbar-thumb {
  background-color: rgb(214, 214, 218);
  border-radius: 20px;
  border: 3px solid rgb(255, 255, 255);
}
.patient-input {
  border-radius: 4px;
  margin-top: 2px;
}
.patient-left textarea {
  height: 32px;
  border-radius: 4px;
  border: 1px solid rgb(210, 210, 210);
  margin-top: 2px;
  margin-top: 2px;
}
.pp1 {
  grid-column: 1 / span 2;
  grid-row: 1;
}
.pp2 {
  grid-column: 4 / span 3;
  grid-row: 1;
}
.pp3 {
  grid-column: 1 / span 2;
  grid-row: 3;
}
.pp4 {
  grid-column: 4 / span 3;
  grid-row: 3;
}
.pp5 {
  grid-column: 1 / span 2;
  grid-row: 5;
}
.pp6 {
  grid-column: 4 / span 3;
  grid-row: 5;
}
.pp7 {
  grid-column: 1 / span 2;
  grid-row: 7;
}
.pp8 {
  grid-column: 4 / span 3;
  grid-row: 7;
}
.pp9 {
  grid-column: 1 / span 2;
  grid-row: 9;
}
.pp10 {
  grid-column: 4 / span 3;
  grid-row: 9;
}
.pp11 {
  grid-column: 1 / span 2;
  grid-row: 11;
}
.pp12 {
  grid-column: 4 / span 3;
  grid-row: 11;
}
.pp13 {
  grid-column: 1 / span 2;
  grid-row: 13;
}
.pp14 {
  grid-column: 4 / span 3;
  grid-row: 13;
}
.pp15 {
  grid-column: 1 / span 2;
  grid-row: 15;
}
.pp16 {
  grid-column: 4 / span 3;
  grid-row: 15;
}
.pp17 {
  grid-column: 1 / span 2;
  grid-row: 17;
}
.pp18 {
  grid-column: 4 / span 3;
  grid-row: 17;
}
.pp19 {
  grid-column: 1 / span 2;
  grid-row: 19;
}
.pp20 {
  grid-column: 4 / span 3;
  grid-row: 19;
}
.pp21 {
  grid-column: 1 / span 2;
  grid-row: 21;
}
.pp22 {
  grid-column: 4 / span 3;
  grid-row: 21;
}
.pp23 {
  grid-column: 1 / span 2;
  grid-row: 23;
}
.pp24 {
  grid-column: 4 / span 3;
  grid-row: 23;
}
.pp25 {
  grid-column: 1 / span 2;
  grid-row: 25;
}
.pp26 {
  grid-column: 4 / span 3;
  grid-row: 25;
}
.pp27 {
  grid-column: 1 / span 2;
  grid-row: 27;
}
.pp28 {
  grid-column: 4 / span 3;
  grid-row: 27;
}
.pp29 {
  grid-column: 1 / span 2;
  grid-row: 29;
}
.pp30 {
  grid-column: 4 / span 3;
  grid-row: 29;
}
.pp31 {
  grid-column: 1 / span 2;
  grid-row: 31;
}
.pp32 {
  grid-column: 4 / span 3;
  grid-row: 31;
}
.pp33 {
  grid-column: 1 / span 2;
  grid-row: 33;
}
.pp34 {
  grid-column: 4 / span 3;
  grid-row: 33;
}
.pp35 {
  grid-column: 1 / span 2;
  grid-row: 35;
}
.pp36 {
  grid-column: 4 / span 3;
  grid-row: 35;
}
.pp37 {
  grid-column: 1 / span 2;
  grid-row: 37;
}
.pp38 {
  grid-column: 4 / span 3;
  grid-row: 37;
}
.pp39 {
  grid-column: 1 / span 2;
  grid-row: 39;
}
.pp40 {
  grid-column: 4 / span 3;
  grid-row: 39;
}
.pp41 {
  grid-column: 1 / span 2;
  grid-row: 41;
}
.pp42 {
  grid-column: 4 / span 3;
  grid-row: 41;
}
.pp43 {
  grid-column: 1 / span 2;
  grid-row: 43;
}
.pp44 {
  grid-column: 4 / span 3;
  grid-row: 43;
}
.pp45 {
  grid-column: 1 / span 2;
  grid-row: 45;
}
.pp46 {
  grid-column: 4 / span 3;
  grid-row: 45;
}
.pp45-1 {
  grid-column: 1 / span 2;
  grid-row: 47;
}
.pp46-1 {
  grid-column: 4 / span 3;
  grid-row: 47;
}
.pp47 {
  grid-column: 1 / span 2;
  grid-row: 49;
}
.pp48 {
  grid-column: 4 / span 3;
  grid-row: 49;
}
.pp49 {
  grid-column: 1 / span 2;
  grid-row: 51;
}
.pp50 {
  grid-column: 4 / span 3;
  grid-row: 51;
}
.pp51 {
  grid-column: 1 / span 2;
  grid-row: 53;
}
.pp52 {
  grid-column: 4 / span 3;
  grid-row: 53;
}
.pp53 {
  grid-column: 1 / span 2;
  grid-row: 55;
}
.pp54 {
  grid-column: 4 / span 3;
  grid-row: 55;
}
.pp55 {
  grid-column: 1 / span 2;
  grid-row: 57;
}
.pp56 {
  grid-column: 4 / span 3;
  grid-row: 57;
}
.pp57 {
  grid-column: 1 / span 2;
  grid-row: 59;
}
.pp58 {
  grid-column: 4 / span 3;
  grid-row: 59;
}
.pp59 {
  grid-column: 1 / span 2;
  grid-row: 61;
}
.pp60 {
  grid-column: 4 / span 3;
  grid-row: 61;
}
.pp61 {
  grid-column: 1 / span 2;
  grid-row: 63;
}
.pp62 {
  grid-column: 4 / span 3;
  grid-row: 63;
}
.pp63 {
  grid-column: 1 / span 2;
  grid-row: 65;
}
.pp64 {
  grid-column: 4 / span 3;
  grid-row: 65;
}
.pp65 {
  grid-column: 1 / span 2;
  grid-row: 67;
}
.pp66 {
  grid-column: 4 / span 3;
  grid-row: 67;
}
.pp67 {
  grid-column: 1 / span 2;
  grid-row: 69;
}
.pp68 {
  grid-column: 4 / span 3;
  grid-row: 69;
}
.pp69 {
  grid-column: 1 / span 2;
  grid-row: 71;
}
.pp70 {
  grid-column: 4 / span 3;
  grid-row: 71;
}
.pp71 {
  grid-column: 1 / span 2;
  grid-row: 73;
}
.pp72 {
  grid-column: 4 / span 3;
  grid-row: 73;
}
.pp73 {
  grid-column: 1 / span 2;
  grid-row: 75;
}
.pp74 {
  grid-column: 4 / span 3;
  grid-row: 75;
}
.pp75 {
  grid-column: 1 / span 2;
  grid-row: 77;
}
.pp76 {
  grid-column: 4 / span 3;
  grid-row: 77;
}
.pp77 {
  grid-column: 1 / span 2;
  grid-row: 79;
}
.pp78 {
  grid-column: 4 / span 3;
  grid-row: 79;
}
.pp79 {
  grid-column: 1 / span 2;
  grid-row: 81;
}
.pp80 {
  grid-column: 4 / span 3;
  grid-row: 81;
}
.pp81 {
  grid-column: 1 / span 2;
  grid-row: 83;
}
.pp82 {
  grid-column: 4 / span 3;
  grid-row: 83;
}
.pp83 {
  grid-column: 1 / span 2;
  grid-row: 85;
}
.pp84 {
  grid-column: 4 / span 3;
  grid-row: 85;
}
.pp85 {
  grid-column: 1 / span 2;
  grid-row: 87;
}
.pp86 {
  grid-column: 4 / span 3;
  grid-row: 87;
}
.pp87 {
  grid-column: 1 / span 2;
  grid-row: 89;
}
.pp88 {
  grid-column: 4 / span 3;
  grid-row: 89;
}
.pp89 {
  grid-column: 1 / span 2;
  grid-row: 91;
}
.pp90 {
  grid-column: 4 / span 3;
  grid-row: 91;
}
.pp91 {
  grid-column: 1 / span 2;
  grid-row: 93;
}
.pp92 {
  grid-column: 4 / span 3;
  grid-row: 93;
}
.pp93 {
  grid-column: 1 / span 2;
  grid-row: 95;
}
.pp94 {
  grid-column: 4 / span 3;
  grid-row: 95;
}
.pp95 {
  grid-column: 1 / span 3;
  grid-row: 97;
}
.pp96 {
  grid-column: 3 / span 3;
  grid-row: 97;
}
.pp97 {
  grid-column: 5 / span 3;
  grid-row: 97;
}

.pp98 {
  grid-column: 1 / span 2;
  grid-row: 99;
}
.pp99 {
  grid-column: 3 / span 2;
  grid-row: 99;
}
.pp100 {
  grid-column: 5 / span 2;
  grid-row: 99;
}
.pp101 {
  grid-column: 1 / span 2;
  grid-row: 101;
}
.pp102 {
  grid-column: 3 / span 2;
  grid-row: 101;
}
.pp103 {
  grid-column: 5 / span 2;
  grid-row: 101;
}
.pp104 {
  grid-column: 1 / span 2;
  grid-row: 103;
}
.pp105 {
  grid-column: 3 / span 2;
  grid-row: 103;
}
.pp106 {
  grid-column: 5 / span 2;
  grid-row: 103;
}
.pp107 {
  grid-column: 1 / span 2;
  grid-row: 105;
}
.pp108 {
  grid-column: 3 / span 2;
  grid-row: 105;
}
.pp109 {
  grid-column: 5 / span 2;
  grid-row: 105;
}
.pp110 {
  grid-column: 1;
  grid-row: 107;
  width: 127px;
}
.pp111 {
  grid-column: 2;
  grid-row: 107;
  width: 115px;
}
.pp112 {
  grid-column: 3;
  grid-row: 107;
  width: 115px;
}
.pp113 {
  grid-column: 4;
  grid-row: 107;
  width: 115px;
}
.pp114 {
  grid-column: 5;
  grid-row: 107;
  width: 115px;
}
.pp115 {
  grid-column: 6;
  grid-row: 107;
  width: 115px;
}
.pp116 {
  grid-column: 1;
  grid-row: 109;
  width: 127px;
}
.pp117 {
  grid-column: 2;
  grid-row: 109;
  width: 115px;
}
.pp118 {
  grid-column: 3;
  grid-row: 109;
  width: 115px;
}
.pp119 {
  grid-column: 4;
  grid-row: 109;
  width: 115px;
}
.pp120 {
  grid-column: 5;
  grid-row: 109;
  width: 115px;
}
.pp121 {
  grid-column: 6;
  grid-row: 109;
  width: 115px;
}
.pp122 {
  grid-column: 1;
  grid-row: 111;
  width: 127px;
}
.pp123 {
  grid-column: 2;
  grid-row: 111;
  width: 115px;
}
.pp124 {
  grid-column: 3;
  grid-row: 111;
  width: 115px;
}
.pp125 {
  grid-column: 4;
  grid-row: 111;
  width: 115px;
}
.pp126 {
  grid-column: 5;
  grid-row: 111;
  width: 115px;
}
.pp127 {
  grid-column: 6;
  grid-row: 111;
  width: 115px;
}

.pp128 {
  grid-column: 1;
  grid-row: 113;
  width: 127px;
}
.pp129 {
  grid-column: 2;
  grid-row: 113;
  width: 115px;
}
.pp130 {
  grid-column: 3;
  grid-row: 113;
  width: 115px;
}
.pp131 {
  grid-column: 4;
  grid-row: 113;
  width: 115px;
}
.pp132 {
  grid-column: 5;
  grid-row: 113;
  width: 115px;
}
.pp133 {
  grid-column: 6;
  grid-row: 113;
  width: 115px;
}

.pp134 {
  grid-column: 1;
  grid-row: 115;
  width: 127px;
}
.pp135 {
  grid-column: 2;
  grid-row: 115;
  width: 115px;
}
.pp136 {
  grid-column: 3;
  grid-row: 115;
  width: 115px;
}
.pp137 {
  grid-column: 4;
  grid-row: 115;
  width: 115px;
}
.pp138 {
  grid-column: 5;
  grid-row: 115;
  width: 115px;
}
.pp139 {
  grid-column: 6;
  grid-row: 115;
  width: 115px;
}
.pp140 {
  grid-column: 1;
  grid-row: 117;
  width: 127px;
}
.pp141 {
  grid-column: 2;
  grid-row: 117;
  width: 115px;
}
.pp142 {
  grid-column: 3;
  grid-row: 117;
  width: 115px;
}
.pp143 {
  grid-column: 4;
  grid-row: 117;
  width: 115px;
}
.pp144 {
  grid-column: 5;
  grid-row: 117;
  width: 115px;
}
.pp145 {
  grid-column: 6;
  grid-row: 117;
  width: 115px;
}
.pp146 {
  grid-column: 1;
  grid-row: 119;
  width: 127px;
}
.pp147 {
  grid-column: 2;
  grid-row: 119;
  width: 115px;
}
.pp148 {
  grid-column: 3;
  grid-row: 119;
  width: 115px;
}
.pp149 {
  grid-column: 4;
  grid-row: 119;
  width: 115px;
}
.pp150 {
  grid-column: 5;
  grid-row: 119;
  width: 115px;
}
.pp151 {
  grid-column: 6;
  grid-row: 119;
  width: 115px;
}
.pp152 {
  grid-column: 1;
  grid-row: 121;
  width: 127px;
}
.pp153 {
  grid-column: 2;
  grid-row: 121;
  width: 115px;
}
.pp154 {
  grid-column: 3;
  grid-row: 121;
  width: 115px;
}
.pp155 {
  grid-column: 4;
  grid-row: 121;
  width: 115px;
}
.pp156 {
  grid-column: 5;
  grid-row: 121;
  width: 115px;
}
.pp157 {
  grid-column: 6;
  grid-row: 121;
  width: 115px;
}
.pp158 {
  grid-column: 1;
  grid-row: 123;
  width: 127px;
}
.pp159 {
  grid-column: 2;
  grid-row: 123;
  width: 115px;
}
.pp160 {
  grid-column: 3;
  grid-row: 123;
  width: 115px;
}
.pp161 {
  grid-column: 4;
  grid-row: 123;
  width: 115px;
}
.pp162 {
  grid-column: 5;
  grid-row: 123;
  width: 115px;
}
.pp163 {
  grid-column: 6;
  grid-row: 123;
  width: 115px;
}
.pp164 {
  grid-column: 1;
  grid-row: 125;
  width: 127px;
}
.pp165 {
  grid-column: 2;
  grid-row: 125;
  width: 115px;
}
.pp166 {
  grid-column: 3;
  grid-row: 125;
  width: 115px;
}
.pp167 {
  grid-column: 4;
  grid-row: 125;
  width: 115px;
}
.pp168 {
  grid-column: 5;
  grid-row: 125;
  width: 115px;
}
.pp169 {
  grid-column: 6;
  grid-row: 125;
  width: 115px;
}

.patient-parent {
  display: flex;
}
.profile-line-one {
  grid-column: 1 / span 6;
  grid-row: 2;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-two {
  grid-column: 1 / span 6;
  grid-row: 4;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-three {
  grid-column: 1 / span 6;
  grid-row: 6;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-four {
  grid-column: 1 / span 6;
  grid-row: 8;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-five {
  grid-column: 1 / span 6;
  grid-row: 10;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-six {
  grid-column: 1 / span 6;
  grid-row: 12;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-seven {
  grid-column: 1 / span 6;
  grid-row: 14;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-eight {
  grid-column: 1 / span 6;
  grid-row: 16;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-nine {
  grid-column: 1 / span 6;
  grid-row: 18;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-ten {
  grid-column: 1 / span 6;
  grid-row: 20;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-eleven {
  grid-column: 1 / span 6;
  grid-row: 22;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-twelve {
  grid-column: 1 / span 6;
  grid-row: 24;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-thirteen {
  grid-column: 1 / span 6;
  grid-row: 26;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-fourteen {
  grid-column: 1 / span 6;
  grid-row: 28;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-fifteen {
  grid-column: 1 / span 6;
  grid-row: 30;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-sixteen {
  grid-column: 1 / span 6;
  grid-row: 32;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-seventeen {
  grid-column: 1 / span 6;
  grid-row: 34;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-eighteen {
  grid-column: 1 / span 6;
  grid-row: 36;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-nineteen {
  grid-column: 1 / span 6;
  grid-row: 38;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-twenty {
  grid-column: 1 / span 6;
  grid-row: 40;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-twentyone {
  grid-column: 1 / span 6;
  grid-row: 42;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-twentytwo {
  grid-column: 1 / span 6;
  grid-row: 44;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-twentythree {
  grid-column: 1 / span 6;
  grid-row: 46;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-twentyfour {
  grid-column: 1 / span 6;
  grid-row: 48;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-twentyfive {
  grid-column: 1 / span 6;
  grid-row: 50;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-twentysix {
  grid-column: 1 / span 6;
  grid-row: 52;

  border-bottom: 1px solid rgb(221, 221, 221);
}

.profile-line-twentyseven {
  grid-column: 1 / span 6;
  grid-row: 54;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-twentyeight {
  grid-column: 1 / span 6;
  grid-row: 56;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-twentynine {
  grid-column: 1 / span 6;
  grid-row: 58;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-thirty {
  grid-column: 1 / span 6;
  grid-row: 60;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-thirtyone {
  grid-column: 1 / span 6;
  grid-row: 62;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-thirtytwo {
  grid-column: 1 / span 6;
  grid-row: 64;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-thirtythree {
  grid-column: 1 / span 6;
  grid-row: 66;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-twentythree-1 {
  grid-column: 1 / span 6;
  grid-row: 68;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-thirtyfour {
  grid-column: 1 / span 6;
  grid-row: 70;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-thirtyfive {
  grid-column: 1 / span 6;
  grid-row: 72;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-thirtysix {
  grid-column: 1 / span 6;
  grid-row: 74;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-thirtyseven {
  grid-column: 1 / span 6;
  grid-row: 76;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-thirtyeight {
  grid-column: 1 / span 6;
  grid-row: 78;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-thirtynine {
  grid-column: 1 / span 6;
  grid-row: 80;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-fourty {
  grid-column: 1 / span 6;
  grid-row: 82;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-fourtyone {
  grid-column: 1 / span 6;
  grid-row: 84;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-fourtytwo {
  grid-column: 1 / span 6;
  grid-row: 86;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-fourtythree {
  grid-column: 1 / span 6;
  grid-row: 88;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-fourtyfour {
  grid-column: 1 / span 6;
  grid-row: 90;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-fourtyfive {
  grid-column: 1 / span 6;
  grid-row: 92;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-fourtysix {
  grid-column: 1 / span 6;
  grid-row: 94;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-fourtyseven {
  grid-column: 1 / span 6;
  grid-row: 96;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-fourtyeight {
  grid-column: 1 / span 6;
  grid-row: 98;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-fourtynine {
  grid-column: 1 / span 6;
  grid-row: 100;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-fifty {
  grid-column: 1 / span 6;
  grid-row: 102;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-fiftyone {
  grid-column: 1 / span 6;
  grid-row: 104;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-fiftytwo {
  grid-column: 1 / span 6;
  grid-row: 106;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-fiftythree {
  grid-column: 1 / span 6;
  grid-row: 108;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-fiftyfour {
  grid-column: 1 / span 6;
  grid-row: 110;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-fiftyfive {
  grid-column: 1 / span 6;
  grid-row: 112;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-fiftysix {
  grid-column: 1 / span 6;
  grid-row: 114;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-fiftyseven {
  grid-column: 1 / span 6;
  grid-row: 116;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-fiftyeight {
  grid-column: 1 / span 6;
  grid-row: 118;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-fiftynine {
  grid-column: 1 / span 6;
  grid-row: 120;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-sixty {
  grid-column: 1 / span 6;
  grid-row: 122;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-sixtyone {
  grid-column: 1 / span 6;
  grid-row: 124;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.profile-line-sixtytwo {
  grid-column: 1 / span 6;
  grid-row: 126;
  margin-bottom: 10px;

  border-bottom: 1px solid rgb(221, 221, 221);
}
.pro-pad {
  padding: 10px 0 0 5px;
}
.pro-pad1 {
  padding: 10px 5px;
}
.pro-pad2 {
  padding: 5px 2px;
}
.patient-pro-foot .add-image-input {
  background-color: white;
}
