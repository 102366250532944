/* @media print {
  @page {
    margin: 20px;
  }
} */
.ultrasono-switch .ant-switch {
  background-color: #00a651;
  width: 47px;
  height: 26px;
}
.ultrasono-dates-details {
  font-weight: 500;
  font-size: 12px;
  color: #adadad;
  width: 58%;
  display: flex;
  justify-content: space-between;
}
.ultrasono-dates-details p {
  padding-bottom: 5px;
}
.print-icon-class img {
  background: #ef4036;
  border-radius: 50%;
  padding: 7px 6px 7px 5px;
  width: 30px;
  height: 30px;
}
.printer-download-logo {
  display: flex;
  justify-content: space-around;
}
.ultrasono-table {
  margin-top: 20px;
}
.ultrasono-table {
  height: 250px;
  overflow-y: scroll;
}
.ultrasono-table .ant-table-tbody {
  line-height: 12px;
}
.ultrasono-table .ant-table-thead > tr > th {
  font-weight: 500;
  font-size: 12px;
  color: #adadad;
  background: #ffffff !important;
  padding: 5px 10px;
  border-bottom: 1px solid #bbb7b7;
}
.ultrasono-table .ant-table-tbody > tr > td {
  border: none;
  padding-top: 10px;
}
.ultrasono-table-one .ant-table-thead > tr > th {
  color: black;
}
.analytics-switch .ant-switch {
  background-color: #ef4036;
}
.ultrasono-table-one table{
  font-size: 9px;
}
.ultrasono-table-one .ant-table-cell{
  padding-left: 10px;
}