.follow-up-div {
    display: flex;
    margin: 20px;
}

.follow-up-14-div {
    width: 23%;
    line-height: 20px;
}

.follow-up-14-div h6 {
    font-size: 13px;
    padding: 5px 10px;
    font-weight: 600;
}

.follow-up-14-div p {
    padding-left: 10px;
    font-weight: 500;
    font-weight: 12px;
    color: #19b3f0;
    line-height: 0px;

}

.follow-up-antSelect {
    display: flex;
    width: 70%;
    align-items: center;
}

.follow-up-wd {
    width: 30%;
    margin: 22px 0px 0px 0px;
}

.follow-up-wd-or-sec {
    width: 30%;
    margin-right: 10px;
}

.follow-up-wd-or {
    width: 33%;
    margin-right: 15px;
}

.ant-select.ant-select-multiple.ant-select-show-arrow.ant-select-show-search.days-follow {
    width: 10vw;
    padding-left: 15px;
}

.opd-followup-calender {
    position: absolute;
    /* margin-top: 9px;
    margin-left: -26px; */
}
.opd-followup-cross {
    position: absolute;
    margin-top: 8px;
    margin-left: -26px;
    color: black;
    display: none;
}
.opd-followup-calender:hover .opd-followup-cross {
    display: block;
}