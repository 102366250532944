.add-new-patient-form {
    padding: 10px 0 0 10px;
}
.appointment-modal-container .ant-modal-body{
    padding: 0 0 24px 24px;
}
.new-patient-name-div {
    display: flex;
    margin-bottom: 2rem;
}

.new-patient-name-details {
    width: 16%;
}

.new-patient-name-details h6 {
    font-size: 15px;
    text-align: left;
    padding: 3px 7px;
}
.new-patient-name-div span.ant-select-selection-search {
    display: none;
}

.all-input-fileds {
    display: flex;
    padding-left: -8px;
    justify-content: space-evenly;
}

.labelname-select-margin {
    margin-top: -22px;
}
.labelname-select-margin-1{
    margin-top: -22px;
    margin-left: 5px;
}
.labelname-select-margin p {
    font-size: 12px;
    opacity: 0.6;
}
.labelname-select-margin-1 p{
    font-size: 12px;
    opacity: 0.6;
    margin-left: 5px;
}
.labelname-input-margin {
    margin-top: -18px;
}

.labelname-input-margin p {
    font-size: 12px;
    opacity: 0.6;
}

.labelname-select-gender-margin {
    margin-top: -18px;
}

.labelname-select-gender-margin p {
    font-size: 12px;
    opacity: 0.6;
}



.delete-icon-div img{
    text-align: center;
}


/* FIRST DIV */
.new-patient-1-div-multiselect1 {
    width: 11vw;
    margin: 0px 6px 0px 0px;
}

.new-patient-1-div-input1 {
    width: 12vw;
    margin: 0px 5px 0px 0px;
}

.new-patient-1-div-multiselect2 {
    width:11vw;
    margin:0px 8px 0px 0px
}

.new-patient-delete-icon-div {
    background-color: red;
    text-align: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    cursor: pointer;
}


/* SECOND DIV */
.new-patient-2-div-multiselect1{
    width: 11vw;
    margin: 0px 15px 0px 0px;
}

.new-patient-2-div-input-field {
    width: 15vw;
}


/* THIRD DIV */
.new-patient-3-div-input-field1 {
    width: 11vw;
    margin-right: 15px;
}

.new-patient-3-div-input-field2 {
    width: 15vw;
    margin-right: 15px;
}


/* FOURTH DIV */
.new-patient-4-div-multiselect {
    width: 6vw;
    margin-right: 10px;
}

.new-patient-4-div-input-field1 {
    width: 121px;
}

.new-patient-4-div-input-field2 {
    width: 11vw;
    margin-right: 10px;
}

/* FIFTH DIV */
.new-patient-5-div-multiselect{
    width: 11vw;
    margin-right: 15px;
}

.new-patient-5-div-input-field {
    width: 15vw;
}

/* SIXTH DIV */
.new-patient-6-div-multiselect {
    width: 11vw;
    margin-right: 15px;
}

.new-patient-6-div-input-field {
    width: 15vw;
}

/* SEVENTH DIV */
.new-patient-7-input-field {
    width: 37vw;
}



.input-field-last-div {
    width: 23vw;
    margin-right: 32px;
}

.new-patient-cancel-btn {
    background-color: lightgray;
}
.new-patient-save-btn{
    margin-right: 5px;
    margin-left: 19px;
}
.date-time-picker{
    width: 80%;
}
.default-input{
    width: 81%;
    margin-left: 10px;
}
.pincode-para{
    margin-left: 10px;

}
.partner-name{
    margin: 10px 0 30px 40px;
    font-weight: bolder;
}
@media screen and (min-width:1111px) {
    .pincode-para{
        margin-left: 0px;
    
    }
    .default-input {
        margin: 0px;
        width: 80%;
    }
}

@media screen and (min-width:1880px){
    
    .ant-modal-content,.add-new-patient-form{

        width: 53vw!important;

        width: 92%;

    }
    .ant-modal{
        display: flex;
        justify-content: center;
    }
   

}

@media screen and (min-width:2560px){
    
    .ant-modal-content,.add-new-patient-form{
        width: 53vw!important;
    }


}


.save-cancel-btns {
    display: flex;
    margin: 0 0 3% 20%;
    margin-left: 35%;
}

