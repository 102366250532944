.bili-parent {
  display: flex;
}
.bili-left {
  width: 80%;
  margin-left: 5px;
  margin-top: 20px;
}
.bili-grid {
  display: grid;
  background-color: white;
  padding: 5px 10px 0 10px;
  font-size: 12px;
}
.bili1 {
  grid-column: 1;
  grid-row: 1;
  width: 150px;
}
.bili2 {
  grid-column: 2;
  grid-row: 1;
}
.bili3 {
  grid-column: 3;
  grid-row: 1;
}
.bili4 {
  grid-column: 4;
  grid-row: 1;
}
.bili5 {
  grid-column: 5;
  grid-row: 1;
}
.bili6 {
  grid-column: 6;
  grid-row: 1;
}
.bili-input {
  width: 120px;
  border-radius: 5px;
  margin-top: 2px;
}
.bili-border {
  grid-column: 1 / span 6;
  grid-row: 2;
  border-bottom: 1px solid #d9d9d9;
}
.bili-border2 {
  grid-column: 1 / span 6;
  grid-row: 4;
  border-bottom: 1px solid #d9d9d9;
}
.bili-border3 {
  grid-column: 1 / span 6;
  grid-row: 6;
  border-bottom: 1px solid #d9d9d9;
}
.h-pad {
  padding: 6px 5px;
}
.bili-foot {
  margin-top: 20px;
  padding-left: 5px;
}
.bili-foot .add-image-input {
  background-color: white;
}
