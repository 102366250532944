
.alt-container {
    background-color: #F0F8FA;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
    
}

.alt-inp{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    text-align: right;
    width: 100%;
    margin-bottom: 10px;    
}
.alt-label{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    text-align: right;
    font-weight: 600;
    margin-right: 20px;
    color: #ccc;
    margin-top: 5px;
}
.alt-section{
    background-color: white;
    padding: 60px 60px 30px 60px;
}
.alt-title{
   margin-right: 297px;
   font-weight: 600;
}
.alt-inp-one{
    border:1px solid #cccc;
    height:35px;
    margin-left: 12px;
    width:360px;
}
.alt-inp-two{
    border:1px solid #cccc;
    height:35px;
    margin-left:10px;
    width:360px;
}
.marg-lef{
    margin-left: 10px;
}