.bmeal-main {
  display: flex;
}
.bmeal-container {
  width: 80%;
}
.bmeal-grid-container {
  display: grid;
  background-color: #ffff;
  text-align: left;
  padding: 24px 10px 0 16px;
  font-size: 12px;
}
.bmeal-grid-container textarea {
  height: 32px;
  border-radius: 4px;
}
.bmeal-1 {
  grid-column: 1;
  grid-row: 1;
}
.bmeal-2 {
  grid-column: 4 / span 3;
  grid-row: 1;
}
.bmeal-border1 {
  grid-column: 1 / span 6;
  grid-row: 2;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.bmeal-3 {
  grid-column: 1;
  grid-row: 3;
}
.bmeal-4 {
  grid-column: 4 / span 3;
  grid-row: 3;
}
.bmeal-border2 {
  grid-column: 1 / span 6;
  grid-row: 4;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.bmeal-5 {
  grid-column: 1;
  grid-row: 5;
}
.bmeal-6 {
  grid-column: 4 / span 3;
  grid-row: 5;
}
.bmeal-border3 {
  grid-column: 1 / span 6;
  grid-row: 6;
  height: 2px;
  border-bottom: 1px solid rgb(221, 221, 221);
}
