.narratives-div {
    display: flex;
    margin: 20px;
    /* margin-bottom: 30px ; */
}

.narratives-5-div {
    width: 23%;
}

.narratives-5-div h6 {
    font-size: 13px;
    padding: 5px 10px;
    font-weight: 600;
}

.narratives-antSelect {
    width: 40vw;
    display: flex;
}

.narratives-input-field {
    width: 42vw;
    border: 1px solid rgb(128,128,128,0.4);
    height: 7rem;
}
