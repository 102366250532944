.ant-switch-checked{
    background-color: #00A651;
}
.pro-detail-heading{
display: flex;
justify-content: space-between;
    background-color: #EEEEEE;
   
    padding: 0px 10px 1px 10px;
}
.pro-detail-heading h4{
    color: #101010;
    font-size: 15px;
    font-weight: 600;
    padding: 11px 0px 0px 15px;
}
.pro-detail-heading h1{
    margin: 0px;
    color: #BFBFBF;
}

.profile-details-left{
    padding: 5px 15px 35px 0px;
    background-color: #ffffff;
    border-radius: 4px;
}
.each-profile-record{
    display: flex;
    margin-top: 10px;
}

.each-profile-record p{
    width: 50%;
    text-align: right;
    padding-right: 30px;
    padding-top: 3px;
    font-weight: 500;
}
.each-profile-record h4{
    padding-top: 3px;
    font-size: 16px;
    font-weight: 600;
}
.save-profile-btn-sec{
   text-align: right;

}
.save-profile-btn{
    font-size: 13px;
    padding: 7px;
    font-weight: 600;
    margin-top: 12px 0px 0px 0px;
}
